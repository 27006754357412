







import { Component, Vue } from 'vue-property-decorator';
import ContentPillars from '@/components/content-pillars.vue';

@Component( {
	components: {
		ContentPillars,
	},
} )
export default class ContentPillarsView extends Vue {}
