<template>
	<v-data-table :headers="headers" :items="dataset" sort-by="keyword_name" class="elevation-3" dense>
		<template v-slot:top>
			<v-toolbar flat color="white">
				<v-toolbar-title>keywords</v-toolbar-title>
				<v-spacer></v-spacer>
				<ac-dialog-edit v-model="dialog" form-title="Keyword" @save="save" @update="update" :is-edit="0 !== editedItem.keyword_id">
					<template v-slot:activator="{ on }">
						<v-btn small color="success" dark class="ma-2" v-on="on" v-if="rolePermission.add == 1">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</template>
					<template v-slot:inputs>
						<v-row>
							<v-col sm="10" md="10">
								<v-select :items="languages" item-value="language_id" item-text="language_name" label="Languages" v-model="editedItem.language_id" dense></v-select>
							</v-col>
						</v-row>
						<v-row>
							<v-col sm="10" md="10">
								<v-text-field v-model="editedItem.keyword_name" label="Keyword Name" required></v-text-field>
							</v-col>
						</v-row>
					</template>
				</ac-dialog-edit>
				<ac-dialog-deactivate v-model="dialogDelete" :itemId="deleteID" :itemType="'Collection'" :itemName="deleteText" @deactivate="deactivate" />
				<ac-dialog-error v-model="dialogError" :text="errorText" />
			</v-toolbar>
		</template>
		<template v-slot:item="{ item }">
			<tr @dblclick="editItem(item)">
				<td>{{ item.keyword_name }}</td>
				<td width="80">
					<v-icon style="padding: 20 !important;" @click="editItem( item )" v-if="rolePermission.edit == 1">
						mdi-pencil
					</v-icon>
					<v-icon style="padding: 20 !important;" @click="deleteItem( item )" v-if="rolePermission.delete == 1">
						mdi-delete-circle
					</v-icon>
				</td>
			</tr>
		</template>
	</v-data-table>
</template>

<script lang="js">
import api from '../services/api';

const endPoint = '/keywords';
const DefaultItem = {
	keyword_id: 0,
	keyword_name: '',
	active: 1,
};

export default {
	name: 'keywords',
	data: () => {
		return {
			headers: [
				{ text: 'Keyword Name', value: 'keyword_name' },
				{ text: '', value: 'action', sortable: false },
			],
			dataset: [],
			dialog: false,
			dialogDelete: false,
			dialogError: false,
			deleteID: 0,
			deleteText: '',
			errorText: '',
			editedItem: DefaultItem,
			defaultItem: DefaultItem,
			currentItem: DefaultItem,
			languages: []
		}
	},
	computed: {
		formTitle() {
			return this.editedItem.keyword_id === 0 ? 'New Keyword' : 'Edit Keyword';
		}
	},
	watch: {
		dialog( val ) {
			if ( this.editedItem.keyword_id === 0 || ! val ) {
				this.editedItem = Object.assign( {}, this.defaultItem );
			}
		},
		dialogDelete( val ) {
			if ( ! val ) {
				this.deleteID = 0;
			}
		}
	},
	created() {
		this.load();
		this.loadLanguages();
	},
	methods: {
		editItem( item ) {
			this.editedItem = Object.assign( {}, item );
			this.currentItem = Object.assign( {}, this.editedItem );
			this.dialog = true;
		},
		deleteItem( item ) {
			this.deleteID = item.keyword_id;
			this.deleteText = item.keyword_name;
			this.dialogDelete = true;
		},
		showError( res, err, defaultText ) {
			this.errorText = defaultText;
			if ( err && err.response && err.response.data && err.response.data.error ) {
				this.errorText += ': ' + err.response.data.error;
			} else if ( res && res.data.error ) {
				this.errorText += ': ' + res.data.error;
			} else if ( err && 'Error: Network Error' == err.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.dialogError = true;
		},
		update() {
			const accel_api = api( this );
			const updateFields = { keyword_id: this.editedItem.keyword_id };
			let updated = false;
			for ( const field in this.editedItem ) {
				if ( this.currentItem[field] != this.editedItem[field] ) {
					updateFields[field] = this.editedItem[field];
					updated = true;
				}
			}
			if ( ! updated ) {
				return;
			}
			accel_api.put( endPoint + '/' + updateFields.keyword_id, updateFields )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.load();
					} else {
						this.showError( res, null, 'Failed to update the Keyword' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to update the Keyword' );
				} );
		},
		save() {
			const accel_api = api( this );
			const saveFields = Object.assign( {}, this.editedItem );
			accel_api.post( endPoint, saveFields )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.load();
					} else {
						this.showError( res, null, 'Failed to add the Keyword' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to add the Keyword' );
				} );
		},
		deactivate( id ) {
			const accel_api = api( this );
			accel_api.delete( endPoint + '/' + id )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.load();
					} else {
						this.showError( res, null, 'Failed to deactivate the Keyword' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to deactivate the Keyword' );
				} );
			this.deleteID = 0;
		},
		async load() {
			const accel_api = api( this );
			accel_api.get( endPoint )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.dataset = res.data.data;
					} else if ( 401 == res.status ) {
						window.console.log( '401: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load the Keyword' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to load the Keyword' );
				} )
		},
		async loadLanguages() {
			const accel_api = api( this );
			accel_api.get( '/language' )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.languages = res.data.data;
					} else if ( 401 == res.status ) {
						window.console.log( '410: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load Languages' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to load Languages' );
				} )
		}
	}
}
</script>

<style scoped></style>
