


















import Vue from 'vue';

export default Vue.extend( {
	props: {
		title: String,
		text: String,
		route: String,
	},
	methods: {
		goToRoute() {
			if ( ! this.route ) {
				return;
			}

			this.$router.push( this.route );
		},
	},
} );
