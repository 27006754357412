








import { Component, Vue } from 'vue-property-decorator';
import Language from '@/components/language.vue';

@Component( {
	components: {
		Language,
	},
} )

export default class LanguageView extends Vue {}

