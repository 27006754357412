<template>
	<ac-dialog-edit v-model="dialog" :form-title="formTitle" @save="save" @update="update" :is-edit="0 !== editedItem.editor_id">
		<template v-slot:activator="{ on }">
			<v-btn small dark class="createBtn" v-on="on">
				{{ buttonName ? buttonName : 'Create New Record' }}
			</v-btn>
		</template>
		<template v-slot:inputs>
			<v-row>
				<v-col cols="12" sm="12">
					<v-select :items="users" item-value="user_id" item-text="fullname" label="User" v-model="editedItem.user_id" dense></v-select>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="12">
					<v-select :items="countries" item-value="country_id" item-text="country_name" label="Country" v-model="editedItem.country_id" dense></v-select>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="12">
					<v-text-field v-model="editedItem.editor_first_name" label="First Name"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="12">
					<v-text-field v-model="editedItem.editor_name" label="Name"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="12">
					<v-text-field v-model="editedItem.editor_name_english" label="Name (English) *" @blur="!$v.editedItem.editor_name_english.$touch()" @input="$v.editedItem.editor_name_english.$touch()" :error-messages="editorNameErrors"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="12">
					<v-select :items="genders" item-value="gender_id" item-text="gender_type" label="Gender" v-model="editedItem.gender_id" dense></v-select>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="12">
					<v-text-field v-model="editedItem.email" label="Email"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="12">
					<v-text-field v-model="editedItem.phone" label="Phone"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="12">
					<v-text-field v-model="editedItem.address1" label="Address Line 1"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="12">
					<v-text-field v-model="editedItem.address2" label="Address Line 2"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="12">
					<v-text-field v-model="editedItem.state" label="State"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="12">
					<v-text-field v-model="editedItem.post_code" label="Postcode"></v-text-field>
				</v-col>
			</v-row>
		</template>
	</ac-dialog-edit>
</template>
<script lang="js">
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import api from '../../services/api';
const endPoint = '/editors';
const DefaultItem = {
	editor_id: 0,
	editor_first_name: '',
	editor_name: '',
	editor_name_english: '',
	gender_id: null,
	email: '',
	phone: '',
	address1: '',
	address2: '',
	state: '',
	post_code: '',
	active: 1,
};
export default {
	mixins: [validationMixin],
	name: 'create-editors',
	props: {
		buttonName: String,
		editItem: Object
	},
	data: () => {
		return {
			dialog: false,
			dialogDelete: false,
			dialogError: false,
			deleteID: 0,
			deleteText: '',
			errorText: '',
			editedItem: DefaultItem,
			defaultItem: DefaultItem,
			currentItem: DefaultItem,
			users: [],
			countries: [],
			genders: [],
		}
	},
	validations: {
		editedItem :{
			editor_name_english: { required },
		}
	},
	computed: {
		formTitle() {
			return this.editedItem.editor_id === 0 ? 'New Editor' : 'Edit Editor';
		},
		editorNameErrors() {
			const errors = []
			if ( ! this.$v.editedItem.editor_name_english.$dirty ) return errors
			! this.$v.editedItem.editor_name_english.required && errors.push( 'Editor Name (English) is required.' )
			return errors;
		},
	},
	watch: {
		editItem( item ) {
			this.editedItem = Object.assign( {}, item );
			this.currentItem = Object.assign( {}, this.editedItem );
			this.dialog = true;
		},
		dialog( val ) {
			if ( this.editedItem.editor_id === 0 || ! val ) {
				this.editedItem = Object.assign( {}, this.defaultItem );
			}
		},
		dialogDelete( val ) {
			if ( ! val ) {
				this.deleteID = 0;
			}
		}
	},
	created() {
		this.loadUsers();
		this.loadCountries();
		this.loadGenders();
	},
	methods: {
		deleteItem( item ) {
			this.deleteID = item.editor_id;
			this.deleteText = item.editor_name;
			this.dialogDelete = true;
		},
		showError( res, err, defaultText ) {
			this.errorText = defaultText;
			if ( err && err.response && err.response.data && err.response.data.error ) {
				this.errorText += ': ' + err.response.data.error;
			} else if ( res && res.data.error ) {
				this.errorText += ': ' + res.data.error;
			} else if ( err && 'Error: Network Error' == err.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.dialogError = true;
		},
		update() {
			const accel_api = api( this );
			const updateFields = { editor_id: this.editedItem.editor_id };
			let updated = false;
			for ( const field in this.editedItem ) {
				if ( this.currentItem[field] != this.editedItem[field] ) {
					updateFields[field] = this.editedItem[field];
					updated = true;
				}
			}
			if ( ! updated ) {
				return;
			}
			accel_api.put( endPoint + '/' + updateFields.editor_id, updateFields )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.$emit( 'editorLoad', res.data.result );
					} else {
						this.showError( res, null, 'Failed to update the Editor ' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to update the Editor' );
				} );
		},
		save() {
			this.$v.$touch();
			if ( this.$v.$invalid ) {
				this.dialog = true;
				return;
			}
			const accel_api = api( this );
			const saveFields = Object.assign( {}, this.editedItem );
			accel_api.post( endPoint, saveFields )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.dialog = false;
						this.$emit( 'editorLoad', res.data.data  );
					} else {
						this.showError( res, null, 'Failed to add the Editor' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to add the Editor' );
				} );
		},
		async loadUsers() {
			const accel_api = api( this );
			accel_api.get( '/users' )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.users = res.data.data;
					} else if ( 401 == res.status ) {
						window.console.log( '410: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load the user roles' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to load the user roles' );
				} )
		},
		async loadCountries() {
			const accel_api = api( this );
			accel_api.get( '/countries' )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.countries = res.data.data;
					} else if ( 401 == res.status ) {
						window.console.log( '410: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load the user roles' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to load the user roles' );
				} )
		},
		async loadGenders() {
			const accel_api = api( this );
			accel_api.get( '/genders' )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.genders = res.data.data;
					} else if ( 401 == res.status ) {
						window.console.log( '410: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load genders' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to load genders' );
				} )
		},
	}
}
</script>

<style scoped>
.createBtn {
	background-color: green !important;
	margin: 8px;
}
</style>
