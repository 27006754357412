








import { Component, Vue } from 'vue-property-decorator';
import ApiResourceUserRoles from '@/components/api-resource-user-roles.vue';

@Component( {
	components: {
		ApiResourceUserRoles,
	},
} )

export default class ApiResourceUserRolesView extends Vue {}

