<template>
	<v-container>
		<div>
			<v-card class="mx-auto pa-4 mb-2" max-width="600" outlined>
				<v-card-title>
					<span class="d-flex mx-auto">My Profile</span>
				</v-card-title>
				<v-avatar class="d-flex mx-auto" size="100" color="primary">
					<span class="white--text text-h3">{{ this.$store.getters.userInitials }}</span>
				</v-avatar>
				<v-text-field v-model="profile.fullname"></v-text-field>
				<v-text-field v-model="profile.email"></v-text-field>
				<v-text-field v-model="profile.phone"></v-text-field>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn class="ma-2" outlined color="success" text @click="update" :disabled="!isValid">Update</v-btn>
				</v-card-actions>
			</v-card>
			<v-card class="mx-auto pa-4" max-width="600" outlined>
				<v-card-title>
					<span class="d-flex mx-auto"></span>
				</v-card-title>
				<v-text-field placeholder="Current Password" v-model="userPass.password" type="password" required></v-text-field>
				<v-text-field v-on:keyup="validate" placeholder="New Password" v-model="userPass.new_password" type="password"></v-text-field>
				<v-text-field v-on:keyup="validate" placeholder="Confirm Password" v-model="userPass.confirm_password" type="password"></v-text-field>
				<v-alert dense outlined type="error" v-if="!this.isValid">{{this.errorText}}</v-alert>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-dialog v-model="dialog" max-width="500px" persistent>
						<template v-slot:activator="{ on }">
							<v-btn class="ma-2" outlined color="success" text v-on="on" :disabled="!isValid">Update</v-btn>
						</template>
						<v-card>
							<v-card-text>
								<v-container>
									<v-text-field v-on:keyup="validateCode" label="Authenticator App Code" prepend-icon="mdi-two-factor-authentication" v-model="userPass.otp_token" />
								</v-container>
							</v-card-text>

							<v-card-actions>
								<v-spacer></v-spacer>
								<div>
									<v-btn color="info" text @click="updatePass" :disabled="!isCodeValid">Submit</v-btn>
								</div>
								<v-btn color="info" text @click="close">Cancel</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-card-actions>
			</v-card>
		</div>
		<v-dialog v-model="dialogError" max-width="450px" persistent>
			<v-card>
				<v-card-title>
					<span class="headline">Error</span>
				</v-card-title>
				<v-card-text>
					<v-alert border="bottom" color="warning" elevation="1">
						{{ errorText }}
					</v-alert>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="success" width="100" @click="closeError">OK</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script lang="js">
import api from '../services/api'

const endPoint = '/users';
const DefaultItem = {
	password: '',
	new_password: '',
	confirm_password: '',
	otp_token: ''
};

export default {
	name: 'profile',
	data: () => {
		return {
			errorText: '',
			dialog: false,
			dialogError: false,
			isValid: true,
			isCodeValid: false,
			message: '',
			otp_token: '',
			userPass: DefaultItem
		}
	},
	watch: {
		dialog( val ) {
			this.userPass.otp_token = '';
			if ( ! val ) {
				this.close();
			}
		},
	},
	created() {
		this.profile = Object.assign( {}, this.$store.getters.USER );
		this.userPass= Object.assign( {}, this.userPass );
		this.currentItem = Object.assign( {}, this.editedItem );
	},
	methods: {
		validate() {
			if ( this.userPass.new_password.length < 10 || this.userPass.confirm_password.length < 10 ) {
				this.isValid = false;
				this.errorText = 'The password must be at least 10 characters long.';
			} else {
				if ( this.userPass.new_password !== this.userPass.confirm_password ) {
					this.isValid = false;
					this.errorText = 'Password confirmation and new password do not match.';
				} else {
					this.isValid = true;
				}
			}
		},
		validateCode() {
			this.isCodeValid = this.userPass.otp_token.length > 0;
		},
		close() {
			this.dialog = false;
			setTimeout( () => {
				this.editedItem = Object.assign( {}, this.profile );
			}, 100 );
		},
		showError( res, err, defaultText ) {
			this.errorText = defaultText;
			if ( err && err.response && err.response.data && err.response.data.error ) {
				this.errorText += ': ' + err.response.data.error;
			} else if ( res && res.data.error ) {
				this.errorText += ': ' + res.data.error;
			} else if ( err && 'Error: Network Error' == err.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else if ( err == null && res == null ) {
				this.errorText = defaultText;
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.dialogError = true;
		},
		closeError() {
			this.errorText = '';
			this.dialogError = false;
		},
		update() {
			const accel_api = api( this );
			const updateFields = {};
			let updated = false;
			for ( const field in this.profile ) {
				if ( this.currentItem[field] != this.profile[field] ) {
					updateFields[field] = this.profile[field];
					updated = true;
				}
			}
			if ( ! updated ) {
				this.close();
				return;
			}
			accel_api.put( endPoint + '/' + this.profile.user_id, updateFields )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.$store.dispatch( 'SET_USER', res.data.data );
					} else {
						this.showError( res, null, 'Failed to update the User' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to update the User' );
				} );
			this.close();
		},
		updatePass() {
			const accel_api = api( this );
			accel_api.put( '/change-password', { password: this.userPass.password, new_password: this.userPass.confirm_password, otp_token: this.userPass.otp_token, email: this.profile.email, user_role_id:this.profile.user_role_id, user_id:this.profile.user_id } )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result && res.data.affectedRows===1 ) {
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to change the Password! Try Again.' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to change the Password! Try Again.' );
				} );
			this.close();
		},
	}
}
</script>

<style scoped>
</style>
