








import { Component, Vue } from 'vue-property-decorator';
import Notifications from '@/components/notifications.vue';

@Component( {
	components: {
		Notifications,
	},
} )
export default class NotificationsView extends Vue {}

