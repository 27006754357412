<template>
	<div>
		<ac-dialog-edit v-model="dialog" :form-title="formTitle" @save="saveForm" @update="saveForm" :is-edit="!isCreate" :loading="loading">
			<template v-slot:activator="{ on }">
				<v-btn small dark class="authorCreateBtn" v-on="on">
					{{ buttonName ? buttonName : 'Create New Record' }}
				</v-btn>
			</template>
			<template v-slot:inputs>
				<author-form ref="form" :value="editItem" @input="onFormSaved" @loading="onFormLoading" @error="showError" required-only></author-form>
			</template>
		</ac-dialog-edit>
		<ac-dialog-error v-model="errorDialog" :text="errorText" />
	</div>
</template>

<script>
import AuthorForm from '@/components/authors/form.vue';

export default {
	name: 'create-authors',
	components: {
		'author-form': AuthorForm,
	},
	props: {
		buttonName: String,
		editItem: Object,
	},
	data: () => {
		return {
			dialog: false,
			errorDialog: false,
			errorText: '',
			loading: false,
		};
	},
	computed: {
		isCreate() {
			return ! this.editItem || ! this.editItem.author_id;
		},
		formTitle() {
			return this.isCreate ? 'New Author' : 'Edit Author';
		},
	},
	watch: {
		editItem() {
			this.dialog = true;
		},
	},
	methods: {
		close() {
			this.dialog = false;
		},
		showError( event ) {
			const { response, error, message } = event;

			this.errorText = message;
			if ( error && error.response && error.response.data && error.response.data.error ) {
				this.errorText += ': ' + error.response.data.error;
			} else if ( response && response.data.error ) {
				this.errorText += ': ' + response.data.error;
			} else if ( error && 'Error: Network Error' == error.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.errorDialog = true;
		},
		saveForm() {
			this.$refs.form.save();
		},
		onFormLoading( val ) {
			this.loading = val;
		},
		onFormSaved( val ) {
			this.$emit( 'authorLoad', val );
			this.$refs.form.reset();
			this.close();
		},
	},
};
</script>

<style scoped>
.authorCreateBtn {
	background-color: green !important;
	margin: 8px;
}
</style>
