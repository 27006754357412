<template>
	<div>
		<div v-for="( group, groupIndex ) in groups" :key="groupIndex + '.' + group.header">
			<div class="text-h6 my-6">
				{{ group.header }}
			</div>
			<asset-manuscript-list-item v-for="(manuscript, manuscriptIndex) in group.manuscripts" :key="manuscript.manuscript_status_id" :value="group.manuscripts[manuscriptIndex]" @input="manuscriptChange( groupIndex, manuscriptIndex, $event )" />
			<v-divider class="my-2"></v-divider>
		</div>
		<ac-dialog-error v-model="errorDialog" :text="errorText" />
	</div>
</template>

<script>
import assetManuscriptsListItem from '@/components/assets/asset-manuscripts-list-item.vue';
import api from '@/services/api';

function isManuscriptPopulated( manuscript ) {
	return null != manuscript.manuscript_id || null != manuscript.manuscript_date || !! manuscript.manuscript_done;
}

export default {
	name: 'asset-manuscripts-list',
	components: {
		'asset-manuscript-list-item': assetManuscriptsListItem,
	},
	props: {
		value: {
			type: Array,
			default: () => [],
		},
	},
	data: () => {
		return {
			manuscriptStatuses: {},
			errorDialog: false,
			errorText: '',
		};
	},
	created() {
		this.loadManuscriptStatuses();
	},
	computed: {
		groups() {
			// Get the groups of manuscript statuses, sorted and grouped by status type name
			const groups = [];
			const manuscripts = Object.values( this.manuscriptStatuses )
				.map( ( status ) => {
					const internalManuscript = {
						manuscript_id: null,
						asset_id: null,
						manuscript_status_id: status.manuscript_status_id,
						manuscript_status_name: status.manuscript_status_name,
						manuscript_status_type_name: status.manuscript_status_type_name,
						process_order: status.process_order,
						manuscript_done: false,
						manuscript_date: null,
					};

					const apiManuscript = this.value.find( ( man ) => man.manuscript_status_id === status.manuscript_status_id );
					if ( apiManuscript ) {
						Object.assign( internalManuscript, apiManuscript );
					}

					return internalManuscript;
				} )
				.sort( ( s1, s2 ) => s1.process_order - s2.process_order );
			let currentHeader = null;

			for ( const manuscript of manuscripts ) {
				if ( null === currentHeader || currentHeader !== manuscript.manuscript_status_type_name ) {
					currentHeader = manuscript.manuscript_status_type_name;
					groups.push( {
						header: currentHeader,
						manuscripts: [],
					} );
				}

				groups[groups.length - 1].manuscripts.push( manuscript );
			}

			return groups;
		},
	},
	watch: {},
	methods: {
		async getResource( url, errorMsg, data ) {
			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : null );
			} catch ( err ) {
				this.showError( null, err, errorMsg );
			}

			if ( 401 == res.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res.status || 'success' !== res.data.result ) {
				this.showError( res, null, errorMsg );

				return;
			}

			return res.data.data;
		},
		async loadManuscriptStatuses() {
			const statuses = await this.getResource( '/manuscript-status', 'Failed to load the Manuscript Statuses' );
			const newStatuses = {};

			// Build a map of statuses types using their IDs as keys
			for ( const status of statuses ) {
				newStatuses[status.manuscript_status_id] = status;
			}

			this.manuscriptStatuses = newStatuses;
		},
		manuscriptChange( groupIndex, manuscriptIndex, val ) {
			const newManuscripts = [];

			this.groups.forEach( ( group, i ) => {
				group.manuscripts.forEach( ( manuscript, j ) => {
					let newManuscript = manuscript;

					if ( i === groupIndex && j === manuscriptIndex ) {
						newManuscript = val;
					}

					if ( isManuscriptPopulated( newManuscript ) ) {
						newManuscripts.push( newManuscript );
					}
				} );
			} );

			this.$emit( 'input', newManuscripts );
		},
		showError( res, err, defaultText ) {
			this.errorText = defaultText;
			if ( err && err.response && err.response.data && err.response.data.error ) {
				this.errorText += ': ' + err.response.data.error;
			} else if ( res && res.data.error ) {
				this.errorText += ': ' + res.data.error;
			} else if ( err && 'Error: Network Error' == err.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.errorDialog = true;
		},
	},
};
</script>

<style scoped lang="scss">
</style>