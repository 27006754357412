<template>
	<v-menu style="max-width: 600px" v-if="assets.length">
		<template v-slot:activator="{ on, attrs }">
			<div class="d-flex flex-row flex-wrap">
				<div class="link" v-bind="attrs" v-for="(asset) in assetsShortList" :key="'sal-' + asset.asset_id">
					<router-link :to="`/assets/${ asset.asset_id }`">
						{{ `LFA ID ${ asset.asset_lfa_id }` }}
					</router-link>
				</div>
				<a class="link" href="javascript:void(0)" v-if="assets && assets.length > shortListLimit" v-on="on">More...</a>
			</div>
		</template>

		<ac-assets-list :assets="assets"></ac-assets-list>
	</v-menu>
</template>

<script>
export default {
	name: 'ac-table-cell-assets',
	props: {
		assets: {
			type: Array,
			default: () => [],
		},
		shortListLimit: {
			type: Number,
			default: 3,
		},
	},
	computed: {
		assetsShortList() {
			return [...this.assets].sort( ( a, b ) => a.asset_lfa_id - b.asset_lfa_id ).slice( 0, this.shortListLimit );
		},
	},
	data: () => {
		return {};
	},
	methods: {},
};
</script>

<style scoped lang="scss">
.link:not(:last-child)::after {
	content: ', ';
	white-space: pre-wrap !important;
}
</style>
