








import { Component, Vue } from 'vue-property-decorator';
import ManuscriptStatus from '@/components/manuscript-status.vue';

@Component( {
	components: {
		ManuscriptStatus,
	},
} )

export default class ManuscriptStatusView extends Vue {}

