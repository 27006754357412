<template>
	<v-data-table :headers="headers" :items="tableCollections" :mobile-breakpoint="0" disable-pagination hide-default-footer sort-by="collection_name" dense>
		<template v-slot:[`item.mirror`]="{ item }">
			<v-simple-checkbox :value="!!item.mirror" @input="mirrorChange( item, $event )"></v-simple-checkbox>
		</template>
		<template v-slot:[`item.window`]="{ item }">
			<v-simple-checkbox :value="!!item.window" @input="windowChange( item, $event )"></v-simple-checkbox>
		</template>
		<template v-slot:[`item.actions`]="{ item }">
			<v-icon style="padding: 20 !important;" @click="removeCollection( item )">
				mdi-delete-circle
			</v-icon>
		</template>
		<template v-slot:[`body.append`]>
			<tr>
				<v-select ref="collectionSelect" :items="selectableCollections" item-text="collection_name" label="Add Collection" v-model="selectedCollection" dense return-object>
					<template v-slot:prepend-item>
						<div class="append">
							<createCollection :buttonName="collectionButtonTitle" @collectionLoad="getCollection"></createCollection>
						</div>
					</template>
				</v-select>
			</tr>
		</template>
	</v-data-table>
</template>

<script language="js">
import CreateCollection from '@/components/collections/createOrUpdate.vue';
export default {
	name: 'asset-collections-table',
	components: {
		createCollection: CreateCollection,
	},
	props: {
		collections: Array,
		selectedCollections: Array,
	},
	data: () => {
		return {
			collectionButtonTitle: 'Create New Collection',
			headers: [
				{ text: 'Name', value: 'collection_name', sortable: false },
				{ text: 'Mirror', value: 'mirror', sortable: false, align: 'center' },
				{ text: 'Window', value: 'window', sortable: false, align: 'center' },
				{ text: '', value: 'actions', sortable: false, align: 'center' },
			],
			selectedCollection: {},
		};
	},
	computed: {
		tableCollections: {
			get() {
				return this.selectedCollections;
			},
			set( value ) {
				this.$emit( 'update:selectedCollections', value );
			},
		},
		allCollections: {
			get() {
				return this.collections;
			},
			set( value ) {
				this.$emit( 'update:collections', value );
			},
		},
		selectableCollections() {
			return this.collections.filter( ( collection ) => {
				return this.selectedCollections.every( ( c ) => c.collection_id !== collection.collection_id );
			} );
		},
	},
	watch: {
		selectedCollection( val ) {
			if ( ! val || ! val.collection_id ) {
				return;
			}

			this.selectedCollection = {};
			this.$refs.collectionSelect.blur();
			this.tableCollections = this.tableCollections.concat( [Object.assign( { mirror: false, window: false }, val )] );
		},
	},
	methods: {
		getCollection( val ) {
			this.allCollections = val.concat( this.allCollections );
		},
		mirrorChange( item, val ) {
			// We need to reassign the list of collections if we want this change to be picked up by the Assets page when updating the asset
			this.tableCollections = this.tableCollections.map( ( c ) => {
				if ( c.collection_id === item.collection_id ) {
					c.mirror = !! val;
				}

				return c;
			} );
		},
		windowChange( item, val ) {
			// We need to reassign the list of collections if we want this change to be picked up by the Assets page when updating the asset
			this.tableCollections = this.tableCollections.map( ( c ) => {
				if ( c.collection_id === item.collection_id ) {
					c.window = !! val;
				}

				return c;
			} );
		},
		removeCollection( item ) {
			this.tableCollections = this.tableCollections.filter( ( c ) => c.collection_id !== item.collection_id );
		},
	},
};
</script>

<style scoped>
</style>