
































import Vue from 'vue';
import { PropType } from 'vue';
import FileInfo from '@/models/file-info';

export default Vue.extend( {
	name: 'ac-file-card',
	props: {
		enableDelete: Boolean,
		enableDownload: Boolean,
		flat: Boolean,
		loading: Boolean,
		showDelete: {
			type: Boolean,
			default: null,
		},
		showDownload: {
			type: Boolean,
			default: null,
		},
		showPreview: Boolean,
		value: Object as PropType<FileInfo | null>,
	},
	data: () => {
		return {};
	},
	computed: {
		_showDelete(): Boolean {
			if ( null != this.showDelete ) {
				return this.showDelete;
			}

			return this.enableDelete;
		},
		_showDownload(): Boolean {
			if ( null != this.showDownload ) {
				return this.showDownload;
			}

			return this.enableDownload;
		},
	},
	watch: {},
	methods: {
		onDelete() {
			this.$emit( 'delete', this.value );
		},
		onDownload() {
			this.$emit( 'download', this.value );
		},
	},
} );
