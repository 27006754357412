






import { Component, Vue } from 'vue-property-decorator';
import Editors from '@/components/editors/list.vue';
@Component( {
	components: {
		Editors,
	},
} )
export default class EditorsView extends Vue {}
