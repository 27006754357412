<template>
	<v-dialog v-model="show" max-width="300px" persistent>
		<v-card>
			<v-card-text class="pt-6 text-h6 text-center message">
				{{ message }}
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="info" text @click="confirm">{{ confirmTitle }}</v-btn>
				<v-btn color="error" text @click="cancel">{{ cancelTitle }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script lang="js">
export default {
	name: 'ac-dialog-deactivate',
	props: {
		value: Boolean,
		message: {
			type: String,
			default: '',
		},
		confirmTitle: {
			type: String,
			default: 'Ok',
		},
		cancelTitle: {
			type: String,
			default: 'Cancel',
		},
	},
	computed: {
		show: {
			get() {
				return this.value
			},
			set( value ) {
				this.$emit( 'input', value );
			}
		},
	},
	methods: {
		confirm() {
			this.$emit( 'confirm' );
			this.show = false;
		},
		cancel() {
			this.$emit( 'cancel' );
			this.show = false;
		},
	}
};
</script>

<style scoped>
.message {
	white-space: pre-wrap;
}
</style>
