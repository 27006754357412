








import { Component, Vue } from 'vue-property-decorator';
import Users from '@/components/users.vue';

@Component( {
	components: {
		Users,
	},
} )
export default class UsersView extends Vue {}

