<template>
	<div class="fader" :style="{ backgroundImage: 'url(' + backgroundImage + ')', transition: 'all 3s ease' }">
		<div v-if="is_verified">
			<v-card width="500" height="290" class="mx-auto mt-5" elevation="15">
				<v-card-title>
					<h1 class="display-1">Login</h1>
				</v-card-title>
				<v-card-text>
					<v-form v-if="!show_code_input" @submit.prevent="verifyUser">
						<v-text-field label="Username" prepend-icon="mdi-account-circle" v-model="email" />
						<v-text-field label="Password" v-model="password" :type="showPassword ? 'text' : 'password'" prepend-icon="mdi-lock" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = ! showPassword" />
						<v-divider></v-divider>
						<v-card-actions>
							<br />
							<v-spacer></v-spacer>
							<v-btn color="success" block type="submit">Login</v-btn>
						</v-card-actions>
					</v-form>
					<v-form v-if="show_code_input" @submit.prevent="loginUser">
						<v-text-field v-if="show_code_input" label="Authenticator App Code" prepend-icon="mdi-two-factor-authentication" v-model="otp_token" />
						<v-divider></v-divider>
						<v-card-actions>
							<v-col>
								<v-spacer></v-spacer>
								<v-btn color="success" block type="submit">Login</v-btn>
								<br />
								<v-spacer></v-spacer>
								<v-btn color="primary" text block @click="show_code_input = false">Back</v-btn>
							</v-col>
						</v-card-actions>
					</v-form>
				</v-card-text>
				<v-alert v-if="'' !== message" border="bottom" color="warning" icon="mdi-account" elevation="1" class="errmessage">
					{{ message }}
				</v-alert>
			</v-card>
		</div>
		<div v-if="!is_verified">
			<v-card width="500" height="420" class="mx-auto mt-5" elevation="15" style="text-align:center">
				<v-card-title>
					<h5>Two Factor Authentication Using Google Authenticator </h5>
				</v-card-title>
				<v-card-text>
					<v-form @submit.prevent="setupOTP">
						<img width="200" :src="this.qr_code" />
						<v-text-field label="Enter Code" prepend-icon="mdi-qrcode" v-model="otp_token" />
						<v-divider></v-divider>
						<v-card-actions>
							<br />
							<v-spacer></v-spacer>
							<v-btn color="green" block type="submit">Submit</v-btn>
						</v-card-actions>
					</v-form>
				</v-card-text>
				<v-alert v-if="'' !== message" border="bottom" color="warning" icon="mdi-account" elevation="1" class="errmessage">
					{{ message }}
				</v-alert>
			</v-card>
		</div>
	</div>
</template>

<script>
import api from '../services/api';

export default {
	name: 'Login',
	data() {
		return {
			email: '',
			password: '',
			showPassword: false,
			imageID: 1,
			message: '',
			is_verified: 1,
			qr_code: '',
			otp_secret: '',
			otp_token: '',
			show_code_input: false,
			menu: [],
			user_role_permission: [],
			images: {
				1: require( '../assets/login-background/1.jpg' ),
				2: require( '../assets/login-background/2.jpg' ),
				3: require( '../assets/login-background/3.jpg' ),
				4: require( '../assets/login-background/4.jpg' ),
				5: require( '../assets/login-background/5.jpg' ),
			},
		};
	},
	mounted() {
		setInterval( this.rotateBackgroundImage, 10000 );
	},
	methods: {
		verifyUser() {
			const accel_api = api( this );

			this.message = '';

			const data = {
				email: this.email,
				password: this.password,
			};
			accel_api
				.post( '/verify', data )
				.then( ( res ) => {
					if ( 200 !== res.status || 'success' !== res.data.result ) {
						this.message = 'Incorrect login details';
						return;
					}

					this.is_verified = res.data.data.is_verified;

					if ( ! res.data.data.is_verified && res.data.data.otp_secret && res.data.data.qr_code ) {
						this.otp_secret = res.data.data.otp_secret;
						this.qr_code = res.data.data.qr_code;
						return;
					}

					this.show_code_input = true;
				} )
				.catch( ( err ) => {
					this.message = 'An error occurred, please try again: ' + err.toString();
				} );
		},
		loginUser() {
			const accel_api = api( this );

			this.message = '';

			const data = {
				email: this.email,
				password: this.password,
				otp_token: this.otp_token,
			};

			accel_api
				.post( '/login', data )
				.then( ( res ) => {
					if ( 200 !== res.status || 'success' !== res.data.result ) {
						this.message = 'Incorrect login details';
						return;
					}

					if ( res.data.data.user_id ) {
						const user = res.data.data;
						this.$store.dispatch( 'SET_USER', user );
						return res;
					} else {
						this.message = 'Incorrect login details';
					}
				} )
				.then( ( res ) => {
					if ( res.data ) {
						accel_api
							.get( '/menus-permissions', {
								headers: {
									'User-ID': res.data.data.user_id,
									'Access-Token': res.data.data.token,
								},
								data,
							} )
							.then( ( res ) => {
								this.menu = res.data.data;
								this.$store.dispatch( 'SET_MENU', this.menu );
								return res;
							} )
							.then( ( res ) => {
								if ( res.data ) {
									accel_api
										.get( '/user-roles-permission/' + this.$store.getters.USER.user_role_id, {
											headers: {
												'User-ID': this.$store.getters.USER.user_id,
												'Access-Token': this.$store.getters.USER.token,
											},
											data,
										} )
										.then( ( res ) => {
											this.user_role_permission = res.data.user_role_access;
											this.$store.dispatch( 'SET_USER_ROLE_PERMISSION', this.user_role_permission );
											this.$router.push( '/' );
										} )
										.catch( ( err ) => {
											this.message = 'An error occurred, please try again: ' + err.toString();
										} );
								}
							} )
							.catch( ( err ) => {
								this.message = 'An error occurred, please try again: ' + err.toString();
							} );
					}
				} )
				.catch( ( err ) => {
					this.message = 'An error occurred, please try again: ' + err.toString();
				} );
		},
		setupOTP() {
			const accel_api = api( this );

			this.message = '';

			const data = {
				email: this.email,
				password: this.password,
				otp_secret: this.otp_secret,
				otp_token: this.otp_token,
			};

			accel_api
				.post( '/setup-otp', data )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result && res.data.data.user_id ) {
						this.message = '';
						const user = res.data.data;
						this.$store.dispatch( 'SET_USER', user );
						return res;
					} else {
						this.message = 'Incorrect code! Try again!';
					}
				} )
				.then( ( res ) => {
					if ( res.data ) {
						accel_api
							.get( '/menus-permissions', {
								headers: {
									'User-ID': res.data.data.user_id,
									'Access-Token': res.data.data.token,
								},
								data,
							} )
							.then( ( res ) => {
								this.menu = res.data.data;
								this.$store.dispatch( 'SET_MENU', this.menu );
								return res;
							} )
							.then( ( res ) => {
								if ( res.data ) {
									accel_api
										.get( '/user-roles-permission/' + this.$store.getters.USER.user_role_id, {
											headers: {
												'User-ID': this.$store.getters.USER.user_id,
												'Access-Token': this.$store.getters.USER.token,
											},
											data,
										} )
										.then( ( res ) => {
											this.user_role_permission = res.data.user_role_access;
											this.$store.dispatch( 'SET_USER_ROLE_PERMISSION', this.user_role_permission );
											this.$router.push( '/' );
										} )
										.catch( ( err ) => {
											this.message = 'An error occurred, please try again: ' + err.toString();
										} );
								}
							} ) //
							.catch( ( err ) => {
								this.message = 'An error occurred, please try again: ' + err.toString();
							} );
					}
				} )
				.catch( ( err ) => {
					this.message = 'An error occurred, please try again: ' + err.toString();
				} );
		},
		rotateBackgroundImage() {
			this.imageID ++;
			if ( 5 < this.imageID ) {
				this.imageID = 1;
			}
		},
	},
	computed: {
		backgroundImage() {
			return this.images[this.imageID]; // require( '../assets/login-background/' + this.imageID + '.jpg' );
		},
	},
};
</script>

<style lang="scss" scoped>
.fader {
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(93vh);
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-position: center top;
	background-size: cover;
}
.errmessage {
	vertical-align: bottom;
}
</style>
