














import GlobalMixin from '@/mixins/globalMixin';
import { isEqual } from 'lodash';
import api from '@/services/api';
import Collection from '@/models/collection';
import Restriction from '@/models/restriction';
import CountryRestriction from '@/models/country-restriction';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { getData, getFullName, getName } from '@/helpers/country-codes';

const endpoint = '/country-restrictions';
const getDefaultItem = (): Partial<CountryRestriction> => {
	return {
		country_code: '',
		collections: [],
	};
};
const mustBeValidCountry = ( value: string ) => getName( value ) != null;

export default GlobalMixin.extend( {
	mixins: [validationMixin],
	name: 'country-restriction-form',
	props: {
		nameError: String,
		handleSave: {
			type: Boolean,
			default: true,
		},
		value: Object,
	},
	data: () => {
		const countries = getData().map( ( c ) => ( { text: getFullName( { name: c.name, code: c.code } ), value: c.code } ) );
		countries.sort( ( a, b ) => a.text.localeCompare( b.text ) );

		return {
			editedItem: getDefaultItem(),
			loading: false,
			loadingCollections: false,
			loadingRestrictions: false,
			collections: [] as Array<Collection>,
			countries,
			restrictions: [] as Array<Restriction>,
		};
	},
	created() {
		this.loadResources();
	},
	validations: {
		editedItem: {
			country_code: { required, mustBeValidCountry },
			restriction_id: { required },
		},
	},
	computed: {
		countryRestriction(): CountryRestriction {
			return ( this.value as CountryRestriction ) || getDefaultItem();
		},
		countryCodeErrors(): string[] {
			const errors: string[] = [];
			if ( ! this.$v.editedItem.country_code?.$dirty ) return errors;
			! this.$v.editedItem.country_code.required && errors.push( 'This field is required.' );
			! this.$v.editedItem.country_code.mustBeValidCountry && errors.push( 'This field is required.' );
			return errors;
		},
		restrictionIdErrors(): string[] {
			const errors: string[] = [];
			if ( ! this.$v.editedItem.restriction_id?.$dirty ) return errors;
			! this.$v.editedItem.restriction_id.required && errors.push( 'This field is required.' );
			return errors;
		},
	},
	watch: {
		hideCollection( val: boolean ) {
			if ( ! val ) {
				this.loadResources();
			}
		},
		countryRestriction: {
			deep: true,
			immediate: true,
			handler( val ) {
				this.editedItem = JSON.parse( JSON.stringify( val ) );
			},
		},
		editedItem: {
			deep: true,
			handler( val ) {
				this.$emit( 'changed', JSON.stringify( val ) !== JSON.stringify( this.countryRestriction ) );
			},
		},
		loading( val ) {
			this.$emit( 'loading', val );
		},
	},
	methods: {
		reset() {
			this.editedItem = JSON.parse( JSON.stringify( this.countryRestriction ) );
			this.$v.$reset();
		},
		sendInput( val: any ) {
			this.$emit( 'input', val );
		},
		async save() {
			this.$v.$touch();
			if ( this.$v.$invalid ) {
				return;
			}

			if ( this.handleSave ) {
				if ( this.countryRestriction.country_restriction_id ) {
					return this.update();
				} else {
					return this.create();
				}
			} else {
				if ( this.countryRestriction.country_restriction_id ) {
					this.$emit( 'update', this.editedItem );
				} else {
					this.$emit( 'save', this.editedItem );
				}
			}
		},
		async create() {
			this.loading = true;

			const accel_api = api( this );
			let res;
			try {
				res = await accel_api.post( endpoint, this.editedItem );

				if ( 200 !== res.status || 'success' !== res.data.result ) {
					this.loading = false;
					this.sendError( res, null, 'Failed to save the Country Restriction' );
					return;
				}
			} catch ( err ) {
				this.sendError( null, err, 'Failed to save the Country Restriction' );
				return;
			} finally {
				this.loading = false;
			}

			this.sendInput( res.data.data?.length ? res.data.data[0] : [] );
		},
		async update() {
			this.loading = true;

			const accel_api = api( this );
			const updatedFields: any = {};
			let needsUpdate = false;
			let field: keyof CountryRestriction;
			for ( field in this.editedItem ) {
				if ( ! isEqual( this.countryRestriction[field], this.editedItem[field] ) ) {
					updatedFields[field] = this.editedItem[field];
					needsUpdate = true;
				}
			}
			if ( ! needsUpdate ) {
				// No update to process, just reset the original values
				this.loading = false;
				this.reset();
				this.sendInput( { ...this.editedItem } );
				return;
			}
			let res;
			try {
				res = await accel_api.put( `${endpoint}/${this.editedItem.country_restriction_id}`, updatedFields );

				if ( 200 !== res.status || 'success' !== res.data.result ) {
					this.loading = false;
					this.sendError( res, null, 'Failed to save the Country Restriction' );
					return;
				}
			} catch ( err ) {
				this.sendError( null, err, 'Failed to save the Country Restriction' );
				return;
			} finally {
				this.loading = false;
			}

			this.sendInput( { ...this.editedItem } );
		},
		async loadResources() {
			await Promise.all( [this.loadCollections(), this.loadRestrictions()] );
		},
		async loadCollections() {
			this.loadingCollections = true;
			this.collections = await this.getApiResource( '/collections', 'Failed to load the Collections' );
			this.loadingCollections = false;
		},
		async loadRestrictions() {
			this.loadingRestrictions = true;
			this.restrictions = await this.getApiResource( '/restrictions', 'Failed to load the Restrictions' );
			this.loadingRestrictions = false;
		},
	},
} );
