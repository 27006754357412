







import { Component, Vue } from 'vue-property-decorator';
import Funders from '@/components/funders.vue';

@Component( {
	components: {
		Funders,
	},
} )
export default class FundersView extends Vue {}
