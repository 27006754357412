


































import Vue from 'vue';

export default Vue.extend( {
	name: 'ac-dialog-edit',
	props: {
		preventClose: {
			type: Boolean,
			default: false,
		},
		value: Boolean,
		isEdit: Boolean,
		formTitle: String,
		cancelButtonTitle: {
			type: String,
			default: 'Cancel',
		},
		saveButtonTitle: {
			type: String,
			default: 'Save',
		},
		updateButtonTitle: {
			type: String,
			default: 'Update',
		},
		loading: Boolean,
	},
	computed: {
		show: {
			get(): boolean {
				return this.value;
			},
			set( value: boolean ) {
				this.$emit( 'input', value );
			},
		},
	},
	methods: {
		save() {
			this.$emit( 'save' );
		},
		update() {
			this.$emit( 'update' );
			if ( ! this.preventClose ) {
				this.close();
			}
		},
		close() {
			this.show = false;
		},
	},
} );
