

















import Vue, { PropType } from 'vue';

export default Vue.extend( {
	name: 'ac-table-cell-links',
	props: {
		keyFunction: {
			// eslint-disable-next-line no-unused-vars
			type: Function as PropType<( item: any ) => string>,
			required: true,
		},
		link: {
			// eslint-disable-next-line no-unused-vars
			type: Function as PropType<( item: any ) => string>,
			required: true,
		},
		shortListLimit: {
			type: Number,
			default: 3,
		},
		sort: {
			// eslint-disable-next-line no-unused-vars
			type: Function as PropType<( a: any, b: any ) => number>,
		},
		text: {
			// eslint-disable-next-line no-unused-vars
			type: Function as PropType<( item: any ) => string>,
			required: true,
		},
		value: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		itemsShortList(): Array<any> {
			const items = [...this.value];

			if ( this.sort != null ) {
				return items.sort( this.sort ).slice( 0, this.shortListLimit );
			}

			return items.slice( 0, this.shortListLimit );
		},
	},
	data: () => {
		return {};
	},
	methods: {
		getSubItemKey( item: any ): string {
			return `subitem-${this.keyFunction( item )}`;
		},
	},
} );
