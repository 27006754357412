







import { Component, Vue } from 'vue-property-decorator';
import PAReadingFocuses from '@/components/pa-reading-focus/list.vue';

@Component( {
	components: {
		'pa-reading-focuses': PAReadingFocuses,
	},
} )
export default class PAReadingFocusesView extends Vue {}
