












import Vue, { PropType } from 'vue';

export default Vue.extend( {
	name: 'ac-links-list',
	props: {
		keyFunction: {
			// eslint-disable-next-line no-unused-vars
			type: Function as PropType<( item: any ) => string>,
			required: true,
		},
		link: {
			// eslint-disable-next-line no-unused-vars
			type: Function as PropType<( item: any ) => string>,
			required: true,
		},
		text: {
			// eslint-disable-next-line no-unused-vars
			type: Function as PropType<( item: any ) => string>,
			required: true,
		},
		sort: {
			// eslint-disable-next-line no-unused-vars
			type: Function as PropType<( a: any, b: any ) => number>,
		},
		value: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		sortedItems(): Array<any> {
			const items = [...this.value];

			if ( this.sort != null ) {
				return items.sort( this.sort );
			}

			return items;
		},
	},
	data: () => {
		return {};
	},
	methods: {},
} );
