








import { Component, Vue } from 'vue-property-decorator';
import Manuscript from '@/components/manuscript.vue';

@Component( {
	components: {
		Manuscript,
	},
} )

export default class ManuscriptView extends Vue {}

