
<template>
	<div class="container container-table">
		<div class="row vertical-10p">
		<div class="container">
			<div class="message">
				<h4>This page doesn't exist.</h4>
				<router-link to="/">Take me home.</router-link>
			</div>
		</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'NotFound'
}
</script>

<style>
.message {
	vertical-align: center;
	align-content: center;
}
</style>
