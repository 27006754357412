<template>
	<div>
		<div v-if="requiredOnly">
			<v-row>
				<v-col cols="12">
					<v-text-field v-model="editedItem.illustrator_name_english" label="Illustrator Name (English)" required @blur="!$v.editedItem.illustrator_name_english.$touch()" @input="$v.editedItem.illustrator_name_english.$touch()" :error-messages="illustratorNameEnErrors" :disabled="loading"></v-text-field>
				</v-col>
			</v-row>
		</div>
		<div v-if="!requiredOnly">
			<v-row>
				<v-col cols="12" sm="4">
					<v-text-field v-model="editedItem.illustrator_name_english" label="Illustrator Name (English) *" required @blur="!$v.editedItem.illustrator_name_english.$touch()" @input="$v.editedItem.illustrator_name_english.$touch()" :error-messages="illustratorNameEnErrors" :disabled="loading"></v-text-field>
				</v-col>
				<v-col cols="12" sm="4">
					<v-text-field v-model="editedItem.illustrator_name" label="Illustrator Name (Destination Language)" :disabled="loading"></v-text-field>
				</v-col>
				<v-col cols="12" sm="4">
					<v-text-field v-model="editedItem.illustrator_first_name" label="Illustrator First Name" :disabled="loading"></v-text-field>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12" sm="4">
					<v-select :items="genders" item-value="gender_id" item-text="gender_type" label="Illustrator Gender" v-model="editedItem.gender_id" :disabled="loading"></v-select>
				</v-col>
				<v-col cols="12" sm="4">
					<v-select :items="users" item-value="user_id" item-text="fullname" label="User" v-model="editedItem.user_id" :disabled="loading"></v-select>
				</v-col>
				<v-col cols="12" sm="4">
					<v-select :items="companies" item-value="company_id" item-text="company_name" label="illustrator Company" v-model="editedItem.company_id" :disabled="loading"></v-select>
				</v-col>

			</v-row>

			<v-divider class="mt-4 mb-6"></v-divider>

			<v-row>
				<v-col cols="12" sm="4">
					<v-text-field v-model="editedItem.email" label="Illustrator Email" @blur="!$v.editedItem.email.$touch()" @input="$v.editedItem.email.$touch()" :error-messages="illustratorEmailErrors" :disabled="loading"></v-text-field>
				</v-col>
				<v-col cols="12" sm="4">
					<v-text-field v-model="editedItem.phone" label="Illustrator Phone" :disabled="loading"></v-text-field>
				</v-col>
			</v-row>

			<v-divider class="mt-4 mb-6"></v-divider>

			<v-row>
				<v-col cols="12" sm="6">
					<v-text-field v-model="editedItem.address1" label="Illustrator Address (Line 1)" :disabled="loading"></v-text-field>
				</v-col>
				<v-col cols="12" sm="6">
					<v-text-field v-model="editedItem.address2" label="Illustrator Address (Line 2)" :disabled="loading"></v-text-field>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12" sm="4">
					<v-text-field v-model="editedItem.state" label="illustrator State" :disabled="loading"></v-text-field>
				</v-col>
				<v-col cols="12" sm="4">
					<v-text-field v-model="editedItem.post_code" label="Illustrator Postcode" :disabled="loading"></v-text-field>
				</v-col>
				<v-col cols="12" sm="4">
					<v-select :items="countries" item-value="country_id" item-text="country_name" label="Illustrator Country" v-model="editedItem.country_id" :disabled="loading"></v-select>
				</v-col>
			</v-row>
		</div>
	</div>
</template>
<script>
import api from '@/services/api';
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';

const endpoint = '/illustrators';
const getDefaultItem = () => {
	return {
		illustrator_id: null,
		illustrator_name: '',
		illustrator_name_english: '',
		gender_id: null,
		phone: '',
		address1: '',
		address2: '',
		state: '',
		post_code: '',
		country_id: null,
		assets: null,
		active: 1,
	};
};

export default {
	mixins: [validationMixin],
	name: 'illustrator-form',
	props: {
		value: Object,
		requiredOnly: {
			type: Boolean,
			default: false,
		},
	},
	data: () => {
		return {
			editedItem: getDefaultItem(),
			loading: false,
			users: [],
			countries: [],
			genders: [],
			companies: [],
		};
	},
	created() {
		this.loadFormResources();
	},
	validations: {
		editedItem: {
			illustrator_name_english: { required },
			email: { email },
		},
	},
	computed: {
		illustrator() {
			return this.value || getDefaultItem();
		},
		illustratorNameEnErrors() {
			const errors = [];
			if ( ! this.$v.editedItem.illustrator_name_english.$dirty ) return errors;
			! this.$v.editedItem.illustrator_name_english.required && errors.push( 'This field is required.' );
			return errors;
		},
		illustratorEmailErrors() {
			const errors = [];
			if ( ! this.$v.editedItem.email.$dirty ) return errors;
			! this.$v.editedItem.email.email && errors.push( 'Must be a valid email address' );
			return errors;
		},
	},
	watch: {
		illustrator: {
			deep: true,
			handler( val ) {
				this.editedItem = JSON.parse( JSON.stringify( val ) );
			},
		},
		editedItem: {
			deep: true,
			handler( val ) {
				this.$emit( 'changed', JSON.stringify( val ) !== JSON.stringify( this.illustrator ) );
			},
		},
		loading( val ) {
			this.$emit( 'loading', val );
		},
	},
	methods: {
		loadFormResources() {
			this.loadCountries();
			this.loadGenders();
			this.loadUsers();
			this.loadCompanies();
		},
		reset() {
			this.editedItem = JSON.parse( JSON.stringify( this.illustrator ) );
		},
		sendError( res, err, defaultText ) {
			this.$emit( 'error', { response: res, error: err, message: defaultText } );
		},
		sendInput( val ) {
			this.$emit( 'input', val );
		},
		async save() {
			this.$v.$touch();
			if ( this.$v.$invalid ) {
				return;
			}

			if ( this.illustrator.illustrator_id ) {
				return this.updateillustrator();
			} else {
				return this.createillustrator();
			}
		},
		async createillustrator() {
			this.loading = true;

			const accel_api = api( this );
			let res;
			try {
				res = await accel_api.post( endpoint, this.editedItem );

				if ( 200 !== res.status || 'success' !== res.data.result ) {
					this.loading = false;
					this.sendError( res, null, 'Failed to save the illustrator' );
					return;
				}
			} catch ( err ) {
				this.sendError( null, err, 'Failed to save the illustrator' );
				return;
			} finally {
				this.loading = false;
			}

			this.sendInput( res.data.data[0] );
		},
		async updateillustrator() {
			this.loading = true;

			const accel_api = api( this );
			const updatedFields = {};
			let needsUpdate = false;
			for ( const field in this.editedItem ) {
				if ( this.illustrator[field] != this.editedItem[field] ) {
					updatedFields[field] = this.editedItem[field];
					needsUpdate = true;
				}
			}
			if ( ! needsUpdate ) {
				// No update to process, just reset the original values
				this.loading = false;
				this.reset();
			}
			let res;
			try {
				res = await accel_api.put( `${endpoint}/${this.illustrator.illustrator_id}`, updatedFields );

				if ( 200 !== res.status || 'success' !== res.data.result ) {
					this.loading = false;
					this.sendError( res, null, 'Failed to save the illustrator' );
					return;
				}
			} catch ( err ) {
				this.sendError( null, err, 'Failed to save the illustrator' );
				return;
			} finally {
				this.loading = false;
			}

			this.sendInput( { ...this.editedItem } );
		},
		async getResource( url, errorMsg, data ) {
			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : null );
			} catch ( err ) {
				this.sendError( null, err, errorMsg );
			}

			if ( 401 == res.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res.status || 'success' !== res.data.result ) {
				this.sendError( res, null, errorMsg );

				return;
			}

			return res.data.data;
		},
		async loadCountries() {
			this.countries = await this.getResource( '/countries', 'Failed to load the Countries' );
		},
		async loadGenders() {
			this.genders = await this.getResource( '/genders', 'Failed to load the Genders' );
		},
		async loadUsers() {
			this.users = await this.getResource( '/users', 'Failed to load the Users' );
		},
		async loadCompanies() {
			this.companies = await this.getResource( '/companies', 'Failed to load the Companies' );
		},
	},
};
</script>

<style scoped>
</style>

