<template>
	<v-list>
		<v-list-item v-for="(asset) in sortedAssets" :key="'al-' + asset.asset_id" single-line>
			<v-list-item-title>
				<router-link :to="`/assets/${ asset.asset_id }`">
					{{ `LFA ID ${ asset.asset_lfa_id }` }}
				</router-link>
			</v-list-item-title>
		</v-list-item>
	</v-list>
</template>

<script>
export default {
	name: 'ac-assets-list',
	props: {
		assets: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		sortedAssets() {
			return [...this.assets].sort( ( a, b ) => a.asset_lfa_id - b.asset_lfa_id );
		},
	},
	data: () => {
		return {};
	},
	methods: {},
};
</script>

<style scoped>
</style>
