
















import Vue from 'vue';
import api from '@/services/api';
import DialogCreate from './createOrUpdate.vue';

const endPoint = '/subject-authorities';

export default Vue.extend( {
	name: 'subject-authorities',
	components: {
		'dialog-create': DialogCreate,
	},
	data: () => {
		return {
			headers: [{ text: 'Name', value: 'subject_authority_name', align: 'center', divider: true }],
			dataset: [],
			dialogDelete: false,
			dialogError: false,
			deleteID: 0,
			deleteText: '',
			errorText: '',
			editedItem: {},
		};
	},
	computed: {
		itemsPerPage: {
			get() {
				return this.$store.getters.itemsPerList( 'subjectAuthorities' );
			},
			set( val ) {
				this.$store.dispatch( 'SET_ITEMS_PER_LIST', {
					listName: 'subjectAuthorities',
					number: val,
				} );
			},
		},
	},
	watch: {
		dialogDelete( val ) {
			if ( ! val ) {
				this.deleteID = 0;
			}
		},
	},
	created() {
		this.load();
	},
	methods: {
		editItem( item: any ) {
			this.editedItem = item;
		},
		deleteItem( item: any ) {
			this.deleteID = item.subject_authority_id;
			this.deleteText = item.subject_authority_name;
			this.dialogDelete = true;
		},
		showError( res: any, err: any, defaultText: string ) {
			this.errorText = defaultText;
			if ( err && err.response && err.response.data && err.response.data.error ) {
				this.errorText += ': ' + err.response.data.error;
			} else if ( res && res.data.error ) {
				this.errorText += ': ' + res.data.error;
			} else if ( err && 'Error: Network Error' == err.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.dialogError = true;
		},
		deactivate() {
			const accel_api = api( this );
			accel_api
				.delete( endPoint + '/' + this.deleteID )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.load();
					} else {
						this.showError( res, null, 'Failed to deactivate the Subject Authority' );
					}
				} )
				.catch( ( err ) => {
					this.showError( null, err, 'Failed to deactivate the Subject Authority' );
				} );
			this.deleteID = 0;
		},
		async load() {
			const accel_api = api( this );
			accel_api
				.get( endPoint )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.dataset = res.data.data;
					} else if ( 401 == res.status ) {
						window.console.log( '401: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load the Subject Authority' );
					}
				} )
				.catch( ( err ) => {
					this.showError( null, err, 'Failed to load the Subject Authority' );
				} );
		},
	},
} );
