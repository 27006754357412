


























import Vue from 'vue';
import api from '@/services/api';

interface SearchItem {
	value: string;
	icon: string;
	text: string;
	subtitle1: string;
	subtitle2: string;
	link: string;
}

const ITEM_TYPES: { [key: string]: { icon: string; link_base: string; title_attribute: string; subtitle_attribute: string; type_name: string } } = {
	asset: {
		icon: 'mdi-book-open-variant',
		link_base: 'assets',
		title_attribute: 'title_english',
		subtitle_attribute: 'title',
		type_name: 'Asset',
	},
	author: {
		icon: 'mdi-typewriter',
		link_base: 'authors',
		title_attribute: 'author_name_english',
		subtitle_attribute: 'author_name',
		type_name: 'Author',
	},
	editor: {
		icon: 'mdi-file-edit',
		link_base: 'editors',
		title_attribute: 'editor_name_english',
		subtitle_attribute: 'editor_name',
		type_name: 'Editor',
	},
	illustrator: {
		icon: 'mdi-draw',
		link_base: 'illustrators',
		title_attribute: 'illustrator_name_english',
		subtitle_attribute: 'illustrator_name',
		type_name: 'Illustrator',
	},
};

// HACK: handle false positive for no-unused-vars from ESLint
/* eslint-disable no-unused-vars */
enum SearchStatus {
	Success,
	Loading,
	Empty,
	Error,
}
/* eslint-enable no-unused-vars */

export default Vue.extend( {
	props: {},
	data: () => {
		return {
			latestSearch: null as string | null,
			query: null as string | null,
			results: [],
			searchStatus: SearchStatus.Success,
			searchTimer: undefined as number | undefined,
			selected: null as string | null,
		};
	},
	computed: {
		entries(): Array<SearchItem> {
			const searchItems: Array<SearchItem> = [];

			this.results?.forEach( ( result: any ) => {
				const { type, id, row } = result;

				if ( ! type || ! id ) {
					return;
				}

				const itemType = ITEM_TYPES[type];

				if ( ! itemType ) {
					return;
				}

				const { title_attribute, subtitle_attribute, link_base, type_name, ...rest } = itemType;

				searchItems.push( {
					value: `${type}/${id}`,
					text: row[title_attribute] || '-',
					subtitle1: row[subtitle_attribute] || '-',
					subtitle2: type_name,
					link: `${link_base}/${id}`,
					...rest,
				} );
			} );

			return searchItems;
		},
		hideNoData(): boolean {
			switch ( this.searchStatus ) {
			case SearchStatus.Empty:
				return ! this.latestSearch;
			case SearchStatus.Error:
				return false;
			default:
				return true;
			}
		},
		loading(): boolean {
			return SearchStatus.Loading === this.searchStatus;
		},
		noDataText(): string {
			switch ( this.searchStatus ) {
			case SearchStatus.Empty:
				return this.latestSearch ? `No results found for "${this.latestSearch}"` : '';
			case SearchStatus.Error:
				return `There was an error while searching for "${this.latestSearch ? this.latestSearch : ''}"`;
			default:
				return '';
			}
		},
		_query() {
			return this.query ? this.query.trim() : null;
		},
	},
	watch: {
		_query( q: string | null ) {
			this.searchDebounced( q );
		},
	},
	methods: {
		async getResource( url: string, errorMsg: string, data?: any ) {
			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : undefined );
			} catch ( err ) {
				this.sendError( null, err, errorMsg );
			}

			if ( 401 == res?.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res?.status || 'success' !== res.data.result ) {
				this.sendError( res, null, errorMsg );

				return;
			}

			return res.data.data;
		},
		sendError( res: any, err: any, defaultText: any ) {
			this.$emit( 'error', { response: res, error: err, message: defaultText } );
		},
		async search( q: string | null ) {
			this.latestSearch = q;
			if ( ! this.latestSearch ) {
				this.searchStatus = SearchStatus.Success;
				return;
			}

			this.searchStatus = SearchStatus.Loading;

			this.results = await this.getResource( '/search', `Error while searching for "${this.latestSearch}"`, { q: this.latestSearch } );

			if ( null == this.results ) {
				this.searchStatus = SearchStatus.Error;
			} else if ( this.results.length ) {
				this.searchStatus = SearchStatus.Success;
			} else {
				this.searchStatus = SearchStatus.Empty;
			}
		},
		searchDebounced( query: string | null ) {
			this.results = [];

			// cancel pending call
			clearTimeout( this.searchTimer );

			// delay new call 500ms
			this.searchTimer = setTimeout( () => {
				this.search( query );
			}, 500 );
		},
	},
} );
