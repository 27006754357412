<template>
	<v-data-table :headers="headers" :items="dataset" sort-by="manuscript_title" class="elevation-3" dense>
		<template v-slot:top>
			<v-toolbar flat color="white">
				<v-toolbar-title>Manuscript</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-dialog v-model="dialog" max-width="500px" persistent>
					<template v-slot:activator="{ on }">
						<v-btn small color="success" dark class="ma-2" v-on="on" v-if="rolePermission.add == 1">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</template>
					<v-card>
						<v-card-title>
							<span class="headline">{{ formTitle }}</span>
						</v-card-title>

						<v-card-text>
							<v-container>
								<v-row>
									<v-col sm="10" md="10">
										<v-select :items="languages" item-value="language_id" item-text="language_name" label="Language" v-model="editedItem.language_id" dense></v-select>
									</v-col>
								</v-row>
								<v-row>
									<v-col sm="10" md="10">
										<v-select :items="manuscriptTypes" item-value="manuscript_status_type_id" item-text="manuscript_status_type_name" label="Manuscript Type" v-model="editedItem.manuscript_type_id" dense></v-select>
									</v-col>
								</v-row>
								<v-row>
									<v-col sm="10" md="10">
										<v-select :items="manuscriptStatuses" item-value="manuscript_status_id" item-text="manuscript_status_name" label="Manuscript Status" v-model="editedItem.manuscript_status_id" dense></v-select>
									</v-col>
								</v-row>
								<v-row>
									<v-col sm="10" md="10">
										<v-text-field v-model="editedItem.manuscript_title" label="Manuscript Title" required></v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col sm="10" md="10">
										<v-text-field v-model="editedItem.manuscript_description" label="Manuscript Description" required></v-text-field>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>

						<v-card-actions>
							<v-spacer></v-spacer>
							<div v-if="0 === editedItem.manuscript_id">
								<v-btn color="info" text @click="save">Save</v-btn>
							</div>
							<div v-if="0 !== editedItem.manuscript_id">
								<v-btn color="info" text @click="update">Update</v-btn>
							</div>
							<v-btn color="info" text @click="close">Cancel</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogDelete" max-width="300px" persistent>
					<v-card>
						<v-card-title>
							<span class="headline">Deactivate Manuscript</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								Are you sure you want to deactivate {{ deleteText }}?
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="error" text @click="deactivate">Deactivate</v-btn>
							<v-btn color="info" text @click="closeDelete">Cancel</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogError" max-width="450px" persistent>
					<v-card>
						<v-card-title>
							<span class="headline">Error</span>
						</v-card-title>
						<v-card-text>
							<v-alert border="bottom" color="warning" elevation="1">
								{{ errorText }}
							</v-alert>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="success" width="100" @click="closeError">OK</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-toolbar>
		</template>
		<template v-slot:item="{ item }">
			<tr @dblclick="editItem( item )">
				<td>{{ item.manuscript_title }}</td>
				<td>{{ item.manuscript_description }}</td>
				<td>{{ item.manuscript_type_name }}</td>
				<td>{{ item.manuscript_status_name }}</td>
				<td>{{ item.language_name }}</td>
				<td width="80">
					<v-icon style="padding: 20 !important;" @click="editItem( item )" v-if="rolePermission.edit == 1">
						mdi-pencil
					</v-icon>
					<v-icon style="padding: 20 !important;" @click="deleteItem( item )" v-if="rolePermission.delete == 1">
						mdi-delete-circle
					</v-icon>
				</td>
			</tr>
		</template>
	</v-data-table>
</template>

<script lang="js">
import api from '../services/api'

const endPoint = '/manuscripts';
const DefaultItem = {
	manuscript_id: 0,
	manuscript_type_id: 0,
	manuscript_status_id: 0,
	language_id: 0,
	manuscript_title: '',
	manuscript_description: '',
	active: 1,
};

export default {
	name: 'manuscript',
	data: () => {
		return {
			headers: [
				{ text: 'Manuscript Title', value: 'manuscript_title' },
				{ text: 'Manuscript Description', value: 'manuscript_description' },
				{ text: 'Manuscript Type Name', value: 'manuscript_type_name' },
				{ text: 'Manuscript Status Name', value: 'manuscript_status_name' },
				{ text: 'Language', value: 'language_name' },
				{ text: '', value: 'action', sortable: false },
			],
			dataset: [],
			dialog: false,
			dialogDelete: false,
			dialogError: false,
			deleteID: 0,
			deleteText: '',
			errorText: '',
			editedItem: DefaultItem,
			defaultItem: DefaultItem,
			currentItem: DefaultItem,
			languages: [],
			manuscriptTypes: [],
			manuscriptStatuses: [],
		}
	},
	computed: {
		formTitle() {
			return this.editedItem.manuscript_id === 0 ? 'New Manuscript' : 'Edit Manuscript';
		}
	},
	watch: {
		dialog( val ) {
			if ( this.editedItem.manuscript_id === 0 ) {
				this.editedItem = Object.assign( {}, this.defaultItem );
			}
			if ( ! val ) {
				this.close();
			}
		},
		dialogDelete( val ) {
			if ( ! val ) {
				this.close();
			}
		}
	},
	created() {
		this.load();
		this.loadLanguages();
		this.loadManuscriptTypes();
		this.loadManuscriptStatuses();
	},
	methods: {
		editItem( item ) {
			this.editedItem = Object.assign( {}, item );
			this.currentItem = Object.assign( {}, this.editedItem );
			this.dialog = true;
		},
		deleteItem( item ) {
			this.deleteID = item.manuscript_id;
			this.deleteText = item.manuscript_title;
			this.dialogDelete = true;
		},
		close() {
			this.dialog = false;
			setTimeout( () => {
				this.editedItem = Object.assign( {}, this.defaultItem );
			}, 100 );
		},
		closeDelete() {
			this.dialogDelete = false
			setTimeout( () => {
				this.deleteID = 0;
			}, 100 );
		},
		showError( res, err, defaultText ) {
			this.errorText = defaultText;
			if ( err && err.response && err.response.data && err.response.data.error ) {
				this.errorText += ': ' + err.response.data.error;
			} else if ( res && res.data.error ) {
				this.errorText += ': ' + res.data.error;
			} else if ( err && 'Error: Network Error' == err.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.dialogError = true;
		},
		closeError() {
			this.errorText = '';
			this.dialogError = false;
		},
		update() {
			const accel_api = api( this );
			const updateFields = {};
			let updated = false;
			for ( const field in this.editedItem ) {
				if ( this.currentItem[field] != this.editedItem[field] ) {
					updateFields[field] = this.editedItem[field];
					updated = true;
				}
			}
			if ( ! updated ) {
				this.close();
				return;
			}
			accel_api.put( endPoint + '/' + this.editedItem.manuscript_id, updateFields )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.load();
					} else {
						this.showError( res, null, 'Failed to update the Manuscript' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to update the Manuscript' );
				} );
			this.close();
		},
		save() {
			const accel_api = api( this );
			accel_api.post( endPoint, this.editedItem )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.load();
					} else {
						this.showError( res, null, 'Failed to add the Manuscript' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to add the Manuscript' );
				} );
			this.close();
		},
		deactivate() {
			const accel_api = api( this );
			accel_api.delete( endPoint + '/' + this.deleteID )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.load();
					} else {
						this.showError( res, null, 'Failed to deactivate the Manuscript' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to deactivate the Manuscript' );
				} );
			this.closeDelete();
		},
		async load() {
			const accel_api = api( this );
			accel_api.get( endPoint )
				.then( res => {
					window.console.log( res );
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.dataset = res.data.data;
					} else if ( 401 == res.status ) {
						window.console.log( '401: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load the Manuscript' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to load the Manuscript' );
				} )
		},
		async loadLanguages() {
			const accel_api = api( this );
			accel_api.get( '/language' )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.languages = res.data.data;
					} else if ( 401 == res.status ) {
						window.console.log( '410: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load the manuscript types' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to load the manuscript types' );
				} )
		},
		async loadManuscriptTypes() {
			const accel_api = api( this );
			accel_api.get( '/manuscript-type' )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.manuscriptTypes = res.data.data;
					} else if ( 401 == res.status ) {
						window.console.log( '410: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load the manuscript types' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to load the manuscript types' );
				} )
		},
		async loadManuscriptStatuses() {
			const accel_api = api( this );
			accel_api.get( '/manuscript-status' )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.manuscriptStatuses = res.data.data;
					} else if ( 401 == res.status ) {
						window.console.log( '410: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load the manuscript types' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to load the manuscript types' );
				} )
		},
	}
}
</script>

<style scoped>
</style>
