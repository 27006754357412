<template>
	<v-card>
		<v-card-title>
			<v-icon @click="$router.go(-1)" class="pr-2">mdi-arrow-left</v-icon>
			<span class="text-h5">{{ formTitle }}</span>
		</v-card-title>
		<v-card flat>
			<v-container>
				<illustrator-form ref="form" :value="item" @input="onFormSaved" @loading="onFormLoading" @changed="onFormChanged" @error="showError"></illustrator-form>

				<v-divider class="mt-4 mb-6" v-if="item && item.assets"></v-divider>
				<v-row v-if="item && item.assets">
					<v-col cols="12">
						<div class="text-subtitle-1">Assigned Assets</div>
						<ac-assets-list :assets="item.assets"></ac-assets-list>
					</v-col>
				</v-row>
			</v-container>

			<v-divider class="mt-4 mb-4"></v-divider>

			<v-card-text>
				<span class="text-body-2">* indicates required fields</span>
			</v-card-text>

		</v-card>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="info" text :disabled="!changed" :loading="loading" @click="saveForm">Save</v-btn>
			<v-btn color="error" text :disabled="!changed" @click="showReset">Reset</v-btn>
		</v-card-actions>
		<ac-dialog-confirm v-model="resetDialog" :message="'Are you sure you want to reset?\nAll information will be lost.'" @confirm="resetForm" />
		<ac-dialog-error v-model="errorDialog" :text="errorText" />
	</v-card>
</template>

<script>
import api from '@/services/api';

import illustratorForm from '@/components/illustrators/form.vue';

const endpoint = '/illustrators';

export default {
	name: 'illustrator',
	components: {
		'illustrator-form': illustratorForm,
	},
	data: () => {
		return {
			errorText: '',
			errorDialog: false,
			resetDialog: false,
			itemId: null,
			item: null,
			changed: false,
			loading: false,
			users: [],
			countries: [],
			genders: [],
		};
	},
	created() {
		this.itemId = this.$route.params.illustrator_id || null;
	},
	computed: {
		formTitle() {
			if ( this.itemId ) {
				return 'Edit Illustrator Record';
			}

			return 'Create New Illustrator Record';
		},
	},
	watch: {
		$route( to, from ) {
			if ( ! to.params.illustrator_id ) {
				// This is the route to create a new item, assign the default values;
				this.itemId = null;

				return;
			}

			if ( from.params && this.itemId === from.params.illustrator_id ) {
				// Same illustrator, just reset the values to their original values
				this.resetForm();

				return;
			}

			this.itemId = to.params.illustrator_id;
		},
		itemId( val ) {
			if ( ! val ) {
				this.item = null;
				return;
			}

			this.load( val );
		},
	},
	methods: {
		saveForm() {
			this.$refs.form.save();
		},
		resetForm() {
			this.$refs.form.reset();
		},
		onFormChanged( val ) {
			this.changed = val;
		},
		onFormLoading( val ) {
			this.loading = val;
		},
		onFormSaved( val ) {
			if ( this.itemId ) {
				// We have updated an existing illustrator. Set the new object and load the illustrator again.
				this.item = val;
				this.load( this.itemId );
			} else {
				// We have created an illustrator. Navigate back.
				this.$router.back();
			}
		},
		showReset() {
			this.resetDialog = true;
		},
		showError( event ) {
			const { response, error, message } = event;

			this.errorText = message;
			if ( error && error.response && error.response.data && error.response.data.error ) {
				this.errorText += ': ' + error.response.data.error;
			} else if ( response && response.data.error ) {
				this.errorText += ': ' + response.data.error;
			} else if ( error && 'Error: Network Error' == error.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.errorDialog = true;
		},
		async getResource( url, errorMsg, data ) {
			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : null );
			} catch ( err ) {
				this.showError( { error: err, message: errorMsg } );
			}

			if ( 401 == res.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res.status || 'success' !== res.data.result ) {
				this.showError( { response: res, message: errorMsg } );

				return;
			}

			return res.data.data;
		},
		async load( id ) {
			const illustrator = ( await this.getResource( `${endpoint}/${id}`, 'Failed to load the Illustrator' ) )[0];
			if ( illustrator ) {
				this.item = illustrator;
			} else {
				// TODO create "not found" view
				this.item = null;
			}
		},
	},
};
</script>

<style scoped>
</style>
