<template>
	<v-checkbox v-bind="{ ...$attrs }" :style="style" v-on="listeners" v-model="internalValue" :class="{ active: currentValue }" :color="computedColor" :on-icon="onIcon" :indeterminate-icon="offIcon" :off-icon="indeterminateIcon" :indeterminate="currentState.indeterminate"></v-checkbox>
</template>

<script>
const STATES = [
	{ value: true, indeterminate: false, prop: 'trueValue' },
	{ value: true, indeterminate: true, prop: 'falseValue' },
	{ value: false, indeterminate: false, prop: 'indeterminateValue' },
];

export default {
	name: 'ac-tri-state-checkbox',
	props: {
		color: String,
		falseValue: {
			validator: () => true,
			default: 0,
		},
		indeterminateValue: {
			validator: () => true,
			default: null,
		},
		indeterminateIcon: {
			type: String,
			default: 'mdi-checkbox-blank-outline',
		},
		offIcon: {
			type: String,
			default: 'mdi-close-box',
		},
		onIcon: {
			type: String,
			default: 'mdi-checkbox-marked',
		},
		trueValue: {
			validator: () => true,
			default: 1,
		},
		value: {
			validator: () => true,
		},
	},
	data: () => {
		return {};
	},
	computed: {
		currentStateIndex() {
			switch ( this.value ) {
			case this.trueValue:
				return 0;
			case this.falseValue:
				return 1;
			default:
				return 2;
			}
		},
		currentState() {
			return STATES[this.currentStateIndex];
		},
		currentValue() {
			return this.currentState.value;
		},
		computedColor() {
			return this.color || 'primary';
		},
		style() {
			const vars = { ...this.$attrs.style };
			if ( this.color ) {
				vars['--icon-color'] = this.color;
			}

			return vars;
		},
		internalValue: {
			get() {
				return this.currentValue;
			},
			set() {
				// Emit an 'input' event with the next state
				const nextIndex = ( this.currentStateIndex + 1 ) % STATES.length;
				this.$emit( 'input', this.$props[STATES[nextIndex].prop] );
			},
		},
		listeners() {
			// Filter out the listener for the `input` event. We need to hijack it to cycle between the three states.
			// eslint-disable-next-line no-unused-vars
			const { input, ...rest } = this.$listeners;

			return rest;
		},
	},
	watch: {},
	created() {},
	methods: {},
};
</script>

<style scoped>
.active,
.active >>> i,
.active >>> v-input--selection-controls__ripple {
	color: var(--icon-color, var(--v-primary-base)) !important;
}
</style>
