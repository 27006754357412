<template>
	<div>
		<div v-for="( group, groupIndex ) in groups" :key="groupIndex + '.' + group.header" class="mt-3">
			<div class="pl-8 text-body-1">
				{{ group.header }}
			</div>
			<ac-search-card-item v-for="manuscript in group.manuscripts" :key="manuscript.manuscript_status_id" :title="manuscript.manuscript_status_name" :value="null != manuscript.manuscript_done">
				<v-list-item class="pl-8">
					<v-list-item-action class="mr-4">
						<ac-tri-state-checkbox :value="manuscript.manuscript_done" @input="manuscriptChange( manuscript.manuscript_status_id, $event )" :trueValue="true" :falseValue="false" />
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>Completed</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</ac-search-card-item>
		</div>
	</div>
</template>

<script>
import api from '@/services/api';

export default {
	name: 'asset-manuscripts-search',
	components: {},
	props: {
		value: Array,
	},
	data: () => {
		return {
			manuscriptStatuses: [],
		};
	},
	created() {
		this.loadManuscriptStatuses();
	},
	computed: {
		groups() {
			// Get the groups of manuscript statuses, sorted and grouped by status type name
			const groups = [];
			let currentHeader = null;

			for ( const status of this.manuscriptStatuses ) {
				if ( null === currentHeader || currentHeader !== status.manuscript_status_type_name ) {
					currentHeader = status.manuscript_status_type_name;
					groups.push( {
						header: currentHeader,
						manuscripts: [],
					} );
				}

				const manuscript = this.value ? this.value.find( ( m ) => m.manuscript_status_id === status.manuscript_status_id ) : null;

				groups[groups.length - 1].manuscripts.push( {
					manuscript_status_id: status.manuscript_status_id,
					manuscript_status_name: status.manuscript_status_name,
					manuscript_done: manuscript ? manuscript.manuscript_done : null,
				} );
			}

			return groups;
		},
	},
	watch: {},
	methods: {
		async getResource( url, errorMsg, data ) {
			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : null );
			} catch ( err ) {
				this.showError( null, err, errorMsg );
			}

			if ( 401 == res.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res.status || 'success' !== res.data.result ) {
				this.showError( res, null, errorMsg );

				return;
			}

			return res.data.data;
		},
		async loadManuscriptStatuses() {
			// Get the a list of manuscript statuses sorted by process_order
			const statuses = await this.getResource( '/manuscript-status', 'Failed to load the Manuscript Statuses' );
			statuses.sort( ( s1, s2 ) => s1.process_order - s2.process_order );

			this.manuscriptStatuses = statuses;
		},
		manuscriptChange( id, val ) {
			let exists = false;
			const newValues = [];
			if ( this.value ) {
				this.value.forEach( ( manuscript ) => {
					const newValue = { ...manuscript };

					if ( id === manuscript.manuscript_status_id ) {
						exists = true;
						newValue.manuscript_done = val;
					}

					if ( newValue.manuscript_done != null ) {
						newValues.push( newValue );
					}
				} );
			}

			if ( ! exists && null != val ) {
				newValues.push( { manuscript_status_id: id, manuscript_done: val } );
			}

			this.$emit( 'input', newValues && newValues.length ? newValues : null );
		},
	},
};
</script>

<style scoped>
</style>