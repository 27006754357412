


































import Vue from 'vue';
import { PropType } from 'vue';
import FileInfo from '@/models/file-info';

export default Vue.extend( {
	name: 'ac-file-select',
	props: {
		accept: String,
		dense: Boolean,
		flat: Boolean,
		title: {
			type: String,
			default: 'Select a file',
		},
		value: Object as PropType<FileInfo | null>,
	},
	data: () => {
		return {};
	},
	computed: {},
	watch: {},
	methods: {
		onChange() {
			const file = ( this.$refs.file as any ).files[0];

			if ( ! file ) {
				this.$emit( 'input', null );
			}

			const fileInfo: FileInfo = {
				file_name: file.name,
				size: file.size,
				file,
			};
			this.$emit( 'input', fileInfo );
		},
	},
} );
