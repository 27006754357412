







import { Component, Vue } from 'vue-property-decorator';
import SubjectAuthorities from '@/components/subject-authorities/list.vue';

@Component( {
	components: {
		SubjectAuthorities,
	},
} )
export default class SubjectAuthoritiesView extends Vue {}
