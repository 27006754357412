



























import Vue from 'vue';
import api from '@/services/api';

export default Vue.extend( {
	name: 'collection-asset-card',
	props: {
		value: Object,
		showRemove: Boolean,
		showOrder: Boolean,
	},
	data: () => {
		return {};
	},
	computed: {},
	watch: {},
	created() {
		this.loadResources();
	},
	methods: {
		remove() {
			this.$emit( 'remove', this.value );
		},
		sendError( res: any, err: any, defaultText: any ) {
			this.$emit( 'error', { response: res, error: err, message: defaultText } );
		},
		async getResource( url: string, errorMsg: string, data?: any ) {
			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : undefined );
			} catch ( err ) {
				this.sendError( null, err, errorMsg );

				return;
			}

			if ( 401 == res.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res.status || 'success' !== res.data.result ) {
				this.sendError( res, null, errorMsg );

				return;
			}

			return res.data.data;
		},
		loadResources() {
			// TODO: load cover image
		},
	},
} );
