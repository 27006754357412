







import { Component, Vue } from 'vue-property-decorator';
import Collection from '@/components/collections/single.vue';

@Component( {
	components: {
		Collection,
	},
} )
export default class CollectionView extends Vue {}
