








import Vue from 'vue';
import api from '@/services/api';

const endPoint = '/topics';

export default Vue.extend( {
	name: 'topics',
	components: {},
	data: () => {
		return {
			headers: [
				{ text: 'Name', value: 'topic_name', align: 'center', divider: true },
				{ text: 'Name (Reader App)', value: 'topic_type', align: 'center' },
			],
			dataset: [],
			dialogError: false,
			errorText: '',
		};
	},
	watch: {},
	computed: {
		itemsPerPage: {
			get() {
				return this.$store.getters.itemsPerList( 'topics' );
			},
			set( val ) {
				this.$store.dispatch( 'SET_ITEMS_PER_LIST', {
					listName: 'topics',
					number: val,
				} );
			},
		},
	},
	created() {
		this.load();
	},
	methods: {
		showError( res: any, err: any, defaultText: string ) {
			this.errorText = defaultText;
			if ( err && err.response && err.response.data && err.response.data.error ) {
				this.errorText += ': ' + err.response.data.error;
			} else if ( res && res.data.error ) {
				this.errorText += ': ' + res.data.error;
			} else if ( err && 'Error: Network Error' == err.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.dialogError = true;
		},
		async load() {
			const accel_api = api( this );
			accel_api
				.get( endPoint )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.dataset = res.data.data;
					} else if ( 401 == res.status ) {
						window.console.log( '401: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load the Topics' );
					}
				} )
				.catch( ( err ) => {
					this.showError( null, err, 'Failed to load the Topics' );
				} );
		},
	},
} );
