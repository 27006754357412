






import { Component, Vue } from 'vue-property-decorator';
import Authors from '@/components/authors/list.vue';
@Component( {
	components: {
		Authors,
	},
} )
export default class AuthorsView extends Vue {}
