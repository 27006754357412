







import { Component, Vue } from 'vue-property-decorator';
import Subjects from '@/components/subjects/list.vue';

@Component( {
	components: {
		Subjects,
	},
} )
export default class SubjectsView extends Vue {}
