<template>
	<v-row align="center">
		<v-col cols="6" md="3">
			<span class="text-body-1">{{ value.manuscript_status_name }}</span>
		</v-col>
		<v-col cols="2" md="1">
			<v-switch v-model="done"></v-switch>
		</v-col>
		<v-col cols="4" md="2">
			<v-menu :ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
				<template v-slot:activator="{ on, attrs }">
					<v-text-field :value="formatDate(selectedDate)" label="Date" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
				</template>
				<v-date-picker ref="date" v-model="selectedDate" no-title scrollable @input="menu = false">
					<v-spacer></v-spacer>
					<v-btn text color="primary" @click="clear">
						Clear
					</v-btn>
				</v-date-picker>
			</v-menu>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'asset-manuscripts-list-item',
	props: {
		value: Object,
	},
	data: () => {
		return {
			menu: false,
		};
	},
	computed: {
		done: {
			get() {
				return this.value.manuscript_done;
			},
			set( val ) {
				if ( this.value.manuscript_done === val ) {
					return;
				}

				this.$emit( 'input', { ...this.value, manuscript_done: !! val } );
			},
		},
		selectedDate: {
			get() {
				return this.value.manuscript_date;
			},
			set( val ) {
				if ( this.value.manuscript_date === val ) {
					return;
				}

				this.$emit( 'input', { ...this.value, manuscript_date: val } );
			},
		},
	},
	created() {
		this.date = this.value.manuscript_date;
	},
	methods: {
		clear() {
			this.menu = false;
			this.selectedDate = null;
		},
		formatDate( date ) {
			if ( ! date ) return null;

			// Dates from the date picker are always ISO strings
			const [year, month, day] = date.split( '-' );
			return `${day}/${month}/${year}`;
		},
	},
	watch: {},
};
</script>

<style scoped lang="scss">
</style>