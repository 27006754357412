




















import GlobalMixin from '@/mixins/globalMixin';
import { isEqual } from 'lodash';
import api from '@/services/api';
import QuestionsPage from '@/models/questions-page';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';

const endpoint = '/questions-pages';
const getDefaultItem = (): QuestionsPage => {
	return {
		name: '',
		description: '',
		content: '',
	};
};

export default GlobalMixin.extend( {
	mixins: [validationMixin],
	name: 'questions-page-form',
	props: {
		requiredOnly: {
			type: Boolean,
			default: false,
		},
		value: Object,
	},
	data: () => {
		return {
			editedItem: getDefaultItem(),
			loading: false,
		};
	},
	created() {
		this.loadFormResources();
	},
	validations: {
		editedItem: {
			name: { required, maxLength: maxLength( 255 ) },
			description: { maxLength: maxLength( 1000 ) },
			content: { maxLength: maxLength( 16000 ) },
		},
	},
	computed: {
		item(): QuestionsPage {
			return ( this.value as QuestionsPage ) || getDefaultItem();
		},
		updatedFields(): Partial<QuestionsPage> {
			const updatedFields: any = {};

			let field: keyof QuestionsPage;
			for ( field in this.editedItem ) {
				if ( ! isEqual( this.item[field], this.editedItem[field] ) ) {
					updatedFields[field] = this.editedItem[field];
				}
			}

			return updatedFields;
		},
		hasUpdates(): boolean {
			return Object.keys( this.updatedFields ).length > 0;
		},
		nameErrors(): string[] {
			const errors: string[] = [];
			if ( ! this.$v.editedItem.name?.$dirty ) return errors;
			! this.$v.editedItem.name.required && errors.push( 'This field is required.' );
			! this.$v.editedItem.name.maxLength && errors.push( 'Must be at most 255 characters long' );
			return errors;
		},
		descriptionErrors(): string[] {
			const errors: string[] = [];
			if ( ! this.$v.editedItem.description?.$dirty ) return errors;
			! this.$v.editedItem.description.maxLength && errors.push( 'Must be at most 1000 characters long' );
			return errors;
		},
		contentErrors(): string[] {
			const errors: string[] = [];
			if ( ! this.$v.editedItem.content?.$dirty ) return errors;
			! this.$v.editedItem.content.maxLength && errors.push( 'Must be at most 16000 characters long' );
			return errors;
		},
	},
	watch: {
		item: {
			deep: true,
			immediate: true,
			handler( val ) {
				this.editedItem = JSON.parse( JSON.stringify( val ) );
			},
		},
		hasUpdates: {
			handler( val ) {
				this.$emit( 'changed', val );
			},
		},
		loading( val ) {
			this.$emit( 'loading', val );
		},
	},
	methods: {
		loadFormResources() {
			// No resources for this form
		},
		reset() {
			this.editedItem = JSON.parse( JSON.stringify( this.item ) );
			this.$v.$reset();
		},
		sendInput( val: any ) {
			this.$emit( 'input', val );
		},
		async save() {
			this.$v.$touch();
			if ( this.$v.$invalid ) {
				return;
			}

			if ( this.item.questions_page_id ) {
				return this.updateItem();
			} else {
				return this.createItem();
			}
		},
		async createItem() {
			this.loading = true;

			const accel_api = api( this );
			let res;
			try {
				res = await accel_api.post( endpoint, this.editedItem );

				if ( 200 !== res.status || 'success' !== res.data.result ) {
					this.loading = false;
					this.sendError( res, null, 'Failed to save the Collection' );
					return;
				}
			} catch ( err ) {
				this.sendError( null, err, 'Failed to save the Collection' );
				return;
			} finally {
				this.loading = false;
			}

			this.sendInput( res.data.data?.length ? res.data.data[0] : [] );
		},
		async updateItem() {
			this.loading = true;

			const accel_api = api( this );

			if ( ! this.hasUpdates ) {
				// No update to process, just reset the original values
				this.loading = false;
				this.reset();
				this.sendInput( { ...this.editedItem } );
				return;
			}
			let res;
			try {
				res = await accel_api.put( `${endpoint}/${this.editedItem.questions_page_id}`, this.updatedFields );

				if ( 200 !== res.status || 'success' !== res.data.result ) {
					this.loading = false;
					this.sendError( res, null, 'Failed to save the Questions Page' );
					return;
				}
			} catch ( err ) {
				this.sendError( null, err, 'Failed to save the Questions Page' );
				return;
			} finally {
				this.loading = false;
			}

			this.sendInput( { ...this.editedItem } );
		},
	},
} );
