<template>
	<v-app id="app" :style="{background: $vuetify.theme.themes[theme].background}">
		<v-navigation-drawer v-model="drawer" app right color="secondary" v-if="activeUser">
			<v-list dense>
				<div v-for="item in currentMenu" :key="item.menu_id">
					<v-list-group v-if="item.child" v-model="item.active">
						<template v-slot:activator>
							<v-list-item-title class="ma-0">
								<v-icon class="pr-2">{{item.icon_text}}</v-icon>{{item.title}}
							</v-list-item-title>
						</template>
						<v-list-item class="pl-8" :disabled="itemChild.active" v-on:click="$router.push({ name: itemChild.route_name })" v-for="itemChild in item.child" :key="itemChild.menu_id">
							<v-list-item-title class="ma-0">
								<v-icon class="pr-2">{{itemChild.icon_text}}</v-icon>{{itemChild.title}}
							</v-list-item-title>
						</v-list-item>
					</v-list-group>
					<v-list-item v-else :disabled="item.active" v-on:click="$router.push({ name: item.route_name })">
						<v-list-item-title class="ma-0">
							<v-icon class="pr-2">{{item.icon_text}}</v-icon>{{item.title}}
						</v-list-item-title>
					</v-list-item>
				</div>

				<v-divider class="my-2"></v-divider>

				<v-list-item v-on:click="logOut">
					<v-list-item-title class="ma-0 red--text">
						<v-icon class="pr-2">mdi-exit-to-app</v-icon>Sign Out
					</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
		<v-app-bar app color="secondary" dense>
			<router-link to="/"><img width="40" src="@/assets/books-icon.png" /></router-link>
			<v-toolbar-title class="pl-1"><strong>Accelerate</strong></v-toolbar-title>
			<v-spacer></v-spacer>
			<div v-if="null !== activeUser">
				<v-avatar style="cursor: pointer" size="36" class="mr-2" color="primary" :disabled="'profile' === this.$router.history.current.name" v-on:click="$router.push('/profile')">
					<span class="white--text">{{ this.$store.getters.userInitials }}</span>
				</v-avatar>
				<v-icon class="pr-2" :disabled="'notifications' === this.$router.history.current.name" v-on:click="$router.push('/notifications')">mdi-bell-outline</v-icon>
				<v-icon class="pr-2" :disabled="'dashboard' === this.$router.history.current.name" v-on:click="$router.push('/')">mdi-view-dashboard-outline</v-icon>
				<v-app-bar-nav-icon @click="drawer = !drawer" />
			</div>
		</v-app-bar>

		<v-main>
			<v-container fluid>
				<router-view></router-view>
			</v-container>
		</v-main>

		<ac-snackbar :value="showInfoSnackbar" :message="infoMessageContent" @input="onInfoInput" :persistent="isInfoPersistent" />
		<ac-dialog-alert :value="showInfoDialog" :message="infoMessageContent" @input="onInfoInput" :persistent="isInfoPersistent" max-width="400px" scrollable />
	</v-app>
</template>

<script>
import api from './services/api';

export default {
	name: 'App',
	components: {
		//
	},
	data() {
		return {
			user: null,
			drawer: false,
		};
	},
	computed: {
		activeUser() {
			const user = this.$store.getters.USER;
			if ( ! user ) {
				return null;
			}
			return user;
		},
		theme() {
			return this.$vuetify.theme.dark ? 'dark' : 'light';
		},
		currentMenu() {
			const items = [];
			this.menus.forEach( ( item ) => {
				let active = false;

				if ( item.child ) {
					const child = item.child.map( ( childItem ) => {
						const childActive = childItem.route_name == this.$route.name;
						active = active || childActive;

						return {
							...childItem,
							active: childActive,
						};
					} );

					items.push( {
						...item,
						child,
						active,
					} );
				} else if ( null != item.route_name && '' !== String( item.route_name ).trim() ) {
					active = item.route_name == this.$route.name;
					items.push( {
						...item,
						active,
					} );
				}
			} );

			return items;
		},
		infoMessage() {
			return this.$store.getters.InfoMessage;
		},
		infoMessageContent() {
			return this.infoMessage ? this.infoMessage.message : '';
		},
		isInfoPersistent() {
			return this.infoMessage && this.infoMessage.persistent;
		},
		showInfoDialog() {
			return this.infoMessage && this.infoMessage.dialog;
		},
		showInfoSnackbar() {
			return this.infoMessage && ! this.infoMessage.dialog;
		},
	},
	methods: {
		logOut: function () {
			const accel_api = api( this );

			if ( ! this.$store.getters.USER || ! this.$store.getters.USER.user_id || ! this.$store.getters.USER.token ) {
				this.$store.dispatch( 'SET_USER', null );
				this.$store.dispatch( 'SET_MENU', null );
				this.$store.dispatch( 'SET_USER_ROLE_PERMISSION', null );
				this.$router.push( '/login' );
				return;
			}
			accel_api
				.post( '/logout' )
				.then( () => {
					this.$store.dispatch( 'SET_USER', null );
					this.$store.dispatch( 'SET_MENU', null );
					this.$store.dispatch( 'SET_USER_ROLE_PERMISSION', null );
					this.$router.push( '/login' );
					return;
				} )
				.catch( ( err ) => {
					window.console.log( err );
					this.$store.dispatch( 'SET_USER', null );
					this.$store.dispatch( 'SET_MENU', null );
					this.$store.dispatch( 'SET_USER_ROLE_PERMISSION', null );
					this.$router.push( '/login' );
				} );
		},
		onInfoInput( val ) {
			if ( ! val ) {
				this.$store.dispatch( 'DISMISS_INFO_MESSAGE' );
			}
		},
	},
};
</script>

<style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
}
</style>
