



























import Vue from 'vue';
import QuestionsPageForm from '@/components/questions-pages/form.vue';
import QuestionsPage from '@/models/questions-page';
import api from '@/services/api';

const endpoint = '/questions-pages';
const getDefaultItem = (): QuestionsPage => {
	return {
		questions_page_id: undefined,
		name: '',
		description: '',
		content: '',
		active: undefined,
	};
};

export default Vue.extend( {
	name: 'questions-page',
	components: {
		'questions-page-form': QuestionsPageForm,
	},
	data: () => {
		return {
			errorText: '',
			errorDialog: false,
			resetDialog: false,
			item: getDefaultItem() as QuestionsPage,
			changed: false,
			loading: false,
			readerCategories: [],
		};
	},
	created() {
		this.item.questions_page_id = Number( this.$route.params.questions_page_id ) || undefined;
	},
	computed: {
		formTitle(): string {
			if ( this.item.questions_page_id ) {
				return `${this.item.name} questions page`;
			}

			return 'Create new Questions Page';
		},
	},
	watch: {
		$route( to, from ) {
			if ( ! to.params.questions_page_id ) {
				// This is the route to create a new item, assign the default values;
				this.item = getDefaultItem();

				return;
			}

			if ( from.params && this.item.questions_page_id === from.params.questions_page_id ) {
				// Same item, just reset the values to their original values
				this.resetForm();

				return;
			}

			this.item.questions_page_id = Number( to.params.questions_page_id );
		},
		'item.questions_page_id': {
			handler: function ( val ) {
				if ( ! val ) {
					return;
				}

				this.load( val );
			},
		},
	},
	methods: {
		saveForm() {
			( this.$refs.form as any ).save();
		},
		resetForm() {
			( this.$refs.form as any ).reset();
		},
		onFormChanged( val: boolean ) {
			this.changed = val;
		},
		onFormLoading( val: boolean ) {
			this.loading = val;
		},
		onFormSaved( val: QuestionsPage ) {
			// We have updated an existing item. Set the new object and load the item again.
			this.item = val;
			// this.load( this.item.questions_page_id! );
		},
		showReset() {
			this.resetDialog = true;
		},
		showError( event: any ) {
			const { response, error, message } = event;

			this.errorText = message;
			if ( error && error.response && error.response.data && error.response.data.error ) {
				this.errorText += ': ' + error.response.data.error;
			} else if ( response && response.data.error ) {
				this.errorText += ': ' + response.data.error;
			} else if ( error && 'Error: Network Error' == error.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.errorDialog = true;
		},
		async getResource( url: string, errorMsg: string, data?: any ) {
			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : undefined );
			} catch ( err ) {
				this.showError( { error: err, message: errorMsg } );

				return;
			}

			if ( 401 == res.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res.status || 'success' !== res.data.result ) {
				this.showError( { response: res, message: errorMsg } );

				return;
			}

			return res.data.data;
		},
		async load( id: number ) {
			const item = ( await this.getResource( `${endpoint}/${id}`, 'Failed to load the Questions Page' ) )[0];
			if ( item ) {
				this.item = item;
			} else {
				// TODO create "not found" view
				this.item = getDefaultItem();
			}
		},
	},
} );
