


























































import Vue from 'vue';
import { isEqual } from 'lodash';
import api from '@/services/api';
import ReaderCategory from '@/models/reader-category';
import CollectionCategory from './collection-category.vue';
import CollectionCategoryCreate from './collection-category-create.vue';

const endpoint = '/reader-categories';

export default Vue.extend( {
	components: {
		'collection-category': CollectionCategory,
		'collection-category-create': CollectionCategoryCreate,
	},
	name: 'collection-categories',
	props: {
		collectionId: {
			type: Number,
			required: true,
		},
	},
	data: () => {
		return {
			errorText: '',
			errorDialog: false,
			editing: false,
			loading: false,
			items: [] as Array<ReaderCategory>,
			editItems: [] as Array<ReaderCategory>,
			loadingItems: [] as Array<boolean>,
			openCategory: undefined as undefined | number,
			confirmChangeMessage: '',
			confirmDialog: false,
		};
	},
	watch: {
		collectionId: {
			handler( val: number ) {
				this.load( val );
			},
			immediate: true,
		},
		items( val: Array<ReaderCategory> ) {
			this.editItems = JSON.parse( JSON.stringify( val ) ).sort( ( a: ReaderCategory, b: ReaderCategory ) => a.order - b.order );
			this.loadingItems = this.editItems.map( () => false );
		},
		editing( val: boolean ) {
			if ( val ) {
				this.openCategory = undefined;
			}
		},
	},
	created() {},
	computed: {
		confirmChangesMessage(): string {
			return 'Are you sure you want to save the changes to the categories?';
		},
		loadingCategory(): boolean {
			return this.loadingItems.find( ( item ) => item ) != null;
		},
	},
	methods: {
		moveItemUp( index: number ) {
			if ( index > 0 ) {
				const prev = this.editItems[index - 1];
				const curr = this.editItems[index];
				const newArray = Array.prototype.slice.call( this.editItems );
				newArray[index - 1] = curr;
				newArray[index] = prev;
				this.editItems = newArray;
			}

			this.updateItemsOrder();
		},
		moveItemDown( index: number ) {
			if ( index < this.editItems.length - 1 ) {
				const next = this.editItems[index + 1];
				const curr = this.editItems[index];
				const newArray = Array.prototype.slice.call( this.editItems );
				newArray[index + 1] = curr;
				newArray[index] = next;
				this.editItems = newArray;
			}

			this.updateItemsOrder();
		},
		addItem( val: ReaderCategory ) {
			this.editItems.push( val );

			this.updateItemsOrder();
		},
		removeItem( index: number ) {
			this.editItems.splice( index, 1 );

			this.updateItemsOrder();
		},
		updateItemsOrder() {
			this.editItems.forEach( ( item, index ) => {
				item.order = index + 1;
			} );
		},
		cancel() {
			this.editItems = JSON.parse( JSON.stringify( this.items ) ).sort( ( a: ReaderCategory, b: ReaderCategory ) => a.order - b.order );

			this.editing = false;
		},
		async saveAssets( val: ReaderCategory, index: number ) {
			this.loadingItems = this.loadingItems.map( ( item, i ) => i === index );

			await this.updateItem( { reader_category_id: val.reader_category_id, assets: val.assets } );
			await this.load( this.collectionId );

			this.loadingItems = this.editItems.map( () => false );
		},
		async save() {
			this.loading = true;

			const saveItems: Array<ReaderCategory> = [];
			const updateItems: Array<Partial<ReaderCategory>> = [];
			const deleteItems: Array<ReaderCategory> = [];

			for ( const item of this.editItems ) {
				const existing = this.items.find( ( oldItem ) => {
					if ( item.reader_category_id ) {
						return item.reader_category_id === oldItem.reader_category_id;
					}

					return false;
				} );

				if ( existing ) {
					const updatedFields = this.getUpdatedFields( existing, item );
					if ( Object.keys( updatedFields ).length > 0 ) {
						// If this item corresponds to a previous one, just update it
						updateItems.push( { ...updatedFields, reader_category_id: existing.reader_category_id } );
					}
				} else {
					// Otherwise create it
					saveItems.push( item );
				}
			}

			for ( const item of this.items ) {
				const existing = this.editItems.find( ( newItem ) => newItem.reader_category_id === item.reader_category_id );

				if ( ! existing ) {
					// This item has been removed
					deleteItems.push( item );
				}
			}

			const tasks: Promise<any>[] = saveItems.map( this.createItem ).concat( updateItems.map( this.updateItem ).concat( deleteItems.map( this.deactivateItem ) ) );

			if ( tasks.length > 0 ) {
				await Promise.allSettled( tasks );
				await this.load( this.collectionId );
			}

			this.loading = false;
			this.editing = false;
		},
		async createItem( item: ReaderCategory ) {
			const accel_api = api( this );
			let res;
			try {
				res = await accel_api.post( endpoint, item );

				if ( 200 !== res.status || 'success' !== res.data.result ) {
					this.showError( { response: res, error: null, message: 'Failed to save the Category' } );
					return;
				}
			} catch ( err ) {
				this.showError( { response: null, error: err, message: 'Failed to save the Category' } );
				return;
			}
		},
		async updateItem( item: Partial<ReaderCategory> ) {
			const accel_api = api( this );
			let res;
			try {
				res = await accel_api.put( `${endpoint}/${item.reader_category_id}`, item );

				if ( 200 !== res.status || 'success' !== res.data.result ) {
					this.showError( { response: res, error: null, message: 'Failed to update the Category' } );
					return;
				}

				return res.data.data;
			} catch ( err ) {
				this.showError( { response: null, error: err, message: 'Failed to update the Category' } );
				return;
			}
		},
		async deactivateItem( item: Partial<ReaderCategory> ) {
			const accel_api = api( this );
			let res;

			try {
				res = await accel_api.delete( `${endpoint}/${item.reader_category_id}` );

				if ( 200 !== res.status || 'success' !== res.data.result ) {
					this.showError( { response: res, error: null, message: 'Failed to remove the Category' } );
					return;
				}
			} catch ( err ) {
				this.showError( { response: null, error: err, message: 'Failed to remove the Category' } );
				return;
			}
		},
		showError( event: any ) {
			const { response, error, message } = event;

			this.errorText = message;
			if ( error && error.response && error.response.data && error.response.data.error ) {
				this.errorText += ': ' + error.response.data.error;
			} else if ( response && response.data.error ) {
				this.errorText += ': ' + response.data.error;
			} else if ( error && 'Error: Network Error' == error.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.errorDialog = true;
		},
		async getResource( url: string, errorMsg: string, data?: any ) {
			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : undefined );
			} catch ( err ) {
				this.showError( { error: err, message: errorMsg } );

				return;
			}

			if ( 401 == res.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res.status || 'success' !== res.data.result ) {
				this.showError( { response: res, message: errorMsg } );

				return;
			}

			return res.data.data;
		},
		async load( collectionId: number ) {
			this.loading = true;
			this.items = await this.getResource( endpoint, 'Failed to load the Reader Categories', { collection_id: collectionId } );
			this.loading = false;
		},
		getUpdatedFields( oldItem: ReaderCategory, newItem: ReaderCategory ): Partial<ReaderCategory> {
			const updatedFields: Partial<ReaderCategory> = {};

			let field: keyof ReaderCategory;
			for ( field in newItem ) {
				if ( ! isEqual( oldItem[field], newItem[field] ) ) {
					updatedFields[field] = newItem[field] as any;
				}
			}

			return updatedFields;
		},
		getDefaultItem(): ReaderCategory {
			return {
				reader_category_name: '',
				reader_category_label: '',
				icon: 'personBookOutlined',
				collection_id: this.collectionId,
				order: 1,
				assets: [],
			};
		},
	},
} );
