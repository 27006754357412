<template>
	<div>
		<ac-list-page :headers="headers" :show-search.sync="showSearch" :items="dataset" :items-per-page.sync="itemsPerPage" title="Author Database" sort-by="author_name_english" @update="editItem($event)" @delete="deleteItem($event)" @search="onSearch" @clear="onClear">
			<template v-slot:buttons>
				<v-row class="my-2 mx-0 pa-0">
					<v-col cols="12" md="12" class="py-2">
						<v-btn color="primary" @click="showSearch=true">
							<v-icon class="pr-2">mdi-magnify</v-icon>Search
						</v-btn>
						<v-btn color="primary" @click="createItem" class="my-2 my-sm-0 ms-sm-2" v-if="rolePermission.add == 1">
							Create New Record
						</v-btn>
						<v-btn color="primary" class="my-2 my-sm-0 ms-sm-2" @click="exportAuthor">
							<v-icon class="pr-2">mdi-download</v-icon>Export
						</v-btn>
					</v-col>
				</v-row>
			</template>
			<template v-slot:[`search.title`]>
				<span>Refine</span>
			</template>
			<template v-slot:[`search.inputs`]>
				<v-list expand>
					<v-list-group>
						<template v-slot:activator>
							<v-list-item-title>Author Database</v-list-item-title>
						</template>
						<ac-search-card-item title="Author Name (Destination Language)" :value="!!searchParams.author_name">
							<v-text-field single-line class="short-input" label="Author Name (Destination Language)" v-model="searchParams.author_name"> </v-text-field>
						</ac-search-card-item>
						<ac-search-card-item title="Author Name (English)" :value="!!searchParams.author_name_english">
							<v-text-field single-line class="short-input" label="Author Name (English)" v-model="searchParams.author_name_english"> </v-text-field>
						</ac-search-card-item>
						<ac-search-card-item title="Author First Name" :value="!!searchParams.author_first_name">
							<v-text-field single-line class="short-input" label="Author First Name" v-model="searchParams.author_first_name"> </v-text-field>
						</ac-search-card-item>
						<ac-search-card-item title="Author Gender" :value="!!searchParams.gender_id">
							<ac-select :items="genders" item-value="gender_id" item-text="gender_type" label="Author Gender" v-model="searchParams.gender_id" multiple autocomplete dense solo flat outlined hide-details show-select-all></ac-select>
						</ac-search-card-item>
						<ac-search-card-item title="Author Email" :value="!!searchParams.email">
							<v-text-field single-line class="short-input" label="Author Email" v-model="searchParams.email"> </v-text-field>
						</ac-search-card-item>
						<ac-search-card-item title="Author Address" :value="!!searchParams.address">
							<v-text-field single-line class="short-input" label="Author Address" v-model="searchParams.address"> </v-text-field>
						</ac-search-card-item>
						<ac-search-card-item title="Author Country" :value="!!searchParams.gender_id">
							<ac-select :items="countries" item-value="country_id" item-text="country_name" label="Author Country" v-model="searchParams.country_id" multiple autocomplete dense solo flat outlined hide-details show-select-all></ac-select>
						</ac-search-card-item>
					</v-list-group>
				</v-list>
			</template>
			<template v-slot:[`item.full_address`]="{ item }">
				{{ [ item.address1, item.address2, item.state, item.post_code ].filter(Boolean).join(', ') }}
			</template>
			<template v-slot:[`item.assets`]="{ item }">
				<ac-table-cell-assets :assets="item.assets"></ac-table-cell-assets>
			</template>
		</ac-list-page>
		<ac-dialog-deactivate v-model="dialogDelete" :itemId="deleteID" :itemType="'Author'" :itemName="deleteText" @deactivate="deactivate" />
		<ac-dialog-error v-model="errorDialog" :text="errorText" />
	</div>
</template>

<script>
import api, { decodeQueryParams, encodeQueryParams } from '@/services/api';

const endPoint = '/authors';

export default {
	name: 'authors',
	components: {},
	data: () => {
		return {
			headers: [
				{ text: 'Author Name (English)', value: 'author_name_english', align: 'center', divider: true },
				{ text: 'Author Name (Destination Language)', value: 'author_name', align: 'center', divider: true },
				{ text: 'Author First Name', value: 'author_first_name', align: 'center', divider: true },
				{ text: 'Author Gender', value: 'gender_type', align: 'center', divider: true },
				{ text: 'Author Email Address', value: 'email', align: 'center', divider: true },
				{ text: 'Author Address', value: 'full_address', align: 'center', divider: true },
				{ text: 'Author Country', value: 'country_name', align: 'center', divider: true },
				{ text: 'Assigned Assets', value: 'assets', align: 'center', divider: true },
			],
			dataset: [],
			dialog: false,
			dialogDelete: false,
			errorDialog: false,
			deleteID: 0,
			deleteText: '',
			errorText: '',
			showSearch: false,
			searchParams: {},
			genders: [],
			countries: [],
		};
	},
	watch: {
		$route( to ) {
			this.initSearchParams( to.query );
			this.load();
		},
		deleteDialog( val ) {
			if ( ! val ) {
				this.deleteID = 0;
			}
		},
		showSearch() {
			if ( this.showSearch == false && this.$route.fullPath != '/authors' ) {
				this.$router.push( { name: this.$route.name, query: ( this.searchParams = {} ) } );
				this.load();
			}
		},
	},
	computed: {
		itemsPerPage: {
			get() {
				return this.$store.getters.itemsPerList( 'authors' );
			},
			set( val ) {
				this.$store.dispatch( 'SET_ITEMS_PER_LIST', {
					listName: 'authors',
					number: val,
				} );
			},
		},
	},
	created() {
		this.initSearchParams( this.$route.query );
		this.load();
		this.loadFormResources();
	},
	methods: {
		onSearch() {
			// Ignore `null` parameters
			const queryParams = {};
			for ( const param in this.searchParams ) {
				if ( this.searchParams[param] !== null ) {
					queryParams[param] = this.searchParams[param];
				}
			}

			// Ignore navigation errors (mostly NavigationDuplicated errors), as API errors are already handled in the page. See https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
			this.$router.push( { name: this.$route.name, query: encodeQueryParams( queryParams ) } ).catch( () => {} );
		},
		onClear() {
			for ( const param in this.searchParams ) {
				if ( this.searchParams[param] !== null ) {
					this.searchParams[param] = null;
				}
			}
			// Ignore navigation errors (mostly NavigationDuplicated errors), as API errors are already handled in the page. See https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
			this.$router.push( { name: this.$route.name } ).catch( () => {} );
		},
		initSearchParams( query ) {
			this.searchParams = {};

			if ( query && Object.keys( query ).length > 0 ) {
				this.showSearch = true;
			}

			this.searchParams = decodeQueryParams( query );
		},
		loadFormResources() {
			this.loadCountries();
			this.loadGenders();
		},
		exportAuthor() {
			const exportAuthors = this.dataset;
			// try to parse data into readable format
			const output = this.parseSearch( exportAuthors );
			// create a stub element to simulate a download event.
			const anchor = document.createElement( 'a' );
			anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent( output );
			anchor.target = '_blank';
			anchor.download = 'authors.tsv';
			anchor.click();
		},
		parseSearch( searchResults ) {
			let output = '';
			searchResults = this.sortData( 'author_id', searchResults, 'asc' );
			//Headers
			output += 'Author Name (English)\tAuthor Name (Destination Language)\tAuthor First Name\tAuthor Gender\tAuthor Email Address\tAuthor Address\tAuthor Country\tAssigned Assets\n';
			for ( const index in searchResults ) {
				const item = searchResults[index];
				const gender = this.genders.find( ( gender ) => gender.gender_id === item.gender_id );
				const address = [item.address1, item.address2, item.state, item.post_code].filter( Boolean ).join( ', ' );
				const country = this.countries.find( ( country ) => country.country_id === item.country_id );

				output += `${item.author_name_english ? item.author_name_english : ''}\t`;
				output += `${item.author_name ? item.author_name : ''}\t`;
				output += `${item.author_first_name ? item.author_first_name : ''}\t`;
				output += `${gender ? gender.gender_type : ''}\t`;
				output += `${item.email ? item.email : ''}\t`;
				output += `${address ? address : ''}\t`;
				output += `${country ? country.country_name : ''}\t`;
				output += `${item.assets ? item.assets.map( ( a ) => a.asset_id ).join( ', ' ) : ''}`;
				output += '\n';
			}
			return output;
		},
		sortData( key, data, sortOrder = 'des' ) {
			const ordered = {};
			let compareFunction = function ( a, b ) {
				return data[b][key] - data[a][key];
			};
			if ( sortOrder === 'asc' ) {
				compareFunction = function ( a, b ) {
					return data[a][key] - data[b][key];
				};
			}
			Object.keys( data )
				.sort( compareFunction )
				.forEach( function ( key ) {
					ordered[key] = data[key];
				} );
			return ordered;
		},
		editItem( item ) {
			this.$router.push( `/authors/${item.author_id}` );
		},
		createItem() {
			this.$router.push( '/authors/new' );
		},
		deleteItem( item ) {
			this.deleteID = item.author_id;
			this.deleteText = item.author_name;
			this.dialogDelete = true;
		},
		showError( res, err, defaultText ) {
			this.errorText = defaultText;
			if ( err && err.response && err.response.data && err.response.data.error ) {
				this.errorText += ': ' + err.response.data.error;
			} else if ( res && res.data.error ) {
				this.errorText += ': ' + res.data.error;
			} else if ( err && 'Error: Network Error' == err.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.errorDialog = true;
		},
		deactivate() {
			const accel_api = api( this );
			accel_api
				.delete( endPoint + '/' + this.deleteID )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.load();
					} else {
						this.showError( res, null, 'Failed to deactivate the Author' );
					}
				} )
				.catch( ( err ) => {
					this.showError( null, err, 'Failed to deactivate the Author' );
				} );
			this.deleteID = 0;
		},
		async getResource( url, errorMsg, data ) {
			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : null );
			} catch ( err ) {
				this.showError( null, err, errorMsg );
			}

			if ( 401 == res.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res.status || 'success' !== res.data.result ) {
				this.showError( res, null, errorMsg );

				return;
			}

			return res.data.data;
		},
		async load() {
			const accel_api = api( this );
			accel_api
				.get( endPoint, { params: this.searchParams } )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.dataset = res.data.data;
					} else if ( 401 == res.status ) {
						window.console.log( '401: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load the Author' );
					}
				} )
				.catch( ( err ) => {
					this.showError( null, err, 'Failed to load the Author' );
				} );
		},
		async loadCountries() {
			this.countries = await this.getResource( '/countries', 'Failed to load the Countries' );
		},
		async loadGenders() {
			this.genders = await this.getResource( '/genders', 'Failed to load the Genders' );
		},
	},
};
</script>

<style scoped></style>
