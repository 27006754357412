







import { Component, Vue } from 'vue-property-decorator';
import Menus from '@/components/menus/list.vue';

@Component( {
	components: {
		Menus,
	},
} )
export default class MenusView extends Vue {}
