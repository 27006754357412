









































import Vue from 'vue';
import api from '@/services/api';
import CollectionAssetCard from './collection-asset-card.vue';

const endpoint = '/assets';

export default Vue.extend( {
	name: 'collection-category-asset-selection',
	components: {
		'collection-asset-card': CollectionAssetCard,
	},
	props: {
		collectionId: {
			type: Number,
			required: true,
		},
		selectedAssets: {
			type: Array,
			required: true,
		},
		value: Boolean,
	},
	data() {
		return {
			loading: false,
			items: [],
		};
	},
	computed: {
		show: {
			get(): boolean {
				return this.value;
			},
			set( value: boolean ) {
				this.$emit( 'input', value );
			},
		},
		selectableAssets(): Array<any> {
			return JSON.parse(
				JSON.stringify(
					this.items.filter( ( item: any ) => {
						return ! this.selectedAssets.some( ( selected: any ) => selected.asset_id == item.asset_id );
					} ),
				),
			);
		},
	},
	watch: {
		value( val: boolean ) {
			if ( val ) {
				this.load();
			} else {
				this.items = [];
			}
		},
	},
	methods: {
		select( val: any ) {
			this.$emit( 'select', val );
			this.show = false;
		},
		close() {
			this.show = false;
		},
		sendError( res: any, err: any, defaultText: any ) {
			this.$emit( 'error', { response: res, error: err, message: defaultText } );
		},
		async getResource( url: string, errorMsg: string, data?: any ) {
			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : undefined );
			} catch ( err ) {
				this.sendError( null, err, errorMsg );

				return;
			}

			if ( 401 == res.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res.status || 'success' !== res.data.result ) {
				this.sendError( res, null, errorMsg );

				return;
			}

			return res.data.data;
		},
		async load() {
			this.loading = true;
			const items = await this.getResource( `${endpoint}`, 'Failed to load the Assets', { collection_id: this.collectionId } );
			items.sort( ( a: any, b: any ) => a.asset_lfa_id - b.asset_lfa_id );
			this.items = items;
			this.loading = false;
		},
	},
} );
