






import { Component, Vue } from 'vue-property-decorator';
import Keywords from '@/components/keywords.vue';

@Component( {
	components: {
		Keywords,
	},
} )
export default class keywordsView extends Vue {}
