<template>
	<v-data-table :headers="headers" :items="dataset" sort-by="role_name" class="elevation-3" dense>
		<template v-slot:top>
			<v-toolbar flat color="white">
				<v-toolbar-title>API Resource - User Roles</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-dialog v-model="dialog" max-width="500px" persistent>
					<template v-slot:activator="{ on }">
						<v-btn small color="success" dark class="ma-2" v-on="on" v-if="rolePermission.add == 1">
							<v-icon>mdi-plus</v-icon>
						</v-btn>
					</template>
					<v-card>
						<v-card-title>
							<span class="headline">{{ formTitle }}</span>
						</v-card-title>

						<v-card-text>
							<v-container>
								<v-row>
									<v-col sm="10" md="10">
										<v-select :items="apiResources" item-value="api_resource_id" item-text="resource_route" label="Resource Route" v-model="editedItem.api_resource_id" dense></v-select>
									</v-col>
								</v-row>
								<v-row>
									<v-col sm="10" md="10">
										<v-select :items="userRoles" item-value="user_role_id" item-text="role_name" label="User Role" v-model="editedItem.user_role_id" dense></v-select>
									</v-col>
								</v-row>
								<v-row>
									<v-col sm="10" md="10">
										<v-checkbox v-model="editedItem.read" label="Read" required></v-checkbox>
									</v-col>
								</v-row>
								<v-row>
									<v-col sm="10" md="10">
										<v-checkbox v-model="editedItem.edit" label="Edit" required></v-checkbox>
									</v-col>
								</v-row>
								<v-row>
									<v-col sm="10" md="10">
										<v-checkbox v-model="editedItem.add" label="Add" required></v-checkbox>
									</v-col>
								</v-row>
								<v-row>
									<v-col sm="10" md="10">
										<v-checkbox v-model="editedItem.delete" label="Delete" required></v-checkbox>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>

						<v-card-actions>
							<v-spacer></v-spacer>
							<div v-if="0 === editedItem.api_resource_user_role_id">
								<v-btn color="info" text @click="save">Save</v-btn>
							</div>
							<div v-if="0 !== editedItem.api_resource_user_role_id">
								<v-btn color="info" text @click="update">Update</v-btn>
							</div>
							<v-btn color="info" text @click="close">Cancel</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogDelete" max-width="300px" persistent>
					<v-card>
						<v-card-title>
							<span class="headline">Deactivate API Resource</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								Are you sure you want to deactivate {{ deleteText }}?
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="error" text @click="deactivate">Deactivate</v-btn>
							<v-btn color="info" text @click="closeDelete">Cancel</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogError" max-width="450px" persistent>
					<v-card>
						<v-card-title>
							<span class="headline">Error</span>
						</v-card-title>
						<v-card-text>
							<v-alert border="bottom" color="warning" elevation="1">
								{{ errorText }}
							</v-alert>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="success" width="100" @click="closeError">OK</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-toolbar>
		</template>
		<template v-slot:item="{ item }">
			<tr @dblclick="editItem( item )">
				<td>{{ item.role_name }}</td>
				<td>{{ item.description }}</td>
				<td>{{ item.resource_route }}</td>
				<td>{{ item.read }}</td>
				<td>{{ item.edit }}</td>
				<td>{{ item.add }}</td>
				<td>{{ item.delete }}</td>
				<td width="80">
					<v-icon style="padding: 20 !important;" @click="editItem( item )" v-if="rolePermission.edit == 1">
						mdi-pencil
					</v-icon>
					<v-icon style="padding: 20 !important;" @click="deleteItem( item )" v-if="rolePermission.delete == 1">
						mdi-delete-circle
					</v-icon>
				</td>
			</tr>
		</template>
	</v-data-table>
</template>

<script>
import api from '../services/api';

const endPoint = '/api-resource-user-roles';
const DefaultItem = {
	api_resource_user_role_id: 0,
	api_resource_id: 0,
	user_role_id: 0,
	read: true,
	edit: false,
	add: false,
	delete: false,
	active: 1,
};

export default {
	name: 'api-resource-user-roles',
	data: () => {
		return {
			headers: [
				{ text: 'User Role', value: 'role_name' },
				{ text: 'Route Description', value: 'description' },
				{ text: 'Resource Route', value: 'resource_route' },
				{ text: 'Read', value: 'read' },
				{ text: 'Edit', value: 'edit' },
				{ text: 'Add', value: 'add' },
				{ text: 'Delete', value: 'delete' },
				{ text: '', value: 'action', sortable: false },
			],
			dataset: [],
			dialog: false,
			dialogDelete: false,
			dialogError: false,
			deleteID: 0,
			deleteText: '',
			errorText: '',
			editedItem: DefaultItem,
			defaultItem: DefaultItem,
			currentItem: DefaultItem,
			userRoles: [],
			apiResources: [],
			user_role_permission: [],
		};
	},
	computed: {
		formTitle() {
			return this.editedItem.api_resource_user_role_id === 0 ? 'New API Resource User Role' : 'Edit API Resource User Role';
		},
	},
	watch: {
		dialog( val ) {
			if ( this.editedItem.api_resource_user_role_id === 0 ) {
				this.editedItem = Object.assign( {}, this.defaultItem );
			}
			if ( ! val ) {
				this.close();
			}
		},
		dialogDelete( val ) {
			if ( ! val ) {
				this.close();
			}
		},
	},
	created() {
		this.load();
		this.loadRoles();
		this.loadApiResources();
	},
	methods: {
		editItem( item ) {
			this.editedItem = Object.assign( {}, item );
			this.currentItem = Object.assign( {}, this.editedItem );
			this.dialog = true;
		},
		deleteItem( item ) {
			this.deleteID = item.api_resource_user_role_id;
			this.deleteText = item.description + ' ' + item.resource_route;
			this.dialogDelete = true;
		},
		close() {
			this.dialog = false;
			setTimeout( () => {
				this.editedItem = Object.assign( {}, this.defaultItem );
			}, 100 );
		},
		closeDelete() {
			this.dialogDelete = false;
			setTimeout( () => {
				this.deleteID = 0;
			}, 100 );
		},
		showError( res, err, defaultText ) {
			this.errorText = defaultText;
			if ( err && err.response && err.response.data && err.response.data.error ) {
				this.errorText += ': ' + err.response.data.error;
			} else if ( res && res.data.error ) {
				this.errorText += ': ' + res.data.error;
			} else if ( err && 'Error: Network Error' == err.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.dialogError = true;
		},
		closeError() {
			this.errorText = '';
			this.dialogError = false;
		},
		update() {
			const accel_api = api( this );
			const updateFields = {};
			let updated = false;
			for ( const field in this.editedItem ) {
				if ( this.currentItem[field] != this.editedItem[field] ) {
					updateFields[field] = this.editedItem[field];
					updated = true;
				}
			}
			if ( ! updated ) {
				this.close();
				return;
			}
			accel_api
				.put( endPoint + '/' + this.editedItem.api_resource_user_role_id, updateFields )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						const roleURL = '/user-roles-permission/' + this.$store.getters.USER.user_role_id;
						accel_api.get( roleURL ).then( ( res ) => {
							if ( res ) {
								this.user_role_permission = res.data.user_role_access;
								this.$store.dispatch( 'SET_USER_ROLE_PERMISSION', this.user_role_permission );
								this.load();
							} else {
								this.load();
								this.showError( res, null, 'Failed to load user role permission' );
							}
						} );
					} else {
						this.showError( res, null, 'Failed to update the API resource user role' );
					}
				} )
				.catch( ( err ) => {
					this.showError( null, err, 'Failed to update the API resource user role' );
				} );
			this.close();
		},
		save() {
			const accel_api = api( this );
			accel_api
				.post( endPoint, this.editedItem )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						//this.load();
						const roleURL = '/user-roles-permission/' + this.$store.getters.USER.user_role_id;
						accel_api.get( roleURL ).then( ( res ) => {
							if ( res ) {
								this.user_role_permission = res.data.user_role_access;
								this.$store.dispatch( 'SET_USER_ROLE_PERMISSION', this.user_role_permission );
								this.load();
							} else {
								this.load();
								this.showError( res, null, 'Failed to load user role permission' );
							}
						} );
					} else {
						this.showError( res, null, 'Failed to add the API resource user role' );
					}
				} )
				.catch( ( err ) => {
					this.showError( null, err, 'Failed to add the API resource user role' );
				} );
			this.close();
		},
		deactivate() {
			const accel_api = api( this );
			accel_api
				.delete( endPoint + '/' + this.deleteID )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.load();
					} else {
						this.showError( res, null, 'Failed to deactivate the API resource user role' );
					}
				} )
				.catch( ( err ) => {
					this.showError( null, err, 'Failed to deactivate the API resource user role' );
				} );
			this.closeDelete();
		},
		async load() {
			const accel_api = api( this );
			accel_api
				.get( endPoint )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.dataset = res.data.data;
					} else if ( 401 == res.status ) {
						window.console.log( '410: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load the API resource user roles' );
					}
				} )
				.catch( ( err ) => {
					this.showError( null, err, 'Failed to load the API resource user roles' );
				} );
		},
		async loadRoles() {
			const accel_api = api( this );
			accel_api
				.get( '/user-roles' )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.userRoles = res.data.data;
					} else if ( 401 == res.status ) {
						window.console.log( '410: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load the user roles' );
					}
				} )
				.catch( ( err ) => {
					this.showError( null, err, 'Failed to load the user roles' );
				} );
		},
		async loadApiResources() {
			const accel_api = api( this );
			accel_api
				.get( '/api-resources' )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.apiResources = res.data.data;
					} else if ( 401 == res.status ) {
						window.console.log( '410: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load the resource routes' );
					}
				} )
				.catch( ( err ) => {
					this.showError( null, err, 'Failed to load the resource routes' );
				} );
		},
	},
};
</script>

<style scoped>
</style>
