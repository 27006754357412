








import { Component, Vue } from 'vue-property-decorator';
import IssuesFocus from '@/components/issues-focus.vue';

@Component( {
	components: {
		IssuesFocus,
	},
} )

export default class IssuesFocusView extends Vue {}

