
import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify, { UserVuetifyPreset } from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use( Vuetify );

const opts: UserVuetifyPreset = {
	icons: {
		iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
	},
	theme: {
		dark: false,
		options: {
			customProperties: true
		},
		themes: {
			dark: {
				primary: '#14234F',
				secondary: '#FFDA00',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#F58020',
				anchor: '#2196F3',
				background: '#292930',
			},
			light: {
				primary: '#14234F',
				secondary: '#FFDA00',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#F58020',
				anchor: '#2196F3',
				background: '#F0F2F5',
			},
		}
	}
}

export default new Vuetify( opts )
