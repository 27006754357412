








import { Component, Vue } from 'vue-property-decorator';
import Profile from '@/components/profile.vue';

@Component( {
	components: {
		Profile,
	},
} )

export default class profileView extends Vue {}

