var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tableFormats,"mobile-breakpoint":0,"disable-pagination":"","hide-default-footer":"","sort-by":"format_name","dense":""},scopedSlots:_vm._u([{key:"item.selected",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"value":!!item.selected},on:{"input":function($event){return _vm.selectedChange( item, $event )}}})]}},{key:"item.asset_format_page_count",fn:function(ref){
var item = ref.item;
return [(item.selected)?_c('v-edit-dialog',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"value":item.asset_format_page_count,"rules":[_vm.nullOrNumberRule, _vm.pageCountRule],"label":"Edit","type":"number","single-line":""},on:{"change":function($event){return _vm.pageCountChange( item, $event )}}})]},proxy:true}],null,true)},[_c('div',[_vm._v(_vm._s(item.asset_format_page_count))])]):_c('div',{staticClass:"disabled-cell"})]}},{key:"item.asset_file_id",fn:function(ref){
var item = ref.item;
return [(item.selected && _vm.assetId)?_c('ac-file-upload',{ref:"formatFileInput",attrs:{"dense":"","flat":"","value":item.asset_file_id,"resource-name":"assets","owner-id":_vm.assetId,"file-attribute-name":'format-file/' + item.format_id,"accept":"application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,text/plain,application/epub+zip"},on:{"input":function($event){return _vm.formatFileChange( item, $event )},"error":function($event){return _vm.sendError( $event.res, $event.err, $event.defaultText )}}}):_c('div',{staticClass:"disabled-cell"})]}},{key:"item.date_published",fn:function(ref){
var item = ref.item;
return [(item.selected)?_c('ac-date-picker',{attrs:{"value":item.date_published,"backgroundColor":"transparent","dense":"","hide-details":"","flat":"","solo":""},on:{"input":function($event){return _vm.datePublishedChange( item, $event )}}}):_c('div',{staticClass:"disabled-cell"})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }