<template>
	<v-data-table
		:headers="headers"
		:items="dataset"
		sort-by="fullname"
		class="elevation-3"
		dense
	>
	<template v-slot:top>
	<v-toolbar flat color="white">
		<v-toolbar-title>Notifications</v-toolbar-title>
		<v-spacer></v-spacer>
		<v-dialog v-model="dialogRead" max-width="300px" persistent>
		<v-card>
			<v-card-title>
			<span class="headline">Mark as read</span>
			</v-card-title>
			<v-card-text>
			<v-container>
				Are you sure you want to mark the message as read?``
			</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="error" text @click="deactivate">Read</v-btn>
				<v-btn color="info" text @click="closeRead">Cancel</v-btn>
			</v-card-actions>
		</v-card>
		</v-dialog>
		<v-dialog v-model="dialogError" max-width="450px" persistent>
		<v-card>
			<v-card-title>
			<span class="headline">Error</span>
			</v-card-title>
			<v-card-text>
				<v-alert
					border="bottom"
					color="warning"
					elevation="1"
				>
				{{ errorText }}
				</v-alert>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="success" width="100" @click="closeError">OK</v-btn>
			</v-card-actions>
		</v-card>
		</v-dialog>
	</v-toolbar>
	</template>
	<template v-slot:item="{ item }">
		<v-icon style="padding: 20 !important;"
			@click="editItem( item )"
		>
			mdi-pencil
		</v-icon>
		<v-icon style="padding: 20 !important;"
			@click="markRead( item )"
		>
			mdi-delete-circle
		</v-icon>
	</template>
	</v-data-table>
</template>

<script>
import api from '@/services/api'

export default {
	name: 'notifications',
	data: () => {
		return {
			headers: [
				{ text: 'Type', value: 'notification_type' },
				{ text: 'From', value: 'fullname' },
				{ text: 'Message', value: 'message' },
				{ text: 'Link', value: 'action_link' },
				{ text: '', value: 'action', sortable: false },
			],
			dataset: [],
			dialog: false,
			dialogRead: false,
			dialogError: false,
			errorText: '',
			readID: 0,
		}
	},
	watch: {
		dialogRead( val ) {
			if ( ! val ) {
				this.closeRead();
			}
		}
	},
	created() {
		this.load();
	},
	methods: {
		deleteItem( item ) {
			this.readID = item.notification_id;
			this.dialogRead = true;
		},
		closeRead() {
			this.dialogRead = false;
		},
		showError( res, err, defaultText ) {
			this.errorText = defaultText;
			if ( err && err.response && err.response.data && err.response.data.error ) {
				this.errorText += ': ' + err.response.data.error;
			} else if ( res && res.data.error ) {
				this.errorText += ': ' + res.data.error;
			} else if ( err && 'Error: Network Error' == err.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.dialogError = true;
		},
		closeError() {
			this.errorText = '';
			this.dialogError = false;
		},
		/*update() {
			const accel_api = api( this );
			const updateFields: any = {};
			let updated = false;
			for ( const field in this.editedItem ) {
				if ( ! this.currentItem.hasOwnProperty( field ) || ! this.editedItem.hasOwnProperty( field ) ) {
					// TODO: remove me
					window.console.log( `Skipping ${field}` );
				}
				if ( this.currentItem[field] != this.editedItem[field] ) {
					updateFields[field] = this.editedItem[field];
					updated = true;
				}
			}
			if ( ! updated ) {
				this.close();
				return;
			}
			accel_api.put( '/notifications/' + this.editedItem.notification_id, updateFields )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						//this.updateCompanies();
						this.load();
					} else {
						this.showError( res, null, 'Failed to update the notification' );
					}
				})
				.catch( err => {
					this.showError( null, err, 'Failed to update the notification' );
				});
			this.close();
		},
		save() {
			const accel_api = api( this );
			accel_api.post( '/notifications', this.editedItem )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.editedItem.notification_id = res.data.insertId;
						this.load();
					} else {
						this.showError( res, null, 'Failed to create the notification' );
					}
				})
				.catch( err => {
					this.showError( null, err, 'Failed to create the notification' );
				});
			this.close();
		},*/
		deactivate() {
			const accel_api = api( this );
			accel_api.delete( '/notifications/' + this.readID )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.load();
					} else {
						this.showError( res, null, 'Failed to deactivate the notification' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to deactivate your notification' );
				} );
			//this.closeDelete();
		},
		async load() {
			const accel_api = api( this );
			accel_api.get( '/notifications' )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.dataset = res.data.data;
					} else if ( 401 == res.status ) {
						window.console.log( '410: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load your notifications' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to load your notifications' );
				} )
		}
	}
}

</script>

<style scoped>
</style>
