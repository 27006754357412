import Vue from 'vue';
import api from '@/services/api';
import pBytes from 'pretty-bytes';

export default Vue.extend( {
	computed:{
		menus: function () {
			const menuList = this.$store.getters.MENU;
			if ( menuList == null ) return [] ;

			const userMenus: any[] = [];
			menuList.forEach( ( x: any ) => {
				if ( x.parent_menu_id == null ) {
					const child: any[] = [];
					menuList.forEach( ( c: any ) => {
						if ( c.parent_menu_id == x.menu_id ) {
							child.push( { ...c } );
						}
					} );
					child.sort( ( a, b ) => a.display_order - b.display_order );

					if ( 0 !== child.length ) {
						userMenus.push( {
							...x,
							child,
						} );
					} else if ( null !== x.route_name ) {
						userMenus.push( {
							...x,
						} );
					}
				}
			} );
			return userMenus.sort( ( a, b ) => a.display_order - b.display_order );
		},
		rolePermission: function () {
			const path = this.$router.currentRoute.path;
			const role = this.$store.getters.UserRolePermission.filter( ( role: any ) => role.resource_route == path )[0];
			return role;
		}
	},
	filters: {
		prettyBytes: function ( num: number ) {
			return pBytes( num );
		},
	},
	methods: {
		formatDate( date: any ) {
			if ( ! date || isNaN( new Date( date ).getTime() ) ) return null;
			// Dates from the date picker are always ISO strings
			const [year, month, day] = date.split( '-' );
			return `${day}/${month}/${year}`;
		},
		sendError( res: any, err: any, defaultText: any ) {
			this.$emit( 'error', { response: res, error: err, message: defaultText } );
		},
		async getApiResource( url: string, errorMsg: string, data?: any ) {
			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : undefined );
			} catch ( err ) {
				this.sendError( null, err, errorMsg );

				return;
			}

			if ( 401 == res.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res.status || 'success' !== res.data.result ) {
				this.sendError( res, null, errorMsg );

				return;
			}

			return res.data.data;
		},
		async getApiDownload( url: string, errorMsg: string, data?: any, options?: any ) {
			const accel_api = api( this );

			let apiOptions: any = undefined;
			if ( data ) {
				apiOptions = {
					params: data,
				};
			}
			if ( options ) {
				apiOptions = {
					...apiOptions,
					...options,
				};
			}

			let res;
			try {
				res = await accel_api.get( url, apiOptions );
			} catch ( err ) {
				this.sendError( null, err, errorMsg );

				return;
			}

			if ( 401 == res.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res.status ) {
				this.sendError( res, null, errorMsg );

				return;
			}

			return res;
		}
	}
} )
