







import { Component, Vue } from 'vue-property-decorator';
import CountryRestrictions from '@/components/country-restrictions/list.vue';

@Component( {
	components: {
		CountryRestrictions,
	},
} )
export default class CountryRestrictionsView extends Vue {}
