







import { Component, Vue } from 'vue-property-decorator';
import QuestionsPage from '@/components/questions-pages/single.vue';

@Component( {
	components: {
		QuestionsPage,
	},
} )
export default class QuestionsPageView extends Vue {}
