






import { Component, Vue } from 'vue-property-decorator';
import Collections from '@/components/collections/list.vue';
@Component( {
	components: {
		Collections,
	},
} )
export default class CollectionsView extends Vue {}
