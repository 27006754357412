








import { Component, Vue } from 'vue-property-decorator';
import ContractType from '@/components/contract-types.vue';

@Component( {
	components: {
		ContractType,
	},
} )

export default class ContractTypeView extends Vue {}

