




import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import { requiredIf, maxValue, minValue, integer } from 'vuelidate/lib/validators';
import api from '@/services/api';

const endpoint = '/assets';

export default Vue.extend( {
	name: 'asset-lfa-id-field',
	mixins: [validationMixin],
	props: {
		isEdit: Boolean,
		solo: Boolean,
		value: Number,
	},
	data: () => {
		return {
			loading: false,
			editing: false,
			editedValue: null as string | null,
			existingError: false,
		};
	},
	validations: {
		editedValue: {
			// eslint-disable-next-line no-unused-vars
			required: requiredIf( function ( this: any ) {
				return this.isEdit;
			} ),
			integer: integer,
			minValue: minValue( 1 ),
			maxValue: maxValue( 2147483647 ),
		},
	},
	computed: {
		lfaIdErrors() {
			const errors: Array<string> = [];
			if ( ! this.$v.editedValue.$dirty ) return errors;
			! this.$v.editedValue.required && errors.push( 'Must be a number greater than 0' );
			! this.$v.editedValue.integer && errors.push( 'Must be a number greater than 0' );
			! this.$v.editedValue.minValue && errors.push( 'Must be a number greater than 0' );
			! this.$v.editedValue.maxValue && errors.push( 'Must be a number lower than 2,147,483,648' );
			this.existingError && errors.push( 'This ID is not available' );
			return errors;
		},
	},
	watch: {
		loading( val: boolean ) {
			this.$emit( 'loading', val );
		},
		editing( val: boolean ) {
			this.$emit( 'editing', val );
		},
		value: {
			handler( val: number | null ) {
				this.editedValue = val !== null && Number.isInteger( val ) ? String( val ) : '';
				this.editing = false;
			},
			immediate: true,
		},
	},
	methods: {
		sendError( res: any, err: any, defaultText: any ) {
			this.$emit( 'error', { response: res, error: err, message: defaultText } );
		},
		async getResource( url: string, errorMsg: string, data?: any ) {
			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : undefined );
			} catch ( err ) {
				this.sendError( null, err, errorMsg );

				return;
			}

			if ( 401 == res.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res.status || 'success' !== res.data.result ) {
				this.sendError( res, null, errorMsg );

				return;
			}

			return res.data.data;
		},
		async save() {
			this.$v.$touch();

			if ( this.$v.$invalid ) {
				return;
			}

			const numValue = Number( this.editedValue ) || null;

			if ( numValue === this.value ) {
				this.editedValue = numValue !== null && Number.isInteger( numValue ) ? String( numValue ) : '';
				this.editing = false;
				return;
			}

			this.existingError = false;
			if ( numValue != null ) {
				this.loading = true;
				const existing = await this.getResource( `${endpoint}`, 'Failed to load the Asset', { asset_lfa_id: [numValue] } );
				this.loading = false;

				if ( existing && existing.length ) {
					this.existingError = true;
					return;
				}
			}

			this.editedValue = numValue !== null && Number.isInteger( numValue ) ? String( numValue ) : '';
			this.$emit( 'input', numValue );
			this.editing = false;
		},
	},
} );
