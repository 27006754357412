




















import Vue, { PropType } from 'vue';
import ReaderCategory from '@/models/reader-category';
import Form from '@/components/reader-categories/form.vue';

export default Vue.extend( {
	name: 'collection-category-create',
	components: {
		'item-form': Form,
	},
	props: {
		currentCategories: {
			type: Array as PropType<Array<ReaderCategory>>,
			required: true,
		},
		value: Object,
	},
	data: () => {
		return {
			nameError: null as string | null,
			dialog: false,
		};
	},
	computed: {},
	watch: {
		dialog( val ) {
			if ( ! val ) {
				( this.$refs.form as any ).reset();
			}
		},
	},
	methods: {
		onSave( val: ReaderCategory ) {
			if ( this.currentCategories.some( ( cat: ReaderCategory ) => cat.reader_category_name == val.reader_category_name ) ) {
				this.nameError = 'A category with this name already exists';
				return;
			}
			this.$emit( 'input', { ...val } );
			this.dialog = false;
		},
		saveForm() {
			( this.$refs.form as any ).save();
		},
	},
} );
