







import { Component, Vue } from 'vue-property-decorator';
import ReaderCategories from '@/components/reader-categories/list.vue';

@Component( {
	components: {
		ReaderCategories,
	},
} )
export default class ReaderCategoriesView extends Vue {}
