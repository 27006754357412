






import { Component, Vue } from 'vue-property-decorator';
import Company from '@/components/companies.vue';

@Component( {
	components: {
		Company,
	},
} )
export default class CompanyView extends Vue {}
