







import { Component, Vue } from 'vue-property-decorator';
import BookProductionModels from '@/components/bookProductionModels/list.vue';

@Component( {
	components: {
		BookProductionModels,
	},
} )
export default class BookProductionModelsView extends Vue {}
