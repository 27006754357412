

























import Vue from 'vue';
import { PropType } from 'vue';
import AssetFormat from '@/models/asset-format';
import Format from '@/models/format';

export default Vue.extend( {
	name: 'asset-formats-table',
	props: {
		assetId: Number,
		formats: Array as PropType<Array<Format>>,
		selectedFormats: Array as PropType<Array<AssetFormat>>,
	},
	data: () => {
		return {
			headers: [
				{ text: 'Formats', value: 'format_name', sortable: false, divider: true, align: 'center' },
				{ text: '', value: 'selected', sortable: false, divider: true, align: 'center' },
				{ text: 'Pages', value: 'asset_format_page_count', sortable: false, divider: true, align: 'center', cellClass: 'dynamic-cell' },
				{ text: 'File', value: 'asset_file_id', sortable: false, divider: true, align: 'center', cellClass: 'dynamic-cell', width: '30%' },
				{ text: 'Date Published', value: 'date_published', sortable: false, align: 'center', cellClass: 'dynamic-cell' },
			],
			nullOrNumberRule: ( v: any ): boolean | string => ! v || ( ! isNaN( parseFloat( v ) ) && isFinite( v ) ) || 'Pages count must be a number',
			pageCountRule: ( v: any ): boolean | string => ! v || ( - 1 < v && 4294967296 > v ) || 'Pages count must be between 0 and 4294967295',
		};
	},
	computed: {
		tableFormats(): Array<AssetFormat & { selected: boolean }> {
			return this.formats.map( ( format: any ) => {
				const selected = this.selectedFormats.find( ( selectedFormat ) => selectedFormat.format_id === format.format_id );

				return {
					...format,
					selected: !! selected,
					asset_format_page_count: selected ? selected.asset_format_page_count : null,
					date_published: selected ? selected.date_published : null,
					asset_file_id: selected ? selected.asset_file_id : null,
				};
			} );
		},
	},
	watch: {},
	methods: {
		validatePageCount( val: any ): boolean {
			return this.nullOrNumberRule( val ) === true && this.pageCountRule( val ) === true;
		},
		selectedChange( item: any, val: any ) {
			// We need to reassign the list of formats if we want this change to be picked up by the Asset page when updating the asset
			const newSelection: Array<AssetFormat> = [];
			this.tableFormats.forEach( ( format ) => {
				const { selected, ...newFormat } = format;
				let newSelected = selected;

				if ( newFormat.format_id === item.format_id ) {
					newSelected = !! val;
				}

				if ( ! newSelected ) {
					// Remove page count for this format
					newFormat.asset_format_page_count = null;
				}

				if ( newSelected ) {
					newSelection.push( newFormat );
				}
			} );

			this.$emit( 'update:selectedFormats', newSelection );
		},
		datePublishedChange( item: any, val: any ) {
			// We need to reassign the list of formats if we want this change to be picked up by the Asset page when updating the asset
			const newSelection: Array<AssetFormat> = [];
			this.tableFormats.forEach( ( format ) => {
				const { selected, ...newFormat } = format;

				if ( newFormat.format_id === item.format_id ) {
					newFormat.date_published = val;
				}

				if ( selected ) {
					newSelection.push( newFormat );
				}
			} );

			this.$emit( 'update:selectedFormats', newSelection );
		},
		formatFileChange( item: any, val: any ) {
			// We need to reassign the list of formats if we want this change to be picked up by the Asset page when updating the asset
			const newSelection: Array<AssetFormat> = [];
			this.tableFormats.forEach( ( format ) => {
				const { selected, ...newFormat } = format;

				if ( newFormat.format_id === item.format_id ) {
					newFormat.asset_file_id = ! val ? null : Number( val );
				}

				if ( selected ) {
					newSelection.push( newFormat );
				}
			} );

			this.$emit( 'update:selectedFormats', newSelection );
		},
		pageCountChange( item: any, val: any ) {
			// We need to reassign the list of formats if we want this change to be picked up by the Asset page when updating the asset
			const newSelection: Array<AssetFormat> = [];
			this.tableFormats.forEach( ( format ) => {
				const { selected, ...newFormat } = format;

				if ( newFormat.format_id === item.format_id && this.validatePageCount( val ) ) {
					newFormat.asset_format_page_count = ! val ? null : Number( val );
				}

				if ( selected ) {
					newSelection.push( newFormat );
				}
			} );

			this.$emit( 'update:selectedFormats', newSelection );
		},
		sendError( res: any, err: any, defaultText: any ) {
			this.$emit( 'error', { response: res, error: err, message: defaultText } );
		},
		async finalize() {
			const tasks = [];

			for ( const ref of this.$refs.formatFileInput as Array<any> ) {
				tasks.push( ref.finalize() );
			}

			if ( ! tasks.length ) {
				return;
			}

			await Promise.allSettled( tasks );

			return;
		},
	},
} );
