
















import Vue from 'vue';
import Form from './form.vue';

export default Vue.extend( {
	name: 'create-subject-authority',
	components: {
		'item-form': Form,
	},
	props: {
		buttonName: String,
		value: Object,
	},
	data: () => {
		return {
			dialog: false,
			errorDialog: false,
			errorText: '',
			loading: false,
		};
	},
	computed: {
		isEdit(): boolean {
			return null != this.value?.subject_authority_id;
		},
		formTitle(): string {
			return this.isEdit ? 'Edit Subject Authority' : 'New Subject Authority';
		},
	},
	watch: {
		dialog( val ) {
			if ( ! val ) {
				this.$emit( 'input', null );
				( this.$refs.form as any ).reset();
			}

			this.errorDialog = false;
		},
		value( val ) {
			if ( val ) {
				this.dialog = true;
			}
		},
	},
	methods: {
		onFormLoading( val: boolean ) {
			this.loading = val;
		},
		onFormSaved( val: any ) {
			this.$emit( 'load', val );
			( this.$refs.form as any ).reset();
			this.dialog = false;
		},
		saveForm() {
			( this.$refs.form as any ).save();
		},
		showError( event: any ) {
			const { response, error, message } = event;

			this.errorText = message;
			if ( error && error.response && error.response.data && error.response.data.error ) {
				this.errorText += ': ' + error.response.data.error;
			} else if ( response && response.data.error ) {
				this.errorText += ': ' + response.data.error;
			} else if ( error && 'Error: Network Error' == error.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.errorDialog = true;
		},
	},
} );
