/**
 * Taken and modified from https://github.com/fannarsh/country-list
 */

import countryCodes from '@/assets/country-codes.json';

// Taken from https://learn.microsoft.com/en-us/javascript/api/%40azure/keyvault-certificates/requireatleastone?view=azure-node-latest
type RequireAtLeastOne<T> = {
	[K in keyof T]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<keyof T, K>>>
}[keyof T]

const nameMap: { [key: string]: string } = {};
const codeMap: { [key: string]: string } = {};
countryCodes.forEach( function ( country ) {
	nameMap[country.name.toLowerCase()] = country.code;
	codeMap[country.code.toLowerCase()] = country.name;
} );

export const getCode = ( name: string ): string | null => {
	return nameMap[name.toLowerCase()];
};

export const getName = ( code: string ): string | null => {
	return codeMap[code.toLowerCase()];
};

export const getData = (): Array<{name: string, code: string}> => {
	return countryCodes;
}

export const getFullName = ( opts: RequireAtLeastOne<{code: string, name: string}> ): string => {
	let { name, code } = opts;

	if ( name == null && code != null ) {
		name = getName( code ) || 'Unknown';
	}

	if ( code == null && name != null ) {
		code = getCode( name ) || '?';
	}

	return `${name} - (${code})`;
}
