








import { Component, Vue } from 'vue-property-decorator';
import Format from '@/components/formats.vue';

@Component( {
	components: {
		Format,
	},
} )

export default class FormatView extends Vue {}

