<template>
	<div>
		<div v-if="requiredOnly">
			<v-row>
				<v-col cols="12">
					<v-text-field v-model="editedItem.author_name_english" :counter="255" label="Author Name (English)" required @blur="!$v.editedItem.author_name_english.$touch()" @input="$v.editedItem.author_name_english.$touch()" :error-messages="authorNameEnErrors" :disabled="loading"></v-text-field>
				</v-col>
			</v-row>
		</div>
		<div v-if="!requiredOnly">
			<v-row>
				<v-col cols="12" sm="4">
					<v-text-field v-model="editedItem.author_name_english" :counter="255" label="Author Name (English) *" required @blur="!$v.editedItem.author_name_english.$touch()" @input="$v.editedItem.author_name_english.$touch()" :error-messages="authorNameEnErrors" :disabled="loading"></v-text-field>
				</v-col>
				<v-col cols="12" sm="4">
					<v-text-field v-model="editedItem.author_name" :counter="255" label="Author Name (Destination Language)" @blur="!$v.editedItem.author_name.$touch()" @input="$v.editedItem.author_name.$touch()" :error-messages="authorNameErrors" :disabled="loading"></v-text-field>
				</v-col>
				<v-col cols="12" sm="4">
					<v-text-field v-model="editedItem.author_first_name" :counter="255" label="Author First Name" @blur="!$v.editedItem.author_first_name.$touch()" @input="$v.editedItem.author_first_name.$touch()" :error-messages="authorFirstNameErrors" :disabled="loading"></v-text-field>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12" sm="4">
					<v-select :items="genders" item-value="gender_id" item-text="gender_type" label="Author Gender" v-model="editedItem.gender_id" :disabled="loading"></v-select>
				</v-col>
				<v-col cols="12" sm="4">
					<v-select :items="users" item-value="user_id" item-text="fullname" label="User" v-model="editedItem.user_id" :disabled="loading"></v-select>
				</v-col>
			</v-row>

			<v-divider class="mt-4 mb-6"></v-divider>

			<v-row>
				<v-col cols="12" sm="4">
					<v-text-field v-model="editedItem.email" :counter="255" label="Author Email" @blur="!$v.editedItem.email.$touch()" @input="$v.editedItem.email.$touch()" :error-messages="authorEmailErrors" :disabled="loading"></v-text-field>
				</v-col>
				<v-col cols="12" sm="4">
					<v-text-field v-model="editedItem.phone" :counter="20" label="Author Phone" @blur="!$v.editedItem.phone.$touch()" @input="$v.editedItem.phone.$touch()" :error-messages="phoneErrors" :disabled="loading"></v-text-field>
				</v-col>
			</v-row>

			<v-divider class="mt-4 mb-6"></v-divider>

			<v-row>
				<v-col cols="12" sm="6">
					<v-text-field v-model="editedItem.address1" :counter="100" label="Author Address (Line 1)" @blur="!$v.editedItem.address1.$touch()" @input="$v.editedItem.address1.$touch()" :error-messages="address1Errors" :disabled="loading"></v-text-field>
				</v-col>
				<v-col cols="12" sm="6">
					<v-text-field v-model="editedItem.address2" :counter="100" label="Author Address (Line 2)" @blur="!$v.editedItem.address2.$touch()" @input="$v.editedItem.address2.$touch()" :error-messages="address2Errors" :disabled="loading"></v-text-field>
				</v-col>
			</v-row>

			<v-row>
				<v-col cols="12" sm="4">
					<v-text-field v-model="editedItem.state" :counter="50" label="Author State" @blur="!$v.editedItem.state.$touch()" @input="$v.editedItem.state.$touch()" :error-messages="stateErrors" :disabled="loading"></v-text-field>
				</v-col>
				<v-col cols="12" sm="4">
					<v-text-field v-model="editedItem.post_code" :counter="10" label="Author Postcode" @blur="!$v.editedItem.post_code.$touch()" @input="$v.editedItem.post_code.$touch()" :error-messages="postCodeErrors" :disabled="loading"></v-text-field>
				</v-col>
				<v-col cols="12" sm="4">
					<v-select :items="countries" item-value="country_id" item-text="country_name" label="Author Country" v-model="editedItem.country_id" :disabled="loading"></v-select>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import api from '@/services/api';
import { validationMixin } from 'vuelidate';
import { email, maxLength, required } from 'vuelidate/lib/validators';

const endpoint = '/authors';
const getDefaultItem = () => {
	return {
		author_id: null,
		author_name: '',
		author_name_english: '',
		gender_id: null,
		email: '',
		phone: '',
		address1: '',
		address2: '',
		state: '',
		post_code: '',
		country_id: null,
		assets: null,
		active: 1,
	};
};

export default {
	mixins: [validationMixin],
	name: 'author-form',
	props: {
		value: Object,
		requiredOnly: {
			type: Boolean,
			default: false,
		},
	},
	data: () => {
		return {
			editedItem: getDefaultItem(),
			loading: false,
			users: [],
			countries: [],
			genders: [],
		};
	},
	created() {
		this.loadFormResources();
	},
	validations: {
		editedItem: {
			author_name_english: { required, maxLength: maxLength( 255 ) },
			email: { email, maxLength: maxLength( 255 ) },
			author_name: { maxLength: maxLength( 255 ) },
			author_first_name: { maxLength: maxLength( 255 ) },
			phone: { maxLength: maxLength( 20 ) },
			address1: { maxLength: maxLength( 100 ) },
			address2: { maxLength: maxLength( 100 ) },
			state: { maxLength: maxLength( 50 ) },
			post_code: { maxLength: maxLength( 10 ) },
		},
	},
	computed: {
		author() {
			return this.value || getDefaultItem();
		},
		authorNameEnErrors() {
			const errors = [];
			if ( ! this.$v.editedItem.author_name_english.$dirty ) return errors;
			! this.$v.editedItem.author_name_english.required && errors.push( 'This field is required.' );
			! this.$v.editedItem.author_name_english.maxLength && errors.push( 'Must be at most 255 characters long' );
			return errors;
		},
		authorNameErrors() {
			const errors = [];
			if ( ! this.$v.editedItem.author_name.$dirty ) return errors;
			! this.$v.editedItem.author_name.maxLength && errors.push( 'Must be at most 255 characters long' );
			return errors;
		},
		authorFirstNameErrors() {
			const errors = [];
			if ( ! this.$v.editedItem.author_first_name.$dirty ) return errors;
			! this.$v.editedItem.author_first_name.maxLength && errors.push( 'Must be at most 255 characters long' );
			return errors;
		},
		authorEmailErrors() {
			const errors = [];
			if ( ! this.$v.editedItem.email.$dirty ) return errors;
			! this.$v.editedItem.email.email && errors.push( 'Must be a valid email address' );
			! this.$v.editedItem.email.maxLength && errors.push( 'Must be at most 255 characters long' );
			return errors;
		},
		phoneErrors() {
			const errors = [];
			if ( ! this.$v.editedItem.phone.$dirty ) return errors;
			! this.$v.editedItem.phone.maxLength && errors.push( 'Must be at most 20 characters long' );
			return errors;
		},
		address1Errors() {
			const errors = [];
			if ( ! this.$v.editedItem.address1.$dirty ) return errors;
			! this.$v.editedItem.address1.maxLength && errors.push( 'Must be at most 100 characters long' );
			return errors;
		},
		address2Errors() {
			const errors = [];
			if ( ! this.$v.editedItem.address2.$dirty ) return errors;
			! this.$v.editedItem.address2.maxLength && errors.push( 'Must be at most 100 characters long' );
			return errors;
		},
		stateErrors() {
			const errors = [];
			if ( ! this.$v.editedItem.state.$dirty ) return errors;
			! this.$v.editedItem.state.maxLength && errors.push( 'Must be at most 50 characters long' );
			return errors;
		},
		postCodeErrors() {
			const errors = [];
			if ( ! this.$v.editedItem.state.$dirty ) return errors;
			! this.$v.editedItem.post_code.maxLength && errors.push( 'Must be at most 10 characters long' );
			return errors;
		},
	},
	watch: {
		author: {
			deep: true,
			handler( val ) {
				this.editedItem = JSON.parse( JSON.stringify( val ) );
			},
		},
		editedItem: {
			deep: true,
			handler( val ) {
				this.$emit( 'changed', JSON.stringify( val ) !== JSON.stringify( this.author ) );
			},
		},
		loading( val ) {
			this.$emit( 'loading', val );
		},
	},
	methods: {
		loadFormResources() {
			this.loadCountries();
			this.loadGenders();
			this.loadUsers();
		},
		reset() {
			this.editedItem = JSON.parse( JSON.stringify( this.author ) );
		},
		sendError( res, err, defaultText ) {
			this.$emit( 'error', { response: res, error: err, message: defaultText } );
		},
		sendInput( val ) {
			this.$emit( 'input', val );
		},
		async save() {
			this.$v.$touch();
			if ( this.$v.$invalid ) {
				return;
			}

			if ( this.author.author_id ) {
				return this.updateAuthor();
			} else {
				return this.createAuthor();
			}
		},
		async createAuthor() {
			this.loading = true;

			const accel_api = api( this );
			let res;
			try {
				res = await accel_api.post( endpoint, this.editedItem );

				if ( 200 !== res.status || 'success' !== res.data.result ) {
					this.loading = false;
					this.sendError( res, null, 'Failed to save the Author' );
					return;
				}
			} catch ( err ) {
				this.sendError( null, err, 'Failed to save the Author' );
				return;
			} finally {
				this.loading = false;
			}

			this.sendInput( res.data.data[0] );
		},
		async updateAuthor() {
			this.loading = true;

			const accel_api = api( this );
			const updatedFields = {};
			let needsUpdate = false;
			for ( const field in this.editedItem ) {
				if ( this.author[field] != this.editedItem[field] ) {
					updatedFields[field] = this.editedItem[field];
					needsUpdate = true;
				}
			}
			if ( ! needsUpdate ) {
				// No update to process, just reset the original values
				this.loading = false;
				this.reset();
			}
			let res;
			try {
				res = await accel_api.put( `${endpoint}/${this.author.author_id}`, updatedFields );

				if ( 200 !== res.status || 'success' !== res.data.result ) {
					this.loading = false;
					this.sendError( res, null, 'Failed to save the Author' );
					return;
				}
			} catch ( err ) {
				this.sendError( null, err, 'Failed to save the Author' );
				return;
			} finally {
				this.loading = false;
			}

			this.sendInput( { ...this.editedItem } );
		},
		async getResource( url, errorMsg, data ) {
			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : null );
			} catch ( err ) {
				this.sendError( null, err, errorMsg );
			}

			if ( 401 == res.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res.status || 'success' !== res.data.result ) {
				this.sendError( res, null, errorMsg );

				return;
			}

			return res.data.data;
		},
		async loadCountries() {
			this.countries = await this.getResource( '/countries', 'Failed to load the Countries' );
		},
		async loadGenders() {
			this.genders = await this.getResource( '/genders', 'Failed to load the Genders' );
		},
		async loadUsers() {
			this.users = await this.getResource( '/users', 'Failed to load the Users' );
		},
	},
};
</script>

<style scoped>
</style>
