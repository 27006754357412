<template>
	<div>
		<ac-list-page :headers="headers" :show-search.sync="showSearch" :items="dataset" :items-per-page.sync="itemsPerPage" title="Book Production Models" sort-by="book_production_model_id" @update="editItem($event)" @delete="deleteItem($event)">
			<template v-slot:buttons>
				<v-row class="my-2 mx-0 pa-0">
					<v-col cols="12" md="12" class="py-2">
						<create-book-production-model :editItem.sync="editItemBookProductionModel" @bookProductionModelLoad="load" v-if="rolePermission.add == 1"></create-book-production-model>
					</v-col>
				</v-row>
			</template>

		</ac-list-page>
		<ac-dialog-deactivate v-model="dialogDelete" :itemId="deleteID" :itemType="'book Production Models'" :itemName="deleteText" @deactivate="deactivate" />
		<ac-dialog-error v-model="errorDialog" :text="errorText" />
	</div>
</template>

<script>
import api from '../../services/api';
import CreateBookProductionModel from '@/components/bookProductionModels/createOrUpdate.vue';
const endPoint = '/book-production-models';

export default {
	name: 'book-production-models',
	components: {
		'create-book-production-model': CreateBookProductionModel,
	},
	data: () => {
		return {
			headers: [{ text: 'Book Production Model Name', value: 'book_production_model_name', align: 'left', divider: true }],
			dataset: [],
			dialog: false,
			dialogDelete: false,
			errorDialog: false,
			deleteID: 0,
			deleteText: '',
			errorText: '',
			showSearch: false,
			editItemBookProductionModel: {},
		};
	},
	watch: {
		deleteDialog( val ) {
			if ( ! val ) {
				this.deleteID = 0;
			}
		},
	},
	computed: {
		itemsPerPage: {
			get() {
				return this.$store.getters.itemsPerList( 'bookProductionModels' );
			},
			set( val ) {
				this.$store.dispatch( 'SET_ITEMS_PER_LIST', {
					listName: 'bookProductionModels',
					number: val,
				} );
			},
		},
	},
	created() {
		this.load();
	},
	methods: {
		editItem( item ) {
			this.editItemBookProductionModel = item;
		},
		deleteItem( item ) {
			this.deleteID = item.book_production_model_id;
			this.dialogDelete = true;
		},
		showError( res, err, defaultText ) {
			this.errorText = defaultText + ': ';
			if ( err && err.response && err.response.data && err.response.data.error ) {
				this.errorText += err.response.data.error;
			} else if ( res && res.data.error ) {
				this.errorText += res.data.error;
			} else if ( err && 'Error: Network Error' == err.toString() ) {
				this.errorText += ' Unable to connect to the backend API';
			} else {
				this.errorText += ' Unexpected error';
			}
			this.errorDialog = true;
		},
		deactivate() {
			const accel_api = api( this );
			accel_api
				.delete( endPoint + '/' + this.deleteID )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.load();
					} else {
						this.showError( res, null, 'Failed to deactivate the Book Production Model' );
					}
				} )
				.catch( ( err ) => {
					this.showError( null, err, 'Failed to deactivate the Book Production Model' );
				} );
			this.deleteID = 0;
		},
		async load() {
			const accel_api = api( this );
			accel_api
				.get( endPoint )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.dataset = res.data.data;
					} else if ( 401 == res.status ) {
						window.console.log( '401: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load the  Book Production Model List' );
					}
				} )
				.catch( ( err ) => {
					this.showError( null, err, 'Failed to load the  Book Production Model' );
				} );
		},
	},
};
</script>

<style scoped></style>
