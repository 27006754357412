







































import Vue from 'vue';
import api from '@/services/api';
import Contract from '@/models/contract';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

const endpoint = '/contracts';
const getDefaultItem = (): Contract => {
	return {
		contract_type_id: null,
		contract_file_id: null,
		contract_description: '',
		start_date: null,
		end_date: null,
	};
};

export default Vue.extend( {
	mixins: [validationMixin],
	name: 'contract-form',
	props: {
		value: Object,
		fileOnly: {
			type: Boolean,
			default: false,
		},
		requiredOnly: {
			type: Boolean,
			default: false,
		},
	},
	data: () => {
		return {
			editedItem: getDefaultItem(),
			loading: false,
			contractTypes: [],
		};
	},
	created() {
		this.loadFormResources();
	},
	validations: {
		editedItem: {
			contract_type_id: { required },
			start_date: {
				maxValue( val, { end_date } ) {
					return null == val || null == end_date || new Date( end_date ) >= new Date( val );
				},
			},
			end_date: {
				minValue( val, { start_date } ) {
					return null == val || null == start_date || new Date( start_date ) <= new Date( val );
				},
			},
		},
	},
	computed: {
		contract(): Contract {
			return ( this.value as Contract ) || getDefaultItem();
		},
		contractTypeErrors(): string[] {
			const errors: string[] = [];
			if ( ! this.$v.editedItem.contract_type_id?.$dirty ) return errors;
			! this.$v.editedItem.contract_type_id.required && errors.push( 'This field is required.' );
			return errors;
		},
		startDateErrors(): string[] {
			const errors: string[] = [];
			if ( ! this.$v.editedItem.start_date?.$dirty ) return errors;
			! this.$v.editedItem.start_date.maxValue && errors.push( 'The start date can\'t be after the end date.' );
			return errors;
		},
		endDateErrors(): string[] {
			const errors: string[] = [];
			if ( ! this.$v.editedItem.end_date?.$dirty ) return errors;
			! this.$v.editedItem.end_date.minValue && errors.push( 'The start date can\'t be after the end date.' );
			return errors;
		},
	},
	watch: {
		contract: {
			deep: true,
			immediate: true,
			handler( val ) {
				this.editedItem = JSON.parse( JSON.stringify( val ) );
			},
		},
		editedItem: {
			deep: true,
			handler( val ) {
				this.$emit( 'changed', JSON.stringify( val ) !== JSON.stringify( this.contract ) );
			},
		},
		loading( val ) {
			this.$emit( 'loading', val );
		},
	},
	methods: {
		loadFormResources() {
			this.loadContractTypes();
		},
		reset() {
			( this.$refs.fileInput as any ).remove();
			this.editedItem = JSON.parse( JSON.stringify( this.contract ) );
			this.$v.$reset();
		},
		sendError( res: any, err: any, defaultText: any ) {
			this.$emit( 'error', { response: res, error: err, message: defaultText } );
		},
		sendInput( val: any ) {
			this.$emit( 'input', val );
		},
		async save() {
			this.$v.$touch();
			if ( this.$v.$invalid ) {
				return;
			}

			if ( this.contract.contract_id ) {
				return this.updateContract();
			} else {
				return this.createContract();
			}
		},
		async createContract() {
			this.loading = true;

			const accel_api = api( this );
			let res;
			try {
				res = await accel_api.post( endpoint, this.editedItem );

				if ( 200 !== res.status || 'success' !== res.data.result ) {
					this.loading = false;
					this.sendError( res, null, 'Failed to save the Contract' );
					return;
				}
			} catch ( err ) {
				this.sendError( null, err, 'Failed to save the Contract' );
				return;
			} finally {
				this.loading = false;
			}

			const contractFileId = await ( this.$refs.fileInput as any ).upload( res.data.insertId );

			if ( contractFileId ) {
				this.editedItem.contract_id = res.data.insertId;
				this.editedItem.contract_file_id = contractFileId;
				await this.updateContract();
			} else {
				this.sendInput( res.data.data?.length ? res.data.data[0] : [] );
			}
		},
		async updateContract() {
			this.loading = true;

			const accel_api = api( this );
			const updatedFields: any = {};
			let needsUpdate = false;
			let field: keyof Contract;
			for ( field in this.editedItem ) {
				if ( this.contract[field] != this.editedItem[field] ) {
					updatedFields[field] = this.editedItem[field];
					needsUpdate = true;
				}
			}
			if ( ! needsUpdate ) {
				// No update to process, just reset the original values
				this.loading = false;
				this.reset();
				this.sendInput( { ...this.editedItem } );
				return;
			}
			let res;
			try {
				res = await accel_api.put( `${endpoint}/${this.editedItem.contract_id}`, updatedFields );

				if ( 200 !== res.status || 'success' !== res.data.result ) {
					this.loading = false;
					this.sendError( res, null, 'Failed to save the Contract' );
					return;
				}
			} catch ( err ) {
				this.sendError( null, err, 'Failed to save the Contract' );
				return;
			} finally {
				await ( this.$refs.fileInput as any ).finalize();
				this.loading = false;
			}

			this.sendInput( { ...this.editedItem } );
		},
		async getResource( url: string, errorMsg: string, data?: any ) {
			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : undefined );
			} catch ( err ) {
				this.sendError( null, err, errorMsg );

				return;
			}

			if ( 401 == res.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res.status || 'success' !== res.data.result ) {
				this.sendError( res, null, errorMsg );

				return;
			}

			return res.data.data;
		},
		async loadContractTypes() {
			this.contractTypes = await this.getResource( '/contract-types', 'Failed to load the Contract Types' );
		},
	},
} );
