










import Vue from 'vue';

import api from '@/services/api';
import Contract from '@/models/contract';

const endpoint = '/contracts';

export default Vue.extend( {
	name: 'asset-contract-selection',
	props: {
		value: Number,
	},
	data: () => {
		return {
			fileInfo: null as Contract | null,
			contracts: [] as Array<Partial<Contract>>,
			loadingFileInfo: false,
		};
	},
	created() {
		this.loadContracts();
	},
	computed: {
		contractSelectItems() {
			return this.contracts.map( ( c ) => {
				const textParts: string[] = [];
				if ( c.contract_type_name ) {
					textParts.push( c.contract_type_name );
				}
				if ( c.contract_description ) {
					textParts.push( c.contract_description );
				}

				const text = textParts.join( ' - ' );
				return {
					text,
					value: c.contract_id || null,
				};
			} );
		},
	},
	watch: {
		value() {
			this.loadFileInfo();
		},
	},
	methods: {
		onChange( val: any ) {
			this.$emit( 'input', val );
		},
		sendError( res: any, err: any, defaultText: any ) {
			this.$emit( 'error', { response: res, error: err, message: defaultText } );
		},
		async getResource( url: string, errorMsg: string, data?: any ) {
			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : undefined );
			} catch ( err ) {
				this.sendError( null, err, errorMsg );

				return;
			}

			if ( 401 == res.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res.status || 'success' !== res.data.result ) {
				this.sendError( res, null, errorMsg );

				return;
			}

			return res.data.data;
		},
		async loadContracts() {
			this.contracts = await this.getResource( endpoint, 'Failed to load the Contracts' );

			if ( this.contracts.length ) {
				this.contracts.unshift( {} );
			}
		},
		async loadFileInfo() {
			this.fileInfo = null;

			if ( ! this.value ) {
				return;
			}

			this.loadingFileInfo = true;
			this.fileInfo = await this.getResource( `${endpoint}/${this.value}/file`, 'Failed to load the Contract File' );
			this.loadingFileInfo = false;
		},
		async download() {
			if ( ! this.value ) {
				return;
			}

			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( `${endpoint}/${this.value}/file/download` );

				if ( 200 !== res.status ) {
					this.sendError( res, null, 'Failed to download the file' );
				}

				const url = res.data.presigned_url
				const link = document.createElement( 'a' );
				link.href = url;
				link.setAttribute( 'download', res.data.file_name );
				document.body.appendChild( link );
				link.click();
				link.remove();
			} catch ( err ) {
				this.sendError( null, err, 'Failed to download the file' );
			}
		},
	},
} );
