
import Vue from 'vue'
import App from './app.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import moment from 'moment'
import Vuelidate from 'vuelidate'
import globalMixin from './mixins/globalMixin'

router.beforeEach( ( to, from, next ) => {
	//window.console.log( 'from: ', from.fullPath, ' to: ', to.fullPath );
	//window.console.log( store );
	if ( '/login' !== to.path && ! store.getters.USER ) {
		window.console.log( 'Not authenticated' );
		next( {
			path: '/login',
			query: { redirect: to.fullPath }
		} );
	} else {
		next();
	}
} );

Vue.use( Vuelidate );
Vue.mixin( globalMixin );
Vue.config.productionTip = false;

Vue.filter( 'formatDate', function ( value: string ) {
	if ( value ) {
		return moment( String( value ) ).format( 'YYYY/MM/DD hh:mm' )
	}
} );

import '@/components/global';

new Vue( {
	vuetify,
	router,
	store,
	render: h => h( App )
} ).$mount( '#app' )
