
import Vue                from 'vue';
import AcAssetsList       from '@/components/global/ac-assets-list.vue';
import AcLinksList        from '@/components/global/ac-links-list.vue';
import AcDatePicker       from '@/components/global/ac-date-picker.vue';
import AcDialogAlert      from '@/components/global/ac-dialog-alert.vue';
import AcDialogConfirm    from '@/components/global/ac-dialog-confirm.vue';
import AcDialogDeactivate from '@/components/global/ac-dialog-deactivate.vue';
import AcDialogEdit       from '@/components/global/ac-dialog-edit.vue';
import AcDialogError      from '@/components/global/ac-dialog-error.vue';
import AcFileCard         from '@/components/global/ac-file-card.vue';
import AcFileSelect       from '@/components/global/ac-file-select.vue';
import AcFileUpload       from '@/components/global/ac-file-upload.vue';
import AcListPage         from '@/components/global/ac-list-page.vue';
import AcSearchCard       from '@/components/global/ac-search-card.vue';
import AcSearchCardItem   from '@/components/global/ac-search-card-item.vue';
import AcSelect           from '@/components/global/ac-select.vue';
import AcSnackbar         from '@/components/global/ac-snackbar.vue';
import AcTableCellAssets  from '@/components/global/ac-table-cell-assets.vue';
import AcTableCellLinks   from '@/components/global/ac-table-cell-links.vue';
import AcTriStateCheckbox from '@/components/global/ac-tri-state-checkbox.vue';

Vue.component( 'ac-assets-list', AcAssetsList );
Vue.component( 'ac-links-list', AcLinksList );
Vue.component( 'ac-date-picker', AcDatePicker );
Vue.component( 'ac-dialog-alert', AcDialogAlert );
Vue.component( 'ac-dialog-confirm', AcDialogConfirm );
Vue.component( 'ac-dialog-deactivate', AcDialogDeactivate );
Vue.component( 'ac-dialog-edit', AcDialogEdit );
Vue.component( 'ac-dialog-error', AcDialogError );
Vue.component( 'ac-file-card', AcFileCard );
Vue.component( 'ac-file-select', AcFileSelect );
Vue.component( 'ac-file-upload', AcFileUpload );
Vue.component( 'ac-list-page', AcListPage );
Vue.component( 'ac-search-card', AcSearchCard );
Vue.component( 'ac-search-card-item', AcSearchCardItem );
Vue.component( 'ac-select', AcSelect );
Vue.component( 'ac-snackbar', AcSnackbar );
Vue.component( 'ac-table-cell-assets', AcTableCellAssets );
Vue.component( 'ac-table-cell-links', AcTableCellLinks );
Vue.component( 'ac-tri-state-checkbox', AcTriStateCheckbox );
