








import { Component, Vue } from 'vue-property-decorator';
import ReadingFocus from '@/components/reading-focus.vue';

@Component( {
	components: {
		ReadingFocus,
	},
} )

export default class ReadingFocusView extends Vue {}

