








import { Component, Vue } from 'vue-property-decorator';
import ReadingLevels from '@/components/reading-levels.vue';

@Component( {
	components: {
		ReadingLevels,
	},
} )

export default class ReadingLevelsView extends Vue {}

