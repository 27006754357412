import { render, staticRenderFns } from "./asset-keywords-selection.vue?vue&type=template&id=c0ff8e42&scoped=true&"
import script from "./asset-keywords-selection.vue?vue&type=script&lang=ts&"
export * from "./asset-keywords-selection.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0ff8e42",
  null
  
)

export default component.exports