




import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import { hyphenateIsbn, isValidIsbn, removeHyphens } from '@/helpers/isbn-helpers';
import { helpers } from 'vuelidate/lib/validators';

const validateIsbn = ( value: any ) => {
	return ! helpers.req( value ) || isValidIsbn( value );
};

export default Vue.extend( {
	name: 'asset-isbn-field',
	mixins: [validationMixin],
	props: {
		label: {
			type: String,
			default: 'ISBN Number',
		},
		value: String,
	},
	data: () => {
		return {};
	},
	validations: {
		displayValue: {
			validateIsbn,
		},
	},
	computed: {
		isbnNumberErrors() {
			const errors = [];
			if ( this.$v.displayValue.$dirty ) {
				! this.$v.displayValue.validateIsbn && errors.push( 'Must be a valid ISBN number' );
			}

			return errors;
		},
		isValid() {
			return this.value === '' || this.value == null || isValidIsbn( this.value );
		},
		displayValue() {
			if ( this.value == null ) {
				return '';
			}
			return hyphenateIsbn( this.value );
		},
	},
	watch: {},
	created() {},
	methods: {
		onInput( event: string | null ) {
			// Just re-emit the event
			this.$emit( 'input', event != null ? removeHyphens( event ) : null );
			this.$v.displayValue.$touch();
		},
	},
} );
