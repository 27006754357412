
































import Vue from 'vue';
import api from '../../services/api';
import QuestionsPage from '@/models/questions-page';
const endPoint = '/questions-pages';

export default Vue.extend( {
	name: 'questions-pages',
	components: {},
	data: () => {
		return {
			headers: [
				{ text: 'Name', value: 'name' },
				{ text: 'Description', value: 'description' },
			],
			dataset: [],
			dialogDelete: false,
			dialogError: false,
			deleteID: 0,
			deleteText: '',
			errorText: '',
		};
	},
	computed: {
		itemsPerPage: {
			get() {
				return this.$store.getters.itemsPerList( 'questionsPages' );
			},
			set( val ) {
				this.$store.dispatch( 'SET_ITEMS_PER_LIST', {
					listName: 'questionsPages',
					number: val,
				} );
			},
		},
	},
	watch: {
		dialogDelete( val: any ) {
			if ( ! val ) {
				this.deleteID = 0;
			}
		},
	},
	created() {
		this.load();
	},
	methods: {
		editItem( item: QuestionsPage ) {
			this.$router.push( `/questions-pages/${item.questions_page_id}` );
		},
		createItem() {
			this.$router.push( '/questions-pages/new' );
		},
		deleteItem( item: QuestionsPage ) {
			this.deleteID = item.questions_page_id!;
			this.deleteText = item.name;
			this.dialogDelete = true;
		},
		showError( res: any, err: any, defaultText: string ) {
			this.errorText = defaultText;
			if ( err && err.response && err.response.data && err.response.data.error ) {
				this.errorText += ': ' + err.response.data.error;
			} else if ( res && res.data.error ) {
				this.errorText += ': ' + res.data.error;
			} else if ( err && 'Error: Network Error' == err.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.dialogError = true;
		},
		deactivate( id: number ) {
			const accel_api = api( this );
			accel_api
				.delete( endPoint + '/' + id )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.load();
					} else {
						this.showError( res, null, 'Failed to deactivate the Questions Page' );
					}
				} )
				.catch( ( err ) => {
					this.showError( null, err, 'Failed to deactivate the Questions Page' );
				} );
			this.deleteID = 0;
		},
		async load() {
			const accel_api = api( this );
			accel_api
				.get( endPoint )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.dataset = res.data.data;
					} else if ( 401 == res.status ) {
						window.console.log( '401: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load the Questions Pages' );
					}
				} )
				.catch( ( err ) => {
					this.showError( null, err, 'Failed to load the Questions Pages' );
				} );
		},
	},
} );
