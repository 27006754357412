<template>
	<v-list-group :sub-group="subGroup" :no-action="noAction" :value="active" @input="active = $event">
		<template v-slot:activator>
			<v-list-item-title>{{ title }}</v-list-item-title>
		</template>

		<v-list-item class="pl-12" dense>
			<v-list-item-content>
				<slot></slot>
			</v-list-item-content>
		</v-list-item>
	</v-list-group>
</template>

<script>
export default {
	name: 'ac-search-card-item',
	props: {
		title: String,
		value: Boolean,
		subGroup: {
			type: Boolean,
			default: true,
		},
		noAction: {
			type: Boolean,
			default: true,
		},
	},
	data: () => {
		return {};
	},
	computed: {
		active: {
			get() {
				return this.value;
			},
			set( val ) {
				this.$emit( 'input', val );
			},
		},
	},
	watch: {},
	created() {},
	methods: {},
};
</script>

<style scoped>
</style>
