<template>
	<v-card>
		<v-card-title>
			<v-icon @click="$router.go(-1)" class="pr-2">mdi-arrow-left</v-icon>
			<span class="text-h5">{{ formTitle }}</span>
		</v-card-title>
		<v-card-subtitle v-if="batchNew">
			<v-row class="pa-4">
				<v-col cols="12" class="d-flex flex-row align-center">
					<asset-lfa-ids-list v-model="newLfaIds" @loading="isCheckingLfaId = $event" @editing="isEditingLfaId = $event" />
				</v-col>
			</v-row>
		</v-card-subtitle>
		<v-card-subtitle v-if="batchEdit">
			<v-row align="center">
				<v-col cols="12" class="d-flex flex-row align-center">
					<span class="mr-4 text-h6">Editing {{ selectedAssetIds.length === 1 ? 'record' : 'records' }}: </span>
					<v-text-field single-line type="number" class="short-input" :disabled="true" v-model="selectedAssetIds.length"> </v-text-field>
				</v-col>
			</v-row>
		</v-card-subtitle>
		<v-card flat>
			<v-tabs v-model="tab" align-with-title>
				<v-tabs-slider></v-tabs-slider>
				<v-tab>
					Record Information
				</v-tab>
				<v-tab v-if="! (batchNew || batchEdit)">
					Book Information
				</v-tab>
				<v-tab>
					In-Flight Updates
				</v-tab>
			</v-tabs>
			<v-overlay :value="overlay">
				<div class="text-center">
					<v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
				</div>
			</v-overlay>
			<v-tabs-items v-model="tab">
				<v-form>
					<v-tab-item eager>
						<v-container>
							<v-row class="mt-2" v-if="!batchNew && !batchEdit">
								<v-col cols="auto" class="text-h6 pt-5">
									LFA ID
								</v-col>
								<v-col cols="auto">
									<asset-lfa-id-field v-model="editedItem.asset_lfa_id" :is-edit="item.asset_id != null" @loading="isCheckingLfaId = $event" @editing="isEditingLfaId = $event" solo />
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="4">
									<v-select :items="programs" item-value="program_id" item-text="program_name" label="Program" v-model="editedItem.program_id" dense></v-select>
								</v-col>
								<v-col cols="12" sm="4">
									<v-select :items="funders" item-value="funder_id" item-text="funder_name" label="Funder" v-model="editedItem.funder_id" dense></v-select>
								</v-col>
								<v-col cols="12" sm="4">
									<v-select :items="languages" item-value="language_id" item-text="language_name" label="Language" v-model="editedItem.language_id" dense></v-select>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="12">
									<asset-formats-table ref="formatsInput" :formats="formats" :selected-formats.sync="editedItem.formats" :asset-id="editedItem.asset_id" @error="showError( $event.response, $event.error, $event.message )" />
								</v-col>
							</v-row>
							<v-row v-if="editedItem.asset_id" class="my-2">
								<v-col cols="12" sm="6">
									<asset-file-selection ref="coverInput" title="Cover File" v-model="editedItem.cover_id" file-name="cover" :asset-id="editedItem.asset_id" accept="image/*" />
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="4" class="pa-0 ma-0">
									<v-switch color="secondary" class="pa-1 ma-0 v-input--reverse" label="Listed On LFA Commerce Site" v-model="editedItem.is_commerce_site"></v-switch>
								</v-col>
							</v-row>
							<v-row v-if="! ( batchNew || batchEdit )">
								<v-col cols="12" sm="6">
									<v-textarea outlined name="input-7-4" label="Notes" :counter="255" v-model="editedItem.note" @blur="!$v.editedItem.note.$touch()" @input="$v.editedItem.note.$touch()" :error-messages="notesErrors"></v-textarea>
								</v-col>
							</v-row>
							<v-row v-if="! ( batchNew || batchEdit )">
								<v-col cols="12" sm="6">
									<div class="text-subtitle-1">Derivatives of this record:</div>
									<ul class="derivatives-list">
										<li v-for="derivative in item.derivatives" :key="derivative.asset_id">
											<router-link :to="`/assets/${derivative.asset_id}`">
												{{ `LFA ID ${derivative.asset_lfa_id}` }}
											</router-link>
										</li>
									</ul>
									<span v-if="!item.derivatives || 0 === item.derivatives.length" class="text-body-2">None</span>
								</v-col>
							</v-row>
						</v-container>
					</v-tab-item>

					<v-tab-item v-if="! ( batchNew || batchEdit )" eager>
						<v-container>
							<v-row align="center" class="mt-2">
								<v-col cols="6" sm="3" class="pa-0 ma-0">
									<v-switch color="secondary" class="pa-1 v-input--reverse" v-model="showParentSelection">
										<template v-slot:label>
											<span class="switch-reverse-label">Derivative</span>
										</template>
									</v-switch>
								</v-col>
								<v-col cols="6" sm="3">
									<v-select v-show="showParentSelection" :items="selectableParentAssets" item-value="asset_id" :item-text="(item) => `LFA ID ${item.asset_lfa_id} - ${item.title_english}`" label="Derivative of" v-model="editedItem.parent_asset_id" :hide-details="true" dense></v-select>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="6">
									<v-text-field v-model="editedItem.title_english" label="Title (English)" :counter="255" @blur="!$v.editedItem.title_english.$touch()" @input="$v.editedItem.title_english.$touch()" :error-messages="titleEnglishErrors"></v-text-field>
								</v-col>
								<v-col cols="12" sm="6">
									<v-text-field v-model="editedItem.title" label="Title (Original)" :counter="255" @blur="!$v.editedItem.title.$touch()" @input="$v.editedItem.title.$touch()" :error-messages="titleErrors"></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="6">
									<asset-isbn-field ref="isbnField" v-model="editedItem.isbn_number"></asset-isbn-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="6">
									<v-textarea outlined name="input-7-4" label="Description (English)" v-model="editedItem.description_english" :counter="1000" @blur="!$v.editedItem.description_english.$touch()" @input="$v.editedItem.description_english.$touch()" :error-messages="descriptionEnErrors"></v-textarea>
								</v-col>
								<v-col cols="12" sm="6">
									<v-textarea outlined name="input-7-4" label="Description (Original)" v-model="editedItem.description" :counter="1000" @blur="!$v.editedItem.description.$touch()" @input="$v.editedItem.description.$touch()" :error-messages="descriptionErrors"></v-textarea>
								</v-col>
							</v-row>
							<asset-keywords-selection :keywords="keywords" :language-id="editedItem.language_id" v-model="editedItem.keywords" />
							<v-divider class="mt-6 mb-8"></v-divider>
							<v-row>
								<v-col cols="12" sm="4">
									<v-select :items="bookProductionModels" item-value="book_production_model_id" item-text="book_production_model_name" label="Book Production Models" v-model="editedItem.book_production_model_id" dense></v-select>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="4">
									<v-select :items="acquisitionTypes" item-value="acquisition_type_id" item-text="acquisition_type_name" label="Acquisition Type" v-model="editedItem.acquisition_type_id" dense></v-select>
								</v-col>
							</v-row>
							<v-divider class="mt-6 mb-8"></v-divider>
							<v-row align="center">
								<v-col cols="12" md="4">
									<asset-contract-selection v-model="editedItem.contract_id" />
								</v-col>
							</v-row>
							<v-divider class="mt-6 mb-8"></v-divider>
							<v-row>
								<v-col cols="12" sm="6">
									<v-select :items="authors" :item-text="(item) => getPersonName(item, 'author_name_english', 'author_name')" item-value="author_id" label="Authors" v-model="editedItem.authors" dense multiple return-object>
										<template v-slot:prepend-item>
											<div class="append">
												<create-author buttonName="Create New Author" @authorLoad="getAuthor"></create-author>
											</div>
										</template>
									</v-select>
								</v-col>
								<v-col cols="12" sm="6">
									<v-select :items="illustrators" :item-text="(item) => getPersonName(item, 'illustrator_name_english', 'illustrator_name')" item-value="illustrator_id" label="Illustrators" v-model="editedItem.illustrators" dense multiple return-object>
										<template v-slot:prepend-item>
											<div class="append">
												<create-illustrator buttonName="Create New Illustrator" @illustratorLoad="getIllustrator"></create-illustrator>
											</div>
										</template>
									</v-select>
								</v-col>
							</v-row>
							<v-divider class="mt-6 mb-8"></v-divider>
							<v-row v-if="editedItem.asset_id">
								<v-col cols="12" sm="6">
									<asset-file-selection ref="approvedManuscriptInput" title="Approved Manuscript/Storyboard" v-model="editedItem.approved_manuscript_id" file-name="approved-manuscript" :asset-id="editedItem.asset_id" accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,text/plain,image/*" />
								</v-col>
							</v-row>
							<v-divider class="mt-6 mb-8"></v-divider>
							<v-row>
								<v-col class="py-0">
									<v-radio-group class="my-0 py-0" :value="!!editedItem.is_fiction" @change="isFictionChanged">
										<template v-slot:label>
											<span class="text-subtitle-1">Text Type</span>
										</template>
										<v-radio label="Fiction" id="Fiction" name="is_fiction" :value="true"></v-radio>
										<v-radio label="Non-Fiction" id="Non-Fiction" name="is_fiction" :value="false"></v-radio>
									</v-radio-group>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="4">
									<v-select :items="readingLevels" item-value="reading_level_id" item-text="reading_level_name" label="Reading Level" v-model="editedItem.reading_level_id" dense></v-select>
								</v-col>
								<v-col cols="12" sm="4">
									<v-select :items="genderRepresentations" item-value="gender_representation_id" item-text="gender_representation_description" label="Gender Representation" v-model="editedItem.gender_representation_id" dense></v-select>
								</v-col>
								<v-col cols="12" sm="4">
									<v-select :items="issuesFocuses" item-value="issues_focus_id" item-text="issues_focus_description" label="Issues Focus" v-model="editedItem.issues_focus_id" dense></v-select>
								</v-col>
								<v-col cols="12" sm="4">
									<v-select :items="readingFocuses" item-value="reading_focus_id" item-text="reading_focus_description" label="Reading Focus" v-model="editedItem.reading_focus_id" dense></v-select>
								</v-col>
								<v-col cols="12" sm="4">
									<v-select :items="contentPillars" item-value="content_pillar_id" item-text="content_pillar_name" label="Content Pillars" v-model="editedItem.content_pillar_id" dense></v-select>
								</v-col>
								<v-col cols="12" sm="4">
									<ac-select :items="paReadingFocuses" item-text="pa_reading_focus_name" label="Program Aligned Reading Focus" v-model="editedItem.pa_reading_focuses" :display-limit="3" dense multiple autocomplete return-object />
								</v-col>
								<v-col cols="12" sm="4">
									<ac-select :items="questionsPages" item-value="questions_page_id" item-text="name" label="Questions Page" v-model="editedItem.questions_page_id" :display-limit="3" dense autocomplete />
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<ac-select :items="subjects" :item-text="(item) => item.subject_term + ': ' + item.subject_label" label="Subjects" v-model="editedItem.subjects" :display-limit="0" dense multiple autocomplete return-object>
									</ac-select>
								</v-col>
							</v-row>
							<v-divider class="mt-6 mb-8"></v-divider>
							<v-row>
								<v-col cols="12" sm="4" class="pa-0 ma-0">
									<v-switch color="secondary" class="pa-1 ma-0 v-input--reverse" label="BTT Potential" v-model="editedItem.is_books_that_travel"></v-switch>
								</v-col>
							</v-row>
							<v-divider class="mt-6 mb-8"></v-divider>
							<v-row>
								<v-col cols="12" sm="6">
									<span class="text-subtitle-1">Collections</span>
									<asset-collections-table :collections.sync="collections" :selectedCollections.sync="editedItem.collections"></asset-collections-table>
								</v-col>
							</v-row>
							<v-divider class="mt-6 mb-8"></v-divider>
							<v-row>
								<v-col cols="12" sm="4">
									<v-switch class="pa-1 ma-0 v-input--reverse" label="In-Flight Approved?" v-model="editedItem.in_flight_approved"></v-switch>
								</v-col>
							</v-row>
						</v-container>
					</v-tab-item>

					<v-tab-item eager>
						<v-container>
							<v-row>
								<v-col cols="12" sm="4">
									<v-select :items="editors" :item-text="(item) => getPersonName(item, 'editor_name_english', 'editor_name')" item-value="editor_id" label="Editors" v-model="editedItem.editors" dense multiple return-object>
										<template v-slot:prepend-item>
											<div class="append">
												<create-editor buttonName="Create New Editor" @editorLoad="getEditor"></create-editor>
											</div>
										</template>
									</v-select>
								</v-col>
							</v-row>
							<v-row align="center">
								<v-col cols="12" md="4">
									<v-menu :ref="editedItem.publish_menu" v-model="editedItem.publish_menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
										<template v-slot:activator="{ on, attrs }">
											<v-text-field :value="formatDate(editedItem.target_publish_date)" label="Target Publish Date" hint="DD/MM/YYYY format" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
										</template>
										<v-date-picker ref="date" v-model="editedItem.target_publish_date" no-title scrollable>
											<v-spacer></v-spacer>
											<v-btn text color="primary" @click="clearPublishDateMenu">
												Clear
											</v-btn>
										</v-date-picker>
									</v-menu>
								</v-col>
							</v-row>
							<div v-if="! ( batchEdit )">
								<asset-manuscripts-list ref="manuscripts" v-model="editedItem.manuscripts"></asset-manuscripts-list>
							</div>
							<div v-if="! ( batchNew || batchEdit )">
								<span class="text-subtitle-1">Special Notes</span>
								<asset-comments></asset-comments>
								<v-row>
									<v-col cols="12" sm="12">
										<asset-formats-table ref="formatsInput" :formats="formats" :selected-formats.sync="editedItem.formats" :asset-id="editedItem.asset_id" @error="showError( $event.response, $event.error, $event.message )" />
									</v-col>
								</v-row>
							</div>
						</v-container>
					</v-tab-item>
				</v-form>
			</v-tabs-items>

			<v-divider class="mt-4 mb-4"></v-divider>

			<v-card-text>
				<span class="text-body-2">* indicates required fields</span>
			</v-card-text>

		</v-card>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="info" text :disabled="!changed || isCheckingLfaId || isEditingLfaId" @click="save">Save</v-btn>
			<v-btn color="error" text :disabled="!changed || isCheckingLfaId || isEditingLfaId" @click="showReset">Reset</v-btn>
		</v-card-actions>
		<ac-dialog-confirm v-model="confirmDialog" :message="confirmBatchMessage" @confirm="updateAssetsBatch" />
		<ac-dialog-confirm v-model="resetDialog" :message="'Are you sure you want to reset?\nAll information will be lost.'" @confirm="reset" />
		<ac-dialog-error v-model="errorDialog" :text="errorText" />
	</v-card>
</template>

<script>
import api from '@/services/api';
import { isEqual } from 'lodash';
import { validationMixin } from 'vuelidate';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';

import AssetCollectionsTable from '@/components/assets/asset-collections-table.vue';
import AssetComments from '@/components/asset-comments.vue';
import AssetContractSelection from '@/components/assets/asset-contract-selection.vue';
import AssetFileSelection from './asset-file-selection.vue';
import AssetFormatsTable from '@/components/assets/asset-formats-table.vue';
import AssetIsbnField from '@/components/assets/asset-isbn-field.vue';
import assetLfaIdField from '@/components/assets/asset-lfa-id-field.vue';
import assetLfaIdsList from '@/components/assets/asset-lfa-ids-list.vue';
import AssetKeywordsSelection from '@/components/assets/asset-keywords-selection.vue';
import CreateAuthor from '@/components/authors/createOrUpdate.vue';
import CreateEditor from '@/components/editors/createOrUpdate.vue';
import AssetManuscriptsList from '@/components/assets/asset-manuscripts-list.vue';
import CreateIllustrator from '@/components/illustrators/createOrUpdate.vue';

const endpoint = '/assets';
const getDefaultItem = () => {
	return {
		asset_id: null,
		asset_lfa_id: undefined,
		title: '',
		title_english: '',
		isbn_number: '',
		description: '',
		description_english: '',
		note: '',
		book_production_model_id: null,
		acquisition_type_id: null,
		asset_category_id: null,
		issues_focus_id: null,
		is_fiction: false,
		reading_focus_id: null,
		reading_level_id: null,
		language_id: null,
		funder_id: null,
		program_id: null,
		is_books_that_travel: false,
		gender_representation_id: null,
		is_commerce_site: 0,
		in_flight_approved: false,
		authors: [],
		collections: [],
		formats: [],
		illustrators: [],
		keywords: [],
		editors: [],
		manuscripts: [],
		bookProductionModels: [],
		target_publish_date: null,
		cover_id: null,
		contract_id: null,
		approved_manuscript_id: null,
		pa_reading_focuses: [],
		publish_menu: false,
		reader_published: false,
		reader_categories: [],
		subjects: [],
		active: 1,
	};
};
export default {
	mixins: [validationMixin],
	name: 'asset',
	components: {
		'asset-collections-table': AssetCollectionsTable,
		'asset-comments': AssetComments,
		'asset-contract-selection': AssetContractSelection,
		'asset-file-selection': AssetFileSelection,
		'asset-formats-table': AssetFormatsTable,
		'asset-isbn-field': AssetIsbnField,
		'asset-lfa-id-field': assetLfaIdField,
		'asset-lfa-ids-list': assetLfaIdsList,
		'asset-keywords-selection': AssetKeywordsSelection,
		'create-editor': CreateEditor,
		'create-author': CreateAuthor,
		'asset-manuscripts-list': AssetManuscriptsList,
		'create-illustrator': CreateIllustrator,
	},
	data: () => {
		return {
			batchNew: false,
			batchEdit: false,
			keywords_selected: [],
			newLfaIds: [null],
			item: getDefaultItem(),
			editedItem: getDefaultItem(),
			errorDialog: false,
			errorText: '',
			isCheckingLfaId: false,
			isEditingLfaId: false,
			resetDialog: false,
			confirmDialog: false,
			tab: null,
			changed: false,
			updated: true,
			showParentSelection: false,
			selectedAssetIds: [],
			assets: [],
			acquisitionTypes: [],
			readerCategories: [],
			issuesFocuses: [],
			contentPillars: [],
			readingFocuses: [],
			readingLevels: [],
			formats: [],
			funders: [],
			programs: [],
			genderRepresentations: [],
			authors: [],
			collections: [],
			illustrators: [],
			languages: [],
			keywords: [],
			editors: [],
			questionsPages: [],
			paReadingFocuses: [],
			subjects: [],
			bookProductionModels: [],
			overlay: false,
		};
	},
	validations: {
		newLfaIds: {
			minLength: minLength( 1 ),
			maxLength: maxLength( 100 ),
			required,
		},
		editedItem: {
			title_english: {
				maxLength: maxLength( 255 ),
			},
			title: {
				maxLength: maxLength( 255 ),
			},
			description: {
				maxLength: maxLength( 1000 ),
			},
			description_english: {
				maxLength: maxLength( 1000 ),
			},
			note: {
				maxLength: maxLength( 255 ),
			},
		},
	},
	computed: {
		formTitle() {
			if ( this.item.asset_id ) {
				let title = `LFA ID ${this.item.asset_lfa_id}`;

				if ( this.item.title_english ) {
					title = `${title} - ${this.item.title_english}`;
				} else if ( this.item.title ) {
					title = `${title} - ${this.item.title}`;
				}

				return title;
			} else if ( this.batchNew ) {
				return 'Create New Batch';
			} else if ( this.batchEdit ) {
				return 'Edit Record Batch';
			}

			return 'Create New Record';
		},
		selectableParentAssets() {
			if ( this.item.asset_id ) {
				return this.assets.filter( ( asset ) => asset.asset_id !== this.item.asset_id ).sort( ( a, b ) => a.asset_lfa_id - b.asset_lfa_id );
			}

			return this.assets;
		},
		newLfaIdsErrors() {
			const errors = [];
			if ( ! this.$v.newLfaIds.$dirty ) return errors;
			! this.$v.newLfaIds.minLength && errors.push( 'Must be a number between 1 and 100' );
			! this.$v.newLfaIds.maxLength && errors.push( 'Must be a number between 1 and 100' );
			! this.$v.newLfaIds.required && errors.push( 'Must be a number between 1 and 100' );
			return errors;
		},
		titleEnglishErrors() {
			const errors = [];
			if ( ! this.$v.editedItem.title_english.$dirty ) return errors;
			! this.$v.editedItem.title_english.maxLength && errors.push( 'Title ( English ) must be at most 255 characters long' );
			return errors;
		},
		titleErrors() {
			const errors = [];
			if ( this.$v.editedItem.title.$dirty ) {
				! this.$v.editedItem.title.maxLength && errors.push( 'Title ( Original ) must be at most 255 characters long' );
			}
			return errors;
		},
		descriptionErrors() {
			const errors = [];
			if ( this.$v.editedItem.description.$dirty ) {
				! this.$v.editedItem.description.maxLength && errors.push( 'Description ( Original ) must be at most 1000 characters long' );
			}
			return errors;
		},
		descriptionEnErrors() {
			const errors = [];
			if ( this.$v.editedItem.description_english.$dirty ) {
				! this.$v.editedItem.description_english.maxLength && errors.push( 'Description ( English ) must be at most 1000 characters long' );
			}
			return errors;
		},
		notesErrors() {
			const errors = [];
			if ( this.$v.editedItem.note.$dirty ) {
				! this.$v.editedItem.note.maxLength && errors.push( 'Notes must be at most 255 characters long' );
			}
			return errors;
		},
		confirmBatchMessage() {
			let message = '';
			message += `You are making changes to ${this.selectedAssetIds.length} ${this.selectedAssetIds.length === 0 ? 'record' : 'records'}.\n`;

			message += this.changeLogs;

			message += 'Do you wish to proceed?';
			return message;
		},
		changeLogs() {
			let logs = '';
			const updatedFields = {};
			for ( const field in this.editedItem ) {
				if ( field !== 'asset_id' && ! isEqual( this.item[field], this.editedItem[field] ) ) {
					updatedFields[field] = this.editedItem[field];
				}
			}
			for ( const field in updatedFields ) {
				if ( Array.isArray( updatedFields[field] ) && updatedFields[field].length === 0 ) {
					continue;
				}
				logs += this.getDisplayValueForKey( field, updatedFields[field] ) + '\n';
			}
			return logs;
		},
	},
	watch: {
		$route( to, from ) {
			this.item.asset_id = to.params.asset_id;

			this.loadFormResources();

			if ( ! this.item.asset_id ) {
				// This is the route to create a new item, assign the default values;
				this.changed = false;
				this.updated = true;
				this.item = getDefaultItem();
				this.editedItem = getDefaultItem();

				switch ( to.name ) {
				case 'asset-new':
					this.batchNew = false;
					this.batchEdit = false;
					break;
				case 'asset-new-batch':
					this.batchNew = true;
					this.batchEdit = false;
					break;
				case 'asset-edit-batch':
					this.batchNew = false;
					this.batchEdit = true;
					break;
				default:
					break;
				}

				return;
			}

			if ( from.params && this.item.asset_id === from.params.asset_id ) {
				// Same asset, just reset the values to their original values
				this.reset();

				return;
			}

			this.load();
		},
		editedItem: {
			deep: true,
			handler() {
				if ( this.updated ) {
					this.updated = false;
					return;
				}

				this.changed = true;
			},
		},
		showParentSelection( val ) {
			// Remove the parent_asset_id if the "Derivative" checkbox is disabled
			if ( ! val ) {
				this.editedItem.parent_asset_id = null;
			}
		},
	},
	created() {
		this.loadFormResources();
		this.item.asset_id = this.$route.params.asset_id;
		const query = this.$route.query;
		if ( query.selectedIds ) {
			this.selectedAssetIds = JSON.parse( query.selectedIds );
		}
		if ( this.item.asset_id ) {
			this.load();
		} else {
			this.updated = false;

			switch ( this.$route.name ) {
			case 'asset-new':
				this.batchNew = false;
				break;
			case 'asset-new-batch':
				this.batchNew = true;
				break;
			case 'asset-edit-batch':
				this.batchEdit = true;
				break;
			default:
				break;
			}
		}
	},
	methods: {
		getDisplayValueForKey( key, val ) {
			if ( key === 'program_id' ) {
				const program = this.programs.find( ( program ) => program.program_id === val );
				return `Program : ${program.program_name}`;
			}
			if ( key === 'funder_id' ) {
				const funder = this.funders.find( ( funder ) => funder.funder_id === val );
				return `Funder : ${funder.funder_name}`;
			}
			if ( key === 'language_id' ) {
				const language = this.languages.find( ( language ) => language.language_id === val );
				return `Language : ${language.language_name}`;
			}
			if ( key === 'formats' ) {
				return `Formats : ${val.map( ( format ) => format.format_name ).join( ', ' )}`;
			}
			if ( key === 'is_commerce_site' ) {
				return `Listed On LFA Commerce Site : ${val ? 'Yes' : 'No'}`;
			}
			if ( key === 'editors' ) {
				return `Editors : ${val.map( ( editor ) => editor.editor_name ).join( ', ' )}`;
			}
			if ( key === 'book_production_model_id' ) {
				const bookProductionModel = this.bookProductionModels.find( ( bookProductionModel ) => bookProductionModel.book_production_model_id === val );
				return `Book Production Model : ${bookProductionModel.book_production_model_name}`;
			}
			if ( key === 'target_publish_date' ) {
				return `Target Publish Date : ${val}`;
			}
			return `${key} : ${val}`;
		},
		getAuthor( val ) {
			this.authors.unshift( val );
		},
		getIllustrator( val ) {
			this.illustrators.unshift( val );
		},
		getEditor( val ) {
			this.editors.unshift( { ...val[0] } );
		},
		getPersonName( obj, englishNameKey, originalNameKey ) {
			if ( obj[originalNameKey] ) {
				return `${obj[englishNameKey]} (${obj[originalNameKey]})`;
			}

			return obj[englishNameKey];
		},
		getReaderCategory( val ) {
			this.readerCategories.unshift( val );
		},
		getSubject( val ) {
			this.subjects.unshift( val );
		},
		loadFormResources() {
			this.loadKeywords();
			this.loadAssets();
			this.loadAcquisitionTypes();
			this.loadCollections();
			this.loadIssuesFocuses();
			this.loadReadingFocuses();
			this.loadReadingLevels();
			this.loadContentPillars();
			this.loadFormats();
			this.loadFunders();
			this.loadGenderRepresentations();
			this.loadAuthors();
			this.loadIllustrators();
			this.loadLanguages();
			this.loadPrograms();
			this.loadEditors();
			this.loadBookProductionModels();
			this.loadQuestionsPages();
			this.loadPaReadingFocuses();
			this.loadSubjects();
		},
		isFictionChanged( val ) {
			this.editedItem.is_fiction = !! val;
		},
		reset() {
			this.changed = false;
			this.updated = true;
			this.editedItem = JSON.parse( JSON.stringify( this.item ) );
			this.showParentSelection = !! this.editedItem.parent_asset_id;
		},
		showConfirmBatch() {
			this.confirmDialog = true;
		},
		showReset() {
			this.resetDialog = true;
		},
		showError( res, err, defaultText ) {
			this.errorText = defaultText;
			if ( err && err.response && err.response.data && err.response.data.error ) {
				this.errorText += ': ' + err.response.data.error;
			} else if ( res && res.data.error ) {
				this.errorText += ': ' + res.data.error;
			} else if ( err && 'Error: Network Error' == err.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.errorDialog = true;
		},
		clearPublishDateMenu() {
			this.editedItem.publish_menu = false;
			this.editedItem.target_publish_date = null;
		},
		formatDate( date ) {
			if ( ! date ) return null;
			// Dates from the date picker are always ISO strings
			const [year, month, day] = date.split( '-' );
			return `${day}/${month}/${year}`;
		},
		async save() {
			this.$v.$touch();

			if ( this.$v.$invalid ) {
				return;
			}

			if ( this.$refs.isbnField && ! this.$refs.isbnField.isValid ) {
				return;
			}

			if ( this.item.asset_id ) {
				return this.updateAsset();
			} else if ( this.batchEdit ) {
				return this.showConfirmBatch();
			} else if ( this.batchNew ) {
				return this.createAssetsBatch();
			} else {
				return this.createAsset();
			}
		},
		async createAsset() {
			const accel_api = api( this );

			this.overlay = true;

			let res;
			try {
				res = await accel_api.post( endpoint, this.editedItem );
				this.overlay = false;
				if ( 200 !== res.status || 'success' !== res.data.result ) {
					this.showError( res, null, 'Failed to save the Asset' );

					return;
				} else {
					this.$store.dispatch( 'SHOW_INFO_MESSAGE', { message: 'Record created successfully!' } );
				}
			} catch ( err ) {
				this.overlay = false;
				this.showError( null, err, 'Failed to save the Asset' );

				return;
			}

			// Go to the previous page after creation
			// TODO handle cases where the user comes from an external website directly to this page
			this.$router.back();
		},
		async updateAsset() {
			const accel_api = api( this );
			const updatedFields = {};
			let needsUpdate = false;

			this.overlay = true;

			for ( const field in this.editedItem ) {
				if ( ! isEqual( this.item[field], this.editedItem[field] ) ) {
					updatedFields[field] = this.editedItem[field];
					needsUpdate = true;
				}
			}

			if ( ! needsUpdate ) {
				// No update to process, just reset the original values
				this.reset();
			}

			let res;
			try {
				res = await accel_api.put( `${endpoint}/${this.item.asset_id}`, updatedFields );
				this.overlay = false;
				if ( 200 !== res.status || 'success' !== res.data.result ) {
					this.showError( res, null, 'Failed to save the Asset' );

					return;
				} else {
					this.changed = false;
					this.$store.dispatch( 'SHOW_INFO_MESSAGE', { message: 'Record updated successfully!' } );
				}
			} catch ( err ) {
				this.overlay = false;
				this.showError( null, err, 'Failed to save the Asset' );

				return;
			}

			await Promise.allSettled( [this.$refs.coverInput.finalize(), this.$refs.approvedManuscriptInput.finalize(), this.$refs.formatsInput.finalize()] );

			this.changed = false;
			this.item = JSON.parse( JSON.stringify( this.editedItem ) );

			this.load();
		},
		async createAssetsBatch() {
			const accel_api = api( this );
			this.overlay = true;

			const payload = [];
			for ( let i = 0; i < this.newLfaIds.length; i ++ ) {
				payload.push( {
					asset_lfa_id: this.newLfaIds[i],
					...this.editedItem,
				} );
			}

			let res;
			try {
				res = await accel_api.post( `${endpoint}/batch`, { data: payload } );
				this.overlay = false;
				if ( 200 !== res.status || 'success' !== res.data.result ) {
					this.showError( res, null, 'Failed to save the Asset' );

					return;
				} else {
					if ( res.data.insertIds ) {
						this.$store.dispatch( 'SHOW_INFO_MESSAGE', { message: `Records created successfully:\n${res.data.insertIds.map( ( id ) => `LFA ID ${id}` ).join( '\n' )}`, dialog: true } );
					} else {
						this.$store.dispatch( 'SHOW_INFO_MESSAGE', { message: 'Records created successfully!' } );
					}
				}
			} catch ( err ) {
				this.overlay = false;
				this.showError( null, err, 'Failed to save the Asset' );

				return;
			}

			// Go to the previous page after creation
			// TODO handle cases where the user comes from an external website directly to this page
			this.$router.back();
		},
		async updateAssetsBatch() {
			const accel_api = api( this );
			this.overlay = true;
			const payload = [];

			const updatedFields = {};
			for ( const field in this.editedItem ) {
				if ( field !== 'asset_id' && ! isEqual( this.item[field], this.editedItem[field] ) ) {
					updatedFields[field] = this.editedItem[field];
				}
			}

			for ( let i = 0; i < this.selectedAssetIds.length; i ++ ) {
				const newItem = Object.assign( {}, updatedFields );
				newItem.asset_id = this.selectedAssetIds[i];
				payload.push( newItem );
			}

			let res;
			try {
				res = await accel_api.put( `${endpoint}/batch`, { data: payload } );
				this.overlay = false;
				if ( 200 !== res.status || 'success' !== res.data.result ) {
					this.showError( res, null, 'Failed to save the Asset Batch' );

					return;
				} else {
					this.changed = false;
					this.$store.dispatch( 'SHOW_INFO_MESSAGE', { message: 'Records updated successfully!' } );
				}
			} catch ( err ) {
				this.overlay = false;
				this.showError( null, err, 'Failed to save the Asset Batch' );

				return;
			}

			// Go to the previous page after creation
			// TODO handle cases where the user comes from an external website directly to this page
			this.$router.back();
		},
		async getResource( url, errorMsg, data ) {
			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : null );
			} catch ( err ) {
				this.showError( null, err, errorMsg );
			}

			if ( 401 == res.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res.status || 'success' !== res.data.result ) {
				this.showError( res, null, errorMsg );

				return;
			}

			return res.data.data;
		},
		async load() {
			const asset = ( await this.getResource( `${endpoint}/${this.item.asset_id}`, 'Failed to load the Asset' ) )[0];
			if ( asset ) {
				this.item = asset;
			} else {
				// TODO create "not found" view
				this.item = getDefaultItem();
			}
			this.updated = true;
			this.editedItem = JSON.parse( JSON.stringify( this.item ) );
			this.keywords_selected = this.editedItem.keywords;
			this.changed = false;
			this.showParentSelection = !! this.item.parent_asset_id;
		},
		async loadAssets() {
			this.assets = await this.getResource( '/assets', 'Failed to load the Assets', { no_associations: true } );
		},
		async loadAcquisitionTypes() {
			this.acquisitionTypes = await this.getResource( '/acquisition-types', 'Failed to load the Acquisition Types' );
		},
		async loadCollections() {
			this.collections = await this.getResource( '/collections', 'Failed to load the Collections' );
		},
		async loadIssuesFocuses() {
			this.issuesFocuses = await this.getResource( '/issues-focus', 'Failed to load the Issues Focuses' );
		},
		async loadReadingFocuses() {
			this.readingFocuses = await this.getResource( '/reading-focus', 'Failed to load the Reading Focuses' );
		},
		async loadFormats() {
			this.formats = await this.getResource( '/formats', 'Failed to load the Formats' );
		},
		async loadFunders() {
			this.funders = await this.getResource( '/funders', 'Failed to load the Funders' );
		},
		async loadReadingLevels() {
			this.readingLevels = await this.getResource( '/reading-levels', 'Failed to load the Reading Levels' );
		},
		async loadGenderRepresentations() {
			this.genderRepresentations = await this.getResource( '/gender-representations', 'Failed to load the Gender Representations' );
		},
		async loadAuthors() {
			this.authors = await this.getResource( '/authors', 'Failed to load the Authors' );
		},
		async loadIllustrators() {
			this.illustrators = await this.getResource( '/illustrators', 'Failed to load the Illustrators' );
		},
		async loadLanguages() {
			this.languages = await this.getResource( '/language', 'Failed to load the Languages' );

			this.languages.unshift( { language_id: null, language_name: 'None' } );
		},
		async loadPrograms() {
			this.programs = await this.getResource( '/programs', 'Failed to load the Programs' );
		},
		async loadKeywords() {
			this.keywords = await this.getResource( '/keywords', 'Failed to load the Keywords' );
		},
		async loadEditors() {
			this.editors = await this.getResource( '/editors', 'Failed to load the Editors' );
		},
		async loadContentPillars() {
			this.contentPillars = await this.getResource( '/content-pillars', 'Failed to load the Content Pillars' );
		},
		async loadBookProductionModels() {
			this.bookProductionModels = await this.getResource( '/book-production-models', 'Failed to load the Book Production Models' );
		},
		async loadPaReadingFocuses() {
			this.paReadingFocuses = await this.getResource( '/pa-reading-focuses', 'Failed to load the Program Aligned Reading Focuses' );
		},
		async loadQuestionsPages() {
			this.questionsPages = await this.getResource( '/questions-pages', 'Failed to load the Questions Pages' );
		},
		async loadSubjects() {
			this.subjects = await this.getResource( '/subjects', 'Failed to load the Subjects' );
		},
	},
};
</script>

<style scoped lang="scss">
.short-input::v-deep .v-input__slot {
	width: 50px;
}
.v-input--reverse .v-input__slot {
	flex-direction: row-reverse !important;
	justify-content: flex-end !important;
}
.v-input--reverse .v-input__control .v-input__slot .v-input--selection-controls__input {
	margin-right: 40% !important;
}
.v-input--reverse .v-label {
	padding-left: 8px;
}
.v-input--reverse .v-messages {
	display: none !important;
}
.switch-reverse-label {
	margin-right: 8px;
}
.derivatives-list {
	list-style-type: none;
	padding: 0;
	margin: 0;
}
.v-sheet.v-list {
	padding-top: 0 !important;
}
.append {
	position: -webkit-sticky;
	position: sticky;
	bottom: 8px;
	width: 100% !important;
	display: flex;
	justify-content: center;
	background: white;
	top: 0;
	z-index: 1;
}
::v-deep .createBtn {
	background-color: #14234f !important;
	width: 100% !important;
	margin: 0 !important;
	height: 35px !important;
}
::v-deep .createBtnDialog {
	width: 100% !important;
}
::v-deep v-icon {
	display: none !important;
}
</style>
