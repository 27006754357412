import { render, staticRenderFns } from "./collection-category-create.vue?vue&type=template&id=3d64c80a&scoped=true&"
import script from "./collection-category-create.vue?vue&type=script&lang=ts&"
export * from "./collection-category-create.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d64c80a",
  null
  
)

export default component.exports