








import { Component, Vue } from 'vue-property-decorator';
import ApiResources from '@/components/api-resources.vue';

@Component( {
	components: {
		ApiResources,
	},
} )

export default class ApiResourcesView extends Vue {}

