<template>
	<div>
		<ac-list-page :headers="headers" :items="dataset" :items-per-page.sync="itemsPerPage" :loading="loading" :text-search-filter="filterAssets" :title="!showCatalogCreator ? 'Content Asset Register' : 'Catalog Creator'" sort-by="asset_lfa_id" @update="editItem($event)" @delete="deleteItem($event)" :show-search.sync="showSearch" @search="onSearch" @clear="onClear">
			<template v-slot:buttons>
				<v-row no-gutters class="my-0 px-3" align="center">
					<v-col cols="12" class="px-4 px-sm-0 d-flex flex-column flex-sm-row">
						<v-btn color="primary" @click="toogleSearch">
							<v-icon left>mdi-filter</v-icon>
							Refine
						</v-btn>

						<v-btn color="primary" @click="createItem" class="my-2 my-sm-0 ms-sm-4" v-if="rolePermission.add == 1">
							Create New Record
						</v-btn>

						<v-btn color="primary" @click="createBatch" class="my-2 my-sm-0 ms-sm-4" v-if="rolePermission.add == 1">
							Create Batch Records
						</v-btn>

						<v-btn :disabled="!(selectedAssets.length)" color="primary" @click="editBatch" class="my-2 my-sm-0 ms-sm-4">
							Edit Selected Records
						</v-btn>
						<v-btn color="primary" @click="toogleCatalogCreator" class="my-2 my-sm-0 ms-sm-4">
							<v-icon left>mdi-filter</v-icon>
							Catalog Creator
						</v-btn>
						<asset-list-export-dialog :value="selectedAssets" @error="showError( $event.response, $event.error, $event.message )" />
					</v-col>
				</v-row>
			</template>
			<template v-slot:top>
				<v-switch v-model="selectAll" label="Select All" :color="color" class="pa-3"></v-switch>
			</template>
			<template v-slot:[`item.selected`]="{ item }">
				<input type="checkbox" :id="item.asset_id" :value="item.asset_id" v-model="selectedAssets">
			</template>
			<template v-slot:[`search.title`]>
				<span v-if="!showCatalogCreator">Refine</span>
				<span v-if="showCatalogCreator">Catalog Creator</span>
			</template>
			<template v-slot:[`search.inputs`]>
				<v-list v-if="!showCatalogCreator" expand>
					<v-list-group :value="hasRecordInfoParams">
						<template v-slot:activator>
							<v-list-item-title>Record Information</v-list-item-title>
						</template>

						<ac-search-card-item title="LFA ID" :value="assetIds != null">
							<v-text-field v-model="assetIds" @blur="!$v.assetIds.$touch()" @input="$v.assetIds.$touch()" :error-messages="assetIdsErrors" dense solo flat outlined single-line>
								<template v-slot:append-outer>
									<v-tooltip right max-width="300">
										<template v-slot:activator="{ on, attrs }">
											<v-icon color="primary" dark v-bind="attrs" v-on="on">
												mdi-information-outline
											</v-icon>
										</template>
										<span>Refine multiple asset ID numbers by separating individual IDs with a comma ( , ) or a range of IDs with a hyphen ( - )</span>
									</v-tooltip>
								</template>
							</v-text-field>
						</ac-search-card-item>

						<ac-search-card-item title="Program" :value="!!searchParams.program_id">
							<ac-select :items="programs" item-value="program_id" item-text="program_name" label="Program" v-model="searchParams.program_id" multiple autocomplete dense solo flat outlined hide-details show-select-all></ac-select>
						</ac-search-card-item>

						<ac-search-card-item title="Funder" :value="!!searchParams.funder_id">
							<ac-select :items="funders" item-value="funder_id" item-text="funder_name" label="Funder" v-model="searchParams.funder_id" multiple autocomplete dense solo flat outlined hide-details show-select-all></ac-select>
						</ac-search-card-item>

						<ac-search-card-item title="Published Language" :value="!!searchParams.language_id">
							<ac-select :items="languages" item-value="language_id" item-text="language_name" label="Published Language" v-model="searchParams.language_id" multiple autocomplete dense solo flat outlined hide-details show-select-all></ac-select>
						</ac-search-card-item>

						<ac-search-card-item title="Format" :value="!!searchParams.format_id">
							<ac-select :items="formats" item-value="format_id" item-text="format_name" label="Format" v-model="searchParams.format_id" multiple autocomplete dense solo flat outlined hide-details show-select-all></ac-select>
						</ac-search-card-item>

						<ac-search-card-item title="Page Count" :value="null != searchParams.page_count">
							<v-text-field type="number" class="short-input" v-model.number="searchParams.page_count" @blur="!$v.searchParams.page_count.$touch()" @input="$v.searchParams.page_count.$touch()" :error-messages="pageCountErrors" dense solo flat outlined single-line></v-text-field>
						</ac-search-card-item>

						<ac-search-card-item title="Date Published" :value="searchParams.date_published_from !== undefined || searchParams.date_published_to !== undefined">
							<ac-date-picker v-model="searchParams.date_published_from" label="Date From" hint="DD/MM/YYYY format" dense solo flat outlined hide-details />
							<div class="my-1" />
							<ac-date-picker v-model="searchParams.date_published_to" label="Date To" hint="DD/MM/YYYY format" dense solo flat outlined hide-details />
						</ac-search-card-item>

						<v-list-item class="pl-8">
							<v-list-item-action class="mr-4">
								<ac-tri-state-checkbox v-model="searchParams.is_commerce_site" />
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title>List on LFA Commerce</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<ac-search-card-item title="Derivative" :value="null != searchParams.derivative">
							<v-list-item class="pl-8">
								<v-list-item-action class="mr-4">
									<ac-tri-state-checkbox v-model="searchParams.derivative" />
								</v-list-item-action>
								<v-list-item-content>
									<v-list-item-title>Derivative</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</ac-search-card-item>
					</v-list-group>

					<v-list-group :value="hasBookInfoParams">
						<template v-slot:activator>
							<v-list-item-title>Book Information</v-list-item-title>
						</template>
						<ac-search-card-item title="Author" :value="searchParams.author != null">
							<v-text-field single-line class="short-input" label="Author Name" v-model="searchParams.author"></v-text-field>
						</ac-search-card-item>
						<ac-search-card-item title="Book Title" :value="searchParams.title != null">
							<v-text-field single-line class="short-input" label="Book Title" v-model="searchParams.title"></v-text-field>
						</ac-search-card-item>
						<ac-search-card-item title="Book Production Model" :value="!!searchParams.book_production_model_name">
							<v-text-field single-line class="short-input" label="Book Production Model" v-model="searchParams.book_production_model_name"> </v-text-field>
						</ac-search-card-item>
						<ac-search-card-item title="Content Pillars" :value="!!searchParams.content_pillar_id">
							<ac-select :items="contentPillars" item-value="content_pillar_id" item-text="content_pillar_name" label="Content Pillars" v-model="searchParams.content_pillar_id" multiple autocomplete dense solo flat outlined hide-details show-select-all></ac-select>
						</ac-search-card-item>
						<ac-search-card-item title="Acquisition Types" :value="!!searchParams.acquisition_type_id">
							<ac-select :items="acquisitionTypes" item-value="acquisition_type_id" item-text="acquisition_type_name" label="Acquisition Types" v-model="searchParams.acquisition_type_id" multiple autocomplete dense solo flat outlined hide-details show-select-all></ac-select>
						</ac-search-card-item>
						<ac-search-card-item title="Gender Representation" :value="!!searchParams.gender_representation_id">
							<ac-select :items="genderRepresentations" item-value="gender_representation_id" item-text="gender_representation_description" label="Gender Representation" v-model="searchParams.gender_representation_id" multiple autocomplete dense solo flat outlined hide-details show-select-all></ac-select>
						</ac-search-card-item>
						<ac-search-card-item title="Issues Focuses" :value="!!searchParams.issues_focus_id">
							<ac-select :items="issuesFocuses" item-value="issues_focus_id" item-text="issues_focus_description" label="Issues Focuses" v-model="searchParams.issues_focus_id" multiple autocomplete dense solo flat outlined hide-details show-select-all></ac-select>
						</ac-search-card-item>
						<ac-search-card-item title="Reading Focuses" :value="!!searchParams.reading_focus_id">
							<ac-select :items="readingFocuses" item-value="reading_focus_id" item-text="reading_focus_description" label="Reading Focuses" v-model="searchParams.reading_focus_id" multiple autocomplete dense solo flat outlined hide-details show-select-all></ac-select>
						</ac-search-card-item>
						<ac-search-card-item title="Program Aligned Reading Focuses" :value="!!searchParams.pa_reading_focus_id">
							<ac-select :items="paReadingFocuses" item-value="pa_reading_focus_id" item-text="pa_reading_focus_name" label="Program Aligned Reading Focuses" v-model="searchParams.pa_reading_focus_id" multiple autocomplete dense solo flat outlined hide-details show-select-all></ac-select>
						</ac-search-card-item>
						<ac-search-card-item title="Reading Level" :value="!!searchParams.reading_level_id">
							<ac-select :items="readingLevels" item-value="reading_level_id" item-text="reading_level_name" label="Reading Levels" v-model="searchParams.reading_level_id" multiple autocomplete dense solo flat outlined hide-details show-select-all></ac-select>
						</ac-search-card-item>
						<ac-search-card-item title="Collections" :value="!!searchParams.collection_id">
							<v-list-item class="pl-8">
								<v-list-item-action class="mr-4">
									<ac-tri-state-checkbox v-model="searchParams.window" />
								</v-list-item-action>
								<v-list-item-content>
									<v-list-item-title>Window</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<v-list-item class="pl-8">
								<v-list-item-action class="mr-4">
									<ac-tri-state-checkbox v-model="searchParams.mirror" />
								</v-list-item-action>
								<v-list-item-content>
									<v-list-item-title>Mirror</v-list-item-title>
								</v-list-item-content>
							</v-list-item>

							<ac-select :items="collections" item-value="collection_id" item-text="collection_name" label="Collections" v-model="searchParams.collection_id" multiple autocomplete dense solo flat outlined hide-details show-select-all></ac-select>
						</ac-search-card-item>
						<ac-search-card-item title="Contract Date" :value="!!searchParams.contract_date_from || !!searchParams.contract_date_to">
							<ac-date-picker v-model="searchParams.contract_date_from" label="Date From" hint="DD/MM/YYYY format" dense solo flat outlined hide-details />
							<div class="my-1" />
							<ac-date-picker v-model="searchParams.contract_date_to" label="Date To" hint="DD/MM/YYYY format" dense solo flat outlined hide-details />
						</ac-search-card-item>
						<ac-search-card-item title="In-flight Approved?" :value="null != searchParams.in_flight_approved">
							<v-list-item class="pl-8">
								<v-list-item-action class="mr-4">
									<ac-tri-state-checkbox v-model="searchParams.in_flight_approved" />
								</v-list-item-action>
								<v-list-item-content>
									<v-list-item-title>In-flight Approved?</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</ac-search-card-item>
						<ac-search-card-item title="Subject" :value="searchParams.subject != null">
							<v-text-field single-line class="short-input" label="Subject Term / Label" v-model="searchParams.subject"></v-text-field>
						</ac-search-card-item>
						<ac-search-card-item title="Text Type" :value="null != searchParams.is_fiction">
							<v-list-item class="pl-8">
								<v-list-item-action class="mr-4">
									<ac-tri-state-checkbox v-model="searchParams.is_fiction" />
								</v-list-item-action>
								<v-list-item-content>
									<v-list-item-title>Fiction</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</ac-search-card-item>
					</v-list-group>

					<v-list-group :value="hasInFlightParams">
						<template v-slot:activator>
							<v-list-item-title>In-Flight</v-list-item-title>
						</template>

						<ac-search-card-item title="Editor" :value="searchParams.editor != null">
							<v-text-field single-line class="short-input" label="Editor Name" v-model="searchParams.editor"></v-text-field>
						</ac-search-card-item>

						<ac-search-card-item title="Illustrator" :value="searchParams.illustrator != null">
							<v-text-field single-line class="short-input" label="Illustrator Name" v-model="searchParams.illustrator"></v-text-field>
						</ac-search-card-item>

						<ac-search-card-item title="Target Publish Date" :value="searchParams.target_publish_date_from !== undefined || searchParams.target_publish_date_to !== undefined">
							<ac-date-picker v-model="searchParams.target_publish_date_from" label="Date From" hint="DD/MM/YYYY format" dense solo flat outlined hide-details />
							<div class="my-1" />
							<ac-date-picker v-model="searchParams.target_publish_date_to" label="Date To" hint="DD/MM/YYYY format" dense solo flat outlined hide-details />
						</ac-search-card-item>

						<asset-manuscripts-search v-model="searchParams.manuscripts_done" />
					</v-list-group>
				</v-list>
				<v-list v-if="showCatalogCreator" expand>
					<ac-search-card-item title="Select Collections" :value="!!searchParams.catalogs">
						<v-list-item v-for="collection in collections" :key="collection.collection_id">
							<input type="radio" :id="collection.collection_id" :value="collection.collection_id" v-model="selectedCollections" @change="onCollectionChange">
							<label :for="collection.collection_id"> {{ collection.collection_name }}</label>
						</v-list-item>
					</ac-search-card-item>
				</v-list>
			</template>
			<template v-slot:[`item.asset_lfa_id`]="{ item }">
				<router-link :to="`/assets/${ item.asset_id }`">
					{{ item.asset_lfa_id }}
				</router-link>
			</template>
			<template v-slot:[`item.authors`]="{ item }">
				{{ item.authors.map((a) => a.author_name_english).join(', ') }}
			</template>
		</ac-list-page>
		<ac-dialog-deactivate v-model="deleteDialog" :itemId="deleteID" :itemType="'Asset'" :itemName="deleteText" @deactivate="deactivate" />
		<ac-dialog-error v-model="errorDialog" :text="errorText" />
	</div>
</template>

<script>
import api, { decodeQueryParams, encodeQueryParams } from '@/services/api';
import { validationMixin } from 'vuelidate';
import { helpers, integer, minValue } from 'vuelidate/lib/validators';
import AssetManuscriptsSearch from './asset-manuscripts-search.vue';
import AssetListExportDialog from './asset-list-export-dialog.vue';

const isIdsQuery = ( val ) => {
	// Match single numerical IDs, or either sequences of comma-separated IDs or ranges in the form "1 - 5"
	return /^\d+(?:(?:\s*-\s*\d+)?|(?:\s*,\s*\d+)*)$/.test( String( val ).trim() );
};

const idsQueryFromString = ( val ) => {
	if ( ! val || ! isIdsQuery( val ) ) {
		return {};
	}

	const range = val.split( '-' );
	const selection = val.split( ',' );

	if ( range.length > 1 && selection.length > 1 ) {
		// This query is not valid, as we can't handle both ID ranges and lists of IDs.
		// This case should already be covered by `isIdsQuery`, but you never know...
		return {};
	}

	if ( range.length === 2 ) {
		// Return a query for a range of IDs
		return {
			asset_lfa_id_from: String( range[0] ).trim(),
			asset_lfa_id_to: String( range[1] ).trim(),
		};
	}

	if ( selection.length > 1 ) {
		// Return an array of IDs
		return {
			asset_id: selection.map( ( id ) => String( id ).trim() ),
		};
	}

	// This should be a single ID
	return {
		asset_id: String( val ).trim(),
	};
};

const idsQueryToString = ( params ) => {
	if ( params.asset_lfa_id ) {
		if ( Array.isArray( params.asset_lfa_id ) ) {
			return params.asset_lfa_id.join( ', ' );
		} else {
			return String( params.asset_lfa_id );
		}
	}

	if ( params.asset_lfa_id_from && params.asset_lfa_id_to ) {
		return `${params.asset_lfa_id_from} - ${params.asset_lfa_id_to}`;
	}

	return null;
};

const idsQueryValidator = ( val ) => ! helpers.req( val ) || isIdsQuery( val );

const endPoint = '/assets';

export default {
	components: {
		'asset-manuscripts-search': AssetManuscriptsSearch,
		'asset-list-export-dialog': AssetListExportDialog,
	},
	name: 'assets',
	mixins: [validationMixin],
	data: () => {
		return {
			headers: [
				{ text: '', value: 'selected', align: 'center', divider: true },
				{ text: 'LFA ID', value: 'asset_lfa_id', align: 'center', divider: true },
				{ text: 'Book Title (English)', value: 'title_english', align: 'center', divider: true },
				{ text: 'Author Name(s)', value: 'authors', align: 'center', divider: true },
				{ text: 'Program Name', value: 'program_name', align: 'center', divider: true },
				{ text: 'Status', value: '', align: 'center', divider: true },
			],
			dataset: [],
			color: 'blue',
			selectAll: false,
			selectedAssets: [],
			selectedCollections: 0,
			deleteDialog: false,
			deleteID: 0,
			deleteText: '',
			errorDialog: false,
			errorText: '',
			showSearch: false,
			showCatalogCreator: false,
			searchParams: {},
			assetIds: '',
			formats: [],
			funders: [],
			languages: [],
			programs: [],
			contentPillars: [],
			acquisitionTypes: [],
			genderRepresentations: [],
			issuesFocuses: [],
			readingFocuses: [],
			readingLevels: [],
			paReadingFocuses: [],
			collections: [],
			subjects: [],
			loading: false,
		};
	},
	validations: {
		searchParams: {
			page_count: {
				integer,
				minValue: minValue( 0 ),
			},
		},
		assetIds: {
			idsQueryValidator,
		},
	},
	computed: {
		itemsPerPage: {
			get() {
				return this.$store.getters.itemsPerList( 'assets' );
			},
			set( val ) {
				this.$store.dispatch( 'SET_ITEMS_PER_LIST', {
					listName: 'assets',
					number: val,
				} );
			},
		},
		hasRecordInfoParams() {
			const recordInfoInputs = ['asset_lfa_id_from', 'asset_lfa_id_to', 'asset_lfa_id', 'date_published_from', 'date_published_to', 'format_id', 'funder_id', 'is_commerce_site', 'language_id', 'page_count', 'program_id'];
			return Object.keys( this.searchParams ).find( ( key ) => recordInfoInputs.includes( key ) && this.searchParams[key] !== undefined ) != null;
		},
		hasBookInfoParams() {
			const inputs = ['acquisition_type_id', 'author', 'book_production_model_name', 'collection_id', 'content_pillar_id', 'contract_date_from', 'contract_date_to', 'gender_representation_id', 'in_flight_approved', 'is_fiction', 'issues_focus_id', 'reading_focus_id', 'reading_level_name', 'subject', 'title'];
			return Object.keys( this.searchParams ).find( ( key ) => inputs.includes( key ) && this.searchParams[key] !== undefined ) != null;
		},
		hasInFlightParams() {
			const inFlightInputs = ['editor', 'illustrator', 'target_publish_date_from', 'target_publish_date_to', 'manuscripts_done'];
			return Object.keys( this.searchParams ).find( ( key ) => inFlightInputs.includes( key ) ) != null;
		},
		assetIdsErrors() {
			const errors = [];
			if ( ! this.$v.assetIds.$dirty ) return errors;
			! this.$v.assetIds.idsQueryValidator && errors.push( 'Must be a list of IDs separated by "," or a range separated by "-"' );
			return errors;
		},
		pageCountErrors() {
			const errors = [];
			if ( ! this.$v.searchParams.page_count.$dirty ) return errors;
			! this.$v.searchParams.page_count.integer && errors.push( 'Must be a positive number' );
			! this.$v.searchParams.page_count.minValue && errors.push( 'Must be a positive number' );
			return errors;
		},
	},
	watch: {
		$route( to ) {
			this.initSearchParams( to.query );
			this.load();
		},
		deleteDialog( val ) {
			if ( ! val ) {
				this.deleteID = 0;
			}
		},
		selectedAssets( val ) {
			const items = this.dataset.map( function ( item ) {
				return item.asset_id;
			} );
			if ( val.length == items.length ) {
				this.selectAll = true;
				this.color = 'blue';
			}
			if ( this.selectAll === true ) {
				if ( val.length != items.length ) {
					this.color = 'red';
				}
				if ( val.length === 0 ) {
					this.selectAll = false;
				}
			}
		},
		selectAll( val ) {
			if ( val === true ) {
				this.color = 'blue';
				const items = this.dataset.map( function ( item ) {
					return item.asset_id;
				} );
				this.selectedAssets = items;
			} else {
				this.selectedAssets = [];
			}
		},
		assetIds( val ) {
			const query = idsQueryFromString( val );
			this.searchParams.asset_lfa_id = query.asset_lfa_id;
			this.searchParams.asset_lfa_id_from = query.asset_lfa_id_from;
			this.searchParams.asset_lfa_id_to = query.asset_lfa_id_to;
		},
	},
	created() {
		this.initSearchParams( this.$route.query );
		this.load();
		this.loadFormResources();
	},
	methods: {
		onSearch() {
			// Stop if the search inputs are invalid
			if ( this.$v.$invalid ) {
				return;
			}

			// Ignore `null` parameters
			const queryParams = {};
			for ( const param in this.searchParams ) {
				if ( this.searchParams[param] !== null && this.searchParams[param] !== '' ) {
					queryParams[param] = this.searchParams[param];
				}
			}

			// Ignore navigation errors (mostly NavigationDuplicated errors), as API errors are already handled in the page. See https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
			this.$router.push( { name: this.$route.name, query: encodeQueryParams( queryParams ) } ).catch( () => {} );
			this.filterCollection();
		},
		onClear() {
			for ( const param in this.searchParams ) {
				if ( this.searchParams[param] !== null ) {
					this.searchParams[param] = null;
				}
			}

			// Ignore navigation errors (mostly NavigationDuplicated errors), as API errors are already handled in the page. See https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
			this.$router.push( { name: this.$route.name } ).catch( () => {} );
		},
		initSearchParams( query ) {
			this.searchParams = {};

			if ( query && Object.keys( query ).length > 0 ) {
				this.showSearch = true;
				if ( Object.keys( query ).length === 1 && Object.keys( query ).find( ( key ) => key === 'catalogs' ) != null ) {
					this.showCatalogCreator = true;
					this.selectedCollections = JSON.parse( query.catalogs );
				} else {
					this.showCatalogCreator = false;
				}
			}

			this.searchParams = decodeQueryParams( query );
			this.assetIds = idsQueryToString( this.searchParams );
		},
		onCollectionChange() {
			this.searchParams.catalogs = this.selectedCollections;
		},
		editItem( item ) {
			this.$router.push( `/assets/${item.asset_id}` );
		},
		editBatch() {
			this.$router.push( { path: '/assets/edit', query: { selectedIds: JSON.stringify( this.selectedAssets ) } } );
		},
		toogleSearch() {
			if ( this.showCatalogCreator ) {
				this.$router.push( '/assets' );
			}
			this.showSearch = true;
			this.showCatalogCreator = false;
			if ( this.searchParams.catalogs ) {
				delete this.searchParams.catalogs;
			}
		},
		toogleCatalogCreator() {
			// This clear the search params if the user has used search refine previously
			this.$router.push( '/assets' );
			this.showSearch = true;
			this.showCatalogCreator = true;
			// Only show asset that belongs to a collection
			this.filterCollection();
		},
		checkCollectionWindowedMirrored( assetCollections ) {
			let result = '';
			for ( let index = 0; index < assetCollections.length; index ++ ) {
				const assetCollection = assetCollections[index];
				if ( assetCollection.window && assetCollection.mirror ) {
					result += 'Window/Mirror';
				} else if ( assetCollection.window ) {
					result += 'Window';
				} else if ( assetCollection.mirror ) {
					result += 'Mirror';
				} else {
					result += 'N/A';
				}
				if ( index !== assetCollections.length - 1 ) {
					result += ', ';
				}
			}
			return result;
		},
		checkFormat( formats ) {
			let result = '';
			for ( let index = 0; index < formats.length; index ++ ) {
				const format = formats[index];
				result += `${format.format_name} - ${format.asset_format_page_count}`;
				if ( index !== formats.length - 1 ) {
					result += ', ';
				}
			}
			return result;
		},
		exportCatalog() {
			let exportAssets = {};
			if ( this.selectedAssets.length === 0 ) {
				exportAssets = this.dataset;
			} else {
				exportAssets = this.dataset.filter( ( asset ) => {
					return this.selectedAssets.includes( asset.asset_id );
				} );
			}
			// try to parse data into readable format
			const output = this.parseCatalog( exportAssets );
			// create a stub element to simulate a download event.
			const anchor = document.createElement( 'a' );
			anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent( output );
			anchor.target = '_blank';
			anchor.download = 'catalogs.tsv';
			anchor.click();
		},
		exportSearch() {
			let exportAssets = {};
			if ( this.selectedAssets.length === 0 ) {
				// Shallow copy
				exportAssets = this.dataset;
			} else {
				// Shallow copy
				exportAssets = this.dataset.filter( ( asset ) => {
					return this.selectedAssets.includes( asset.asset_id );
				} );
			}
			// try to parse data into readable format
			const output = this.parseSearch( exportAssets );
			// create a stub element to simulate a download event.
			const anchor = document.createElement( 'a' );
			anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent( output );
			anchor.target = '_blank';
			anchor.download = 'assets.tsv';
			anchor.click();
		},
		filterCollection() {
			this.loading = true;
			if ( this.showCatalogCreator ) {
				this.dataset = this.dataset.filter( ( asset ) => {
					return (
						asset.collections &&
						asset.collections.some( ( collection ) => {
							return this.selectedCollections === collection.collection_id;
						} )
					);
				} );
			}
			this.loading = false;
		},
		filterAssets( _, search, item ) {
			if ( ! search ) {
				return true;
			}

			const query = search.toLowerCase();
			const textFields = ['asset_lfa_id', 'title', 'title_english', 'language_name', 'program_name'];

			for ( const field of textFields ) {
				if (
					item[field] &&
					String( item[field] )
						.toLowerCase()
						.includes( query )
				) {
					return true;
				}
			}

			const subFields = [
				{ name: 'collections', itemAttributes: ['collection_name'] },
				{ name: 'authors', itemAttributes: ['author_name', 'author_name_english'] },
				{ name: 'editors', itemAttributes: ['editor_name', 'editor_name_english'] },
				{ name: 'illustrators', itemAttributes: ['illustrator_name', 'illustrator_name_english'] },
			];

			for ( const field of subFields ) {
				if ( ! item[field.name] ) {
					continue;
				}

				for ( const subItem of item[field.name] ) {
					for ( const attribute of field.itemAttributes ) {
						if (
							subItem[attribute] &&
							String( subItem[attribute] )
								.toLowerCase()
								.includes( query )
						) {
							return true;
						}
					}
				}
			}

			return false;
		},
		sortData( key, data, sortOrder = 'des' ) {
			const ordered = {};
			let compareFunction = function ( a, b ) {
				return data[b][key] - data[a][key];
			};
			if ( sortOrder === 'asc' ) {
				compareFunction = function ( a, b ) {
					return data[a][key] - data[b][key];
				};
			}
			Object.keys( data )
				.sort( compareFunction )
				.forEach( function ( key ) {
					ordered[key] = data[key];
				} );
			return ordered;
		},
		parseCatalog( catalog ) {
			let output = '';
			catalog = this.sortData( 'order', catalog );
			for ( const index in catalog ) {
				const item = catalog[index];
				const language = this.languages.find( ( language ) => language.language_id === item.language_id );
				const genderRepresentation = this.genderRepresentations.find( ( genderRepresentation ) => genderRepresentation.gender_representation_id === item.gender_representation_id );
				const issuesFocus = this.issuesFocuses.find( ( issuesFocus ) => issuesFocus.issues_focus_id === item.issues_focus_id );
				const contentPillar = this.contentPillars.find( ( contentPillar ) => contentPillar.content_pillar_id === item.content_pillar_id );
				const readingFocus = this.readingFocuses.find( ( readingFocus ) => readingFocus.reading_focus_id === item.reading_focus_id );

				output += `Title (English)\t ${item.title_english ? item.title_english : ''}\n\n`;
				output += `Author Name (English)\t ${item.authors ? item.authors.map( ( a ) => a.author_name_english ).join( ', ' ) : ''}\n\n`;
				output += `Meta Description (English)\t ${item.description_english ? item.description_english : ''}\n\n`;
				output += `Language - Content Pillar\t ${language ? language.language_name : ''}\t${contentPillar ? contentPillar.content_pillar_name : ''}\n `;
				output += `Fiction/Nonfiction - Reading Focus\t ${item.is_fiction ? 'Fiction' : 'Nonfiction'}\t${readingFocus ? readingFocus.reading_focus_description : ''}\n`;
				output += `Window/Mirror - Issue Focus\t ${item.collections ? this.checkCollectionWindowedMirrored( item.collections ) : ''}\t${issuesFocus ? issuesFocus.issues_focus_description : ''}\n`;
				output += `Gender Representation - Format\t ${genderRepresentation ? genderRepresentation.gender_representation_description : ''}\t${item.formats ? this.checkFormat( item.formats ) : ''}\n`;
				output += `ISBN - LFA ID\t ${item.isbn_number ? item.isbn_number : ''}\t${item.asset_lfa_id}\n`;
				output += '\n';
			}
			return output;
		},
		parseSearch( searchResults ) {
			let output = '';
			searchResults = this.sortData( 'asset_lfa_id', searchResults, 'asc' );
			//Headers
			output += 'LFA ID\tBook Title (English)\tAuthor Name(s)\tProgram Name\n';
			for ( const index in searchResults ) {
				const item = searchResults[index];
				const program = this.programs.find( ( program ) => program.program_id === item.program_id );

				output += `${item.asset_lfa_id ? item.asset_lfa_id : ''}\t`;
				output += `${item.title_english ? item.title_english : ''}\t`;
				output += `${item.authors ? item.authors.map( ( a ) => a.author_name_english ).join( ', ' ) : ''}\t`;
				output += `${program ? program.program_name : ''}`;
				output += '\n';
			}
			return output;
		},
		createItem() {
			this.$router.push( '/assets/new' );
		},
		createBatch() {
			this.$router.push( '/assets/new-batch' );
		},
		deleteItem( item ) {
			this.deleteID = item.asset_id;
			this.deleteText = item.title_english;
			this.deleteDialog = true;
		},
		showError( res, err, defaultText ) {
			this.errorText = defaultText;
			if ( err && err.response && err.response.data && err.response.data.error ) {
				this.errorText += ': ' + err.response.data.error;
			} else if ( res && res.data.error ) {
				this.errorText += ': ' + res.data.error;
			} else if ( err && 'Error: Network Error' == err.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.errorDialog = true;
		},
		deactivate() {
			const accel_api = api( this );
			accel_api
				.delete( endPoint + '/' + this.deleteID )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.load();
					} else {
						this.showError( res, null, 'Failed to deactivate the Asset' );
					}
				} )
				.catch( ( err ) => {
					this.showError( null, err, 'Failed to deactivate the Asset' );
				} );
			this.deleteID = 0;
		},
		loadFormResources() {
			this.loadLanguages();
			this.loadFormats();
			this.loadFunders();
			this.loadPrograms();
			this.loadContentPillars();
			this.loadAcquisitionTypes();
			this.loadGenderRepresentations();
			this.loadIssuesFocuses();
			this.loadReadingFocuses();
			this.loadReadingLevels();
			this.loadCollections();
			this.loadPaReadingFocuses();
		},
		async getResource( url, errorMsg, data ) {
			const accel_api = api( this );
			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : null );
			} catch ( err ) {
				this.showError( null, err, errorMsg );
			}

			if ( 401 == res.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res.status || 'success' !== res.data.result ) {
				this.showError( res, null, errorMsg );

				return;
			}
			return res.data.data;
		},
		async load() {
			const accel_api = api( this );
			this.loading = true;
			accel_api
				.get( endPoint, { params: this.searchParams } )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						const data = res.data.data;
						this.dataset = data.map( ( item ) => {
							if ( item.is_fiction == 1 ) {
								item.is_fiction = 'Fiction';
							} else if ( item.is_fiction == 0 ) {
								item.is_fiction = 'Non-Fiction';
							}
							return item;
						} );
						this.filterCollection();
						this.loading = false;
					} else if ( 401 == res.status ) {
						window.console.log( '401: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load the Asset' );
						this.loading = false;
					}
				} )
				.catch( ( err ) => {
					this.showError( null, err, 'Failed to load the Asset' );
					this.loading = false;
				} );
		},
		async loadFormats() {
			this.formats = await this.getResource( '/formats', 'Failed to load the Formats' );
		},
		async loadFunders() {
			this.funders = await this.getResource( '/funders', 'Failed to load the Funders' );
		},
		async loadKeywords() {
			this.keywords = await this.getResource( '/keywords', 'Failed to load the Keywords' );
		},
		async loadLanguages() {
			this.languages = await this.getResource( '/language', 'Failed to load the Languages' );
		},
		async loadPrograms() {
			this.programs = await this.getResource( '/programs', 'Failed to load the Programs' );
		},
		async loadAcquisitionTypes() {
			this.acquisitionTypes = await this.getResource( '/acquisition-types', 'Failed to load the Acquisition Types' );
		},
		async loadIssuesFocuses() {
			this.issuesFocuses = await this.getResource( '/issues-focus', 'Failed to load the Issues Focuses' );
		},
		async loadReadingFocuses() {
			this.readingFocuses = await this.getResource( '/reading-focus', 'Failed to load the Reading Focuses' );
		},
		async loadGenderRepresentations() {
			this.genderRepresentations = await this.getResource( '/gender-representations', 'Failed to load the Gender Representations' );
		},
		async loadContentPillars() {
			this.contentPillars = await this.getResource( '/content-pillars', 'Failed to load the Content Pillars' );
		},
		async loadReadingLevels() {
			this.readingLevels = await this.getResource( '/reading-levels', 'Failed to load the Reading Levels ' );
		},
		async loadCollections() {
			this.collections = await this.getResource( '/collections', 'Failed to load the Collections' );
		},
		async loadPaReadingFocuses() {
			this.paReadingFocuses = await this.getResource( '/pa-reading-focuses', 'Failed to load the Program Aligned Reading Focuses' );
		},
	},
};
</script>

<style>
.v-input--reverse .v-input__slot {
	flex-direction: row-reverse !important;
	justify-content: flex-end !important;
}
.v-input--reverse .v-input__control .v-input__slot .v-input--selection-controls__input {
	margin-right: 40% !important;
}
.v-input--reverse .v-label {
	padding-left: 8px;
}
.v-input--reverse .v-messages {
	display: none !important;
}
</style>
