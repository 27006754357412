<template>
	<div>
		<ac-list-page :headers="headers" :show-search.sync="showSearch" :items="dataset" :items-per-page.sync="itemsPerPage" title="Menu" sort-by="menu_id" @update="editItem($event)" @delete="deleteItem($event)">
			<template v-slot:buttons>
				<v-row class="my-2 mx-0 pa-0">
					<v-col cols="12" md="12" class="py-2">
						<create-menu :parentMenus="parents" :editItem.sync="editItemMenu" @menuLoad="load" v-if="rolePermission.add == 1"></create-menu>
					</v-col>
				</v-row>
			</template>
			<template v-slot:[`item.user_roles`]="{ item }">
				{{ item.user_roles.map((a) => a.role_name).join(', ') }}
			</template>

		</ac-list-page>
		<ac-dialog-deactivate v-model="dialogDelete" :itemId="deleteID" :itemType="'menu'" :itemName="deleteText" @deactivate="deactivate" />
		<ac-dialog-error v-model="errorDialog" :text="errorText" />
	</div>
</template>

<script>
import api from '../../services/api';
import CreateMenu from '@/components/menus/createOrUpdate.vue';
const endPoint = '/menus';

export default {
	name: 'menus',
	components: {
		'create-menu': CreateMenu,
	},
	data: () => {
		return {
			headers: [
				{ text: 'Menu Title', value: 'title', align: 'center', divider: true },
				{ text: 'Menu Icon', value: 'icon_text', align: 'center', divider: true },
				{ text: 'User Role', value: 'user_roles', align: 'center', divider: true },
				{ text: 'Api Resource Route', value: 'route_name', align: 'center', divider: true },
			],
			dataset: [],
			parents: [],
			dialog: false,
			dialogDelete: false,
			errorDialog: false,
			deleteID: 0,
			deleteText: '',
			errorText: '',
			showSearch: false,
			editItemMenu: {},
		};
	},
	watch: {
		deleteDialog( val ) {
			if ( ! val ) {
				this.deleteID = 0;
			}
		},
	},
	computed: {
		itemsPerPage: {
			get() {
				return this.$store.getters.itemsPerList( 'menus' );
			},
			set( val ) {
				this.$store.dispatch( 'SET_ITEMS_PER_LIST', {
					listName: 'menus',
					number: val,
				} );
			},
		},
	},
	created() {
		this.load();
	},
	methods: {
		editItem( item ) {
			this.editItemMenu = item;
		},
		deleteItem( item ) {
			this.deleteID = item.menu_id;
			this.dialogDelete = true;
		},
		showError( res, err, defaultText ) {
			this.errorText = defaultText + ': ';
			if ( err && err.response && err.response.data && err.response.data.error ) {
				this.errorText += err.response.data.error;
			} else if ( res && res.data.error ) {
				this.errorText += res.data.error;
			} else if ( err && 'Error: Network Error' == err.toString() ) {
				this.errorText += ' Unable to connect to the backend API';
			} else {
				this.errorText += ' Unexpected error';
			}
			this.errorDialog = true;
		},
		deactivate() {
			const accel_api = api( this );
			accel_api
				.delete( endPoint + '/' + this.deleteID )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.load();
					} else {
						this.showError( res, null, 'Failed to deactivate the Menu' );
					}
				} )
				.catch( ( err ) => {
					this.showError( null, err, 'Failed to deactivate the Menu' );
				} );
			this.deleteID = 0;
		},
		async load() {
			const accel_api = api( this );
			accel_api
				.get( endPoint )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.dataset = res.data.data;
						this.parents = this.dataset.filter( ( x ) => x.parent_menu_id == null );
					} else if ( 401 == res.status ) {
						window.console.log( '401: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load the Menu List' );
					}
				} )
				.catch( ( err ) => {
					this.showError( null, err, 'Failed to load the Menu' );
				} );
		},
	},
};
</script>

<style scoped></style>
