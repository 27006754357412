











import Vue from 'vue';

export default Vue.extend( {
	name: 'asset-keywords-selection',
	props: {
		keywords: Array,
		languageId: Number,
		value: Array,
	},
	data: () => {
		return {};
	},
	computed: {
		englishKeywords(): Array<any> {
			if ( ! this.keywords ) {
				return [];
			}

			return this.keywords.filter( ( keyword: any ) => {
				return null == keyword.language_id;
			} );
		},
		destLanguageKeywords(): Array<any> {
			if ( ! this.keywords || ! this.languageId ) {
				return [];
			}

			return this.keywords.filter( ( keyword: any ) => {
				return this.languageId == keyword.language_id;
			} );
		},
		englishSelection: {
			get(): Array<any> {
				if ( ! this.value ) {
					return [];
				}

				return this.value.filter( ( keyword: any ) => {
					return null == keyword.language_id;
				} );
			},
			set( val: Array<any> ) {
				this.$emit( 'input', val.concat( this.destLanguageSelection ) );
			},
		},
		destLanguageSelection: {
			get(): Array<any> {
				if ( ! this.value || ! this.languageId ) {
					return [];
				}

				return this.value.filter( ( keyword: any ) => {
					return this.languageId == keyword.language_id;
				} );
			},
			set( val: Array<any> ) {
				this.$emit( 'input', this.englishSelection.concat( val ) );
			},
		},
	},
	watch: {
		languageId( val: any ) {
			// Reset the keywords for the destination language if there is no selected language
			if ( null == val ) {
				this.destLanguageSelection = [];
			}
		},
	},
	methods: {},
} );
