<template>
	<ac-dialog-edit v-model="dialog" :form-title="formTitle" @save="save" @update="update" :is-edit="editedItem.menu_id !== 0">
		<template v-slot:activator="{ on }">
			<v-btn small dark class="createBtn" v-on="on">
				{{ buttonName ? buttonName : 'Create New Record' }}
			</v-btn>
		</template>
		<template v-slot:inputs>
			<v-row>
				<v-col cols="12" sm="12">
					<v-select :items="currentParentMenus" item-value="menu_id" item-text="title" label="Parent Menu" v-model="editedItem.parent_menu_id" dense clearable></v-select>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="12">
					<v-select :items="menuRoutes" item-value="name" item-text="name" label="Menu Route" v-model="editedItem.route_name" dense></v-select>
				</v-col>
			</v-row>
			<v-row>
				<v-col sm="12" md="12">
					<v-select :items="userRoles" item-value="user_role_id" item-text="role_name" label="User Role" v-model="editedItem.user_roles" dense multiple return-object>
					</v-select>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="12">
					<v-text-field v-model="editedItem.title" label="Menu Title" required @blur="!$v.editedItem.title.$touch()" @input="$v.editedItem.title.$touch()" :error-messages="titleNameErrors"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="12">
					<v-text-field v-model="editedItem.icon_text" label="Menu Icon"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="12">
					<v-text-field type="number" v-model="editedItem.display_order" label="Order Number"></v-text-field>
				</v-col>
			</v-row>
		</template>
	</ac-dialog-edit>
</template>
<script lang="js">
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import api from '../../services/api';
const endPoint = '/menus';
const menuURL = '/menus-permissions';
const DefaultItem = {
	menu_id: 0,
	parent_menu_id: null,
	user_role_id: 0,
	title: '',
	route_name: '',
	icon_text: '',
	display_order: 0,
	active: 1,
	userRoles: []
};
export default {
	mixins: [validationMixin],
	name: 'create-menu',
	props: {
		buttonName: String,
		editItem: Object,
		parentMenus: Array
	},
	data: () => {
		return {
			dialog: false,
			dialogDelete: false,
			dialogError: false,
			deleteID: 0,
			deleteText: '',
			errorText: '',
			editedItem: DefaultItem,
			defaultItem: DefaultItem,
			currentItem: DefaultItem,
			menuRoutes: [],
			userRoles: [],
		}
	},
	validations: {
		editedItem :{
			title: { required },
		}
	},
	computed: {
		formTitle() {
			return this.editedItem.menu_id === 0 ? 'New Menu' : 'Edit Menu';
		},
		titleNameErrors() {
			const errors = []
			if ( ! this.$v.editedItem.title.$dirty ) return errors
			! this.$v.editedItem.title.required && errors.push( 'Menu Title is required.' )
			return errors;
		},
		currentParentMenus() {
			if ( ! this.editedItem || null == this.editedItem.menu_id ) {
				return this.parentMenus;
			}

			return this.parentMenus.filter( ( item ) => {
				return item.menu_id !== this.editedItem.menu_id;
			} )
		}
	},
	watch: {
		editItem( item ) {
			this.editedItem = Object.assign( {}, item );
			this.currentItem = Object.assign( {}, this.editedItem );
			this.dialog = true;
		},
		dialog( val ) {
			if ( this.editedItem.menu_id === 0 || ! val ) {
				this.editedItem = Object.assign( {}, this.defaultItem );
			}
		},
		dialogDelete( val ) {
			if ( ! val ) {
				this.deleteID = 0;
			}
		}
	},
	created() {
		this.loadRoutes();
		this.loadRoles();
	},
	methods: {
		deleteItem( item ) {
			this.deleteID = item.menu_id;
			this.deleteText = item.title;
			this.dialogDelete = true;
		},
		showError( res, err, defaultText ) {
			this.errorText = defaultText;
			if ( err && err.response && err.response.data && err.response.data.error ) {
				this.errorText += ': ' + err.response.data.error;
			} else if ( res && res.data.error ) {
				this.errorText += ': ' + res.data.error;
			} else if ( err && 'Error: Network Error' == err.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.dialogError = true;
		},
		update() {
			const accel_api = api( this );
			const updateFields = { menu_id: this.editedItem.menu_id };
			let updated = false;
			for ( const field in this.editedItem ) {
				if ( this.currentItem[field] != this.editedItem[field] ) {
					updateFields[field] = this.editedItem[field];
					updated = true;
				}
			}
			if ( ! updated ) {
				return;
			}
			accel_api.put( endPoint + '/' + updateFields.menu_id, this.editedItem )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						accel_api.get( menuURL )
							.then( res => {
								if ( 200 === res.status && 'success' === res.data.result ) {
									this.$store.dispatch( 'SET_MENU', res.data.data );
									this.$emit( 'menuLoad', res.data.result  );
								} else {
									this.showError( res, null, 'Failed to add the Menu' );
								}
							} )
							.catch( err => {
								this.showError( null, err, 'Failed to add the Menu' );
							} );
						this.$emit( 'menuLoad', res.data.result );
					} else {
						this.showError( res, null, 'Failed to update the Menu ' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to update the Menu' );
				} );
		},
		save() {
			this.$v.$touch();
			if ( this.$v.$invalid ) {
				return;
			}
			const accel_api = api( this );
			const saveFields = Object.assign( {}, this.editedItem );
			accel_api.post( endPoint, saveFields )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						accel_api.get( menuURL )
							.then( res => {
								if ( 200 === res.status && 'success' === res.data.result ) {
									this.$store.dispatch( 'SET_MENU', res.data.data );
									this.$emit( 'menuLoad', res.data.result  );
								} else {
									this.showError( res, null, 'Failed to add the Menu' );
								}
							} )
							.catch( err => {
								this.showError( null, err, 'Failed to add the Menu' );
							} );
						this.$emit( 'menuLoad', res.data.result );
					} else {
						this.showError( res, null, 'Failed to add the Menu' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to add the Menu' );
				} );
		},
		async loadRoutes() {
			this.menuRoutes = this.$router.options.routes;
		},
		async loadRoles() {
			const accel_api = api( this );
			accel_api.get( '/user-roles' )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.userRoles = res.data.data;
					} else if ( 401 == res.status ) {
						window.console.log( '410: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load the user roles' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to load the user roles' );
				} )
		},
	}
}
</script>

<style scoped>
.createBtn {
	background-color: #14234f !important;
	margin: 8px;
}
</style>
