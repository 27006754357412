import { RouteConfig } from 'vue-router'
import Vue from 'vue'
import VueRouter from 'vue-router'
import apiResources  from '@/views/api-resources.vue'
import asset from '@/views/assets/single.vue'
import assets from '@/views/assets/list.vue'
import author from '@/views/authors/single.vue'
import authors from '@/views/authors/list.vue'
import bookProductionModel from '@/views/book-production-model.vue'
import collection from '@/views/collections/single.vue'
import collections from '@/views/collections/list.vue'
import companies from '@/views/companies.vue'
import contentPillars from '@/views/content-pillars.vue'
import contractTypes from '@/views/contract-types.vue'
import contracts from '@/views/contracts/list.vue'
import countries from '@/views/countries.vue'
import countryRestrictions from '@/views/country-restrictions/list.vue'
import dashboard from '@/views/dashboard.vue'
import editors from '@/views/editors.vue'
import formats from '@/views/formats.vue'
import funders from '@/views/funders.vue'
import genderRepresentations from '@/views/gender-representations.vue'
import genders from '@/views/genders.vue'
import illustrator from '@/views/illustrators/single.vue'
import illustrators from '@/views/illustrators/list.vue'
import issuesFocus from '@/views/issues-focus.vue'
import keywords from '@/views/keywords.vue'
import language from '@/views/language.vue'
import login from '@/views/login.vue'
import manuscript from '@/views/manuscript.vue'
import manuscriptFile from '@/views/manuscript-file.vue'
import manuscriptStatus from '@/views/manuscript-status.vue'
import manuscriptType from '@/views/manuscript-type.vue'
import menus from '@/views/menus.vue'
import notFound from '@/views/not-found.vue'
import notifications from '@/views/notifications.vue'
import paReadingFocuses from '@/views/pa-reading-focus/list.vue'
import profile from '@/views/profile.vue'
import programs from '@/views/programs.vue'
import questionsPages from '@/views/questions-pages/list.vue'
import questionsPage from '@/views/questions-pages/single.vue'
import readerCategories from '@/views/reader-categories/list.vue'
import readingFocus from '@/views/reading-focus.vue'
import readingLevels from '@/views/reading-levels.vue'
import resourceRoles from '@/views/api-resource-user-roles.vue'
import subjectAuthorities from '@/views/subject-authorities/list.vue'
import subjects from '@/views/subjects/list.vue'
import topics from '@/views/topics/list.vue'
import userRoles from '@/views/user-roles.vue'
import users from '@/views/users.vue'

Vue.use( VueRouter )

const routes: Array<RouteConfig> = [
	{
		path: '/login',
		name: 'login',
		component: login,
	},
	{
		path: '/',
		name: 'dashboard',
		component: dashboard,
	},
	{
		path: '/users',
		name: 'users',
		component: users,
	},
	{
		path: '/user-roles',
		name: 'user-roles',
		component: userRoles,
	},
	{
		path: '/profile',
		name: 'profile',
		component: profile,
	},
	/*
	{
		path: '/notifications',
		name: 'notifications',
		// route level code-splitting
		// this generates a separate chunk (notifications.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" * / '../views/notifications.vue')
	},
	*/
	{
		path: '/notifications',
		name: 'notifications',
		component: notifications,
	},
	{
		path: '/api-resources',
		name: 'api-resources',
		component: apiResources,
	},
	{
		path: '/api-resource-user-roles',
		name: 'api-resource-user-roles',
		component: resourceRoles,
	},
	{
		path: '/reading-focus',
		name: 'reading-focus',
		component: readingFocus,
	},
	{
		path: '/reading-levels',
		name: 'reading-levels',
		component: readingLevels,
	},
	{
		path: '/language',
		name: 'language',
		component: language,
	},
	{
		path: '/manuscript-type',
		name: 'manuscript-type',
		component: manuscriptType,
	},
	{
		path: '/manuscript-status',
		name: 'manuscript-status',
		component: manuscriptStatus,
	},
	{
		path: '/manuscripts',
		name: 'manuscripts',
		component: manuscript,
	},
	{
		path: '/manuscript-files',
		name: 'manuscript-files',
		component: manuscriptFile,
	},
	{
		path: '/reader-categories',
		name: 'reader-categories',
		component: readerCategories,
	},
	{
		path: '/formats',
		name: 'formats',
		component: formats,
	},
	{
		path: '/issues-focus',
		name: 'issues-focus',
		component: issuesFocus,
	},
	{
		path: '/gender-representations',
		name: 'gender-representations',
		component: genderRepresentations,
	},
	{
		path: '/assets',
		name: 'assets',
		component: assets,
	},
	{
		path: '/assets/:asset_id(\\d+)',
		name: 'asset',
		component: asset,
	},
	{
		path: '/assets/new',
		name: 'asset-new',
		component: asset,
	},
	{
		path: '/assets/new-batch',
		name: 'asset-new-batch',
		component: asset,
	},
	{
		path: '/assets/edit',
		name: 'asset-edit-batch',
		component: asset,
	},
	{
		path: '/funders',
		name: 'funders',
		component: funders,
	},
	{
		path: '/countries',
		name: 'countries',
		component: countries,
	},
	{
		path: '/country-restrictions',
		name: 'country-restrictions',
		component: countryRestrictions,
	},
	{
		path: '/authors',
		name: 'authors',
		component: authors,
	},
	{
		path: '/authors/:author_id(\\d+)',
		name: 'author',
		component: author,
	},
	{
		path: '/authors/new',
		name: 'author-new',
		component: author,
	},
	{
		path: '/illustrators',
		name: 'illustrators',
		component: illustrators,
	},
	{
		path: '/illustrators/:illustrator_id(\\d+)',
		name: 'illustrator',
		component: illustrator,
	},
	{
		path: '/illustrators/new',
		name: 'illustrator-new',
		component: illustrator,
	},
	{
		path: '/collections',
		name: 'collections',
		component: collections,
	},
	{
		path: '/collections/:collection_id(\\d+)',
		name: 'collection',
		component: collection,
	},
	{
		path: '/collections/new',
		name: 'collection-new',
		component: collection,
	},
	{
		path: '/content-pillars',
		name: 'content-pillars',
		component: contentPillars,
	},
	{
		path: '/contract-types',
		name: 'contract-types',
		component: contractTypes,
	},
	{
		path: '/contracts',
		name: 'contracts',
		component: contracts,
	},
	{
		path: '/genders',
		name: 'genders',
		component: genders,
	},
	{
		path: '/programs',
		name: 'programs',
		component: programs,
	},
	{
		path: '/keywords',
		name: 'keywords',
		component: keywords,
	},
	{
		path: '/companies',
		name: 'companies',
		component: companies,
	},
	{
		path: '/editors',
		name: 'editors',
		component: editors,
	},
	{
		path: '/menus',
		name: 'menus',
		component: menus,
	},
	{
		path: '/pa-reading-focuses',
		name: 'pa-reading-focuses',
		component: paReadingFocuses,
	},
	{
		path: '/questions-pages',
		name: 'questions-pages',
		component: questionsPages,
	},
	{
		path: '/questions-pages/:questions_page_id(\\d+)',
		name: 'questions-page',
		component: questionsPage,
	},
	{
		path: '/questions-pages/new',
		name: 'questions-page-new',
		component: questionsPage,
	},
	{
		path: '/subject-authorities',
		name: 'subject-authorities',
		component: subjectAuthorities,
	},
	{
		path: '/subjects',
		name: 'subjects',
		component: subjects,
	},
	{
		path: '/topics',
		name: 'topics',
		component: topics,
	},
	{
		path: '/book-production-models',
		name: 'book-production-models',
		component: bookProductionModel,
	},
	{
		path: '*',
		name: 'notFound',
		component: notFound,
	},
]

const router = new VueRouter( {
	routes
} )

export default router
