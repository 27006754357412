







import { Component, Vue } from 'vue-property-decorator';
import Assets from '@/components/assets/list.vue';

@Component( {
	components: {
		Assets,
	},
} )
export default class AssetsView extends Vue {}
