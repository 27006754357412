








import { Component, Vue } from 'vue-property-decorator';
import UserRoles from '@/components/user-roles.vue';

@Component( {
	components: {
		UserRoles,
	},
} )
export default class UserRolesView extends Vue {}

