

































































import Vue from 'vue';
import draggable from 'vuedraggable';
import api from '@/services/api';
import ReaderCategory from '@/models/reader-category';
import CollectionAssetCard from './collection-asset-card.vue';
import CollectionCategoryAssetSelection from './collection-category-asset-selection.vue';

export default Vue.extend( {
	components: {
		'collection-asset-card': CollectionAssetCard,
		'collection-category-asset-selection': CollectionCategoryAssetSelection,
		draggable,
	},
	name: 'collection-category',
	props: {
		editing: Boolean,
		loading: Boolean,
		value: Object,
	},
	data: () => {
		return {
			editingAssets: false,
			editItems: [] as Array<any>,
			confirmChangeMessage: '',
			confirmDialog: false,
			selectionDialog: false,
		};
	},
	computed: {
		confirmChangesMessage() {
			return 'Are you sure you want to save the changes to the assets?';
		},
	},
	watch: {
		editing( val: boolean ) {
			if ( val && this.editingAssets ) {
				this.cancel();
			}
		},
		value: {
			handler: function ( val: Array<any> ) {
				const newItems = JSON.parse( JSON.stringify( val ) ).assets;
				newItems.sort( ( a: any, b: any ) => a.order - b.order );
				this.editItems = newItems;
			},
			immediate: true,
		},
	},
	created() {
		this.loadResources();
	},
	methods: {
		moveDown( item: ReaderCategory ) {
			this.$emit( 'moveDown', item );
		},
		moveUp( item: ReaderCategory ) {
			this.$emit( 'moveUp', item );
		},
		remove( item: ReaderCategory ) {
			this.$emit( 'remove', item );
		},
		sendError( res: any, err: any, defaultText: any ) {
			this.$emit( 'error', { response: res, error: err, message: defaultText } );
		},
		async getResource( url: string, errorMsg: string, data?: any ) {
			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : undefined );
			} catch ( err ) {
				this.sendError( null, err, errorMsg );

				return;
			}

			if ( 401 == res.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res.status || 'success' !== res.data.result ) {
				this.sendError( res, null, errorMsg );

				return;
			}

			return res.data.data;
		},
		loadResources() {
			// nothing to load here
		},
		cancel() {
			const newItems = JSON.parse( JSON.stringify( this.value ) ).assets;
			newItems.sort( ( a: any, b: any ) => a.order - b.order );
			this.editItems = newItems;

			this.editingAssets = false;
		},
		save() {
			this.$emit( 'input', { ...this.value, assets: this.editItems } );
			this.editingAssets = false;
		},
		updateItemsOrder() {
			this.editItems = this.editItems.map( ( item: any, index: number ) => {
				return {
					...item,
					order: index + 1,
				};
			} );
		},
		addAsset( asset: any ) {
			const { asset_id, asset_lfa_id, title, title_english } = asset;

			this.editItems.unshift( {
				asset_id,
				asset_lfa_id,
				title,
				title_english,
				order: 1,
			} );

			this.updateItemsOrder();
		},
		removeAssetAt( index: number ) {
			this.editItems.splice( index, 1 );

			this.updateItemsOrder();
		},
	},
} );
