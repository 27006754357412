







import { Component, Vue } from 'vue-property-decorator';
import Dashboard from '@/components/dashboard/dashboard.vue';

@Component( {
	components: {
		Dashboard,
	},
} )
export default class DashboardView extends Vue {}
