
import axios from 'axios'

export default ( self: any ) => {
	const authHeaders: any = {}
	if ( self.$store.getters.USER?.user_id && self.$store.getters.USER?.token ) {
		authHeaders['User-ID'] = self.$store.getters.USER?.user_id
		authHeaders['Access-Token'] = self.$store.getters.USER?.token
	}

	return axios.create( {
		baseURL: process.env.VUE_APP_API_URL != null ? process.env.VUE_APP_API_URL : 'http://localhost:7777/v1',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			...authHeaders
		},
		validateStatus: function ( status: number ) {
			return status >= 200;
		}
	} )
}


export const encodeQueryParams = ( params: { [key: string]: any; } ) => {
	const encoded: { [key: string]: any; } = {};
	for ( const key in params ) {
		if ( 'object' === typeof params[key] && Array.isArray( params[key] ) ) {
			// The Vue Router's default encoder for arrays splits arrays in a list of values with the same key. We don't want that.
			if ( params[key].length ) {
				encoded[key] = JSON.stringify( params[key] );
			}
		} else {
			encoded[key] = params[key];
		}
	}

	return encoded;
}

export const decodeQueryParams = ( params: { [key: string]: any; } ) => {
	const decoded: { [key: string]: any; } = {};
	for ( const key in params ) {
		try {
			decoded[key] = JSON.parse( params[key] );
		} catch ( _ ) {
			decoded[key] = params[key];
		}
	}

	return decoded;
}
