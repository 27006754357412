




























import Vue from 'vue';
import assetLfaIdField from '@/components/assets/asset-lfa-id-field.vue';

interface TableDataItem {
	index: number;
	value: number | null;
}

export default Vue.extend( {
	name: 'asset-lfa-ids-list',
	components: {
		'asset-lfa-id-field': assetLfaIdField,
	},
	props: {
		minItems: {
			type: Number,
			default: 1,
		},
		value: Array,
	},
	data: () => {
		return {
			headers: [
				{ text: '', value: 'value', align: 'center', divider: true },
				{ text: '', value: 'actions', align: 'center', sortable: false },
			],
			loading: [] as Array<boolean>,
			editing: [] as Array<boolean>,
			editedValues: [] as Array<number | null>,
			tableData: [] as Array<TableDataItem>,
		};
	},
	computed: {},
	watch: {
		loading( val: Array<boolean> ) {
			this.$emit(
				'loading',
				val.reduce( ( acc, curr ) => acc || curr, false ),
			);
		},
		editing( val: Array<boolean> ) {
			this.$emit(
				'editing',
				val.reduce( ( acc, curr ) => acc || curr, false ),
			);
		},
		value: {
			handler( val: Array<number | null> ) {
				this.editedValues = JSON.parse( JSON.stringify( val ) );
			},
			immediate: true,
		},
		editedValues: {
			handler( val: Array<number | null> ) {
				this.tableData = val.map( ( v, i ) => {
					return {
						index: i,
						value: v,
					};
				} );
				this.loading = val.map( () => false );
				this.editing = val.map( () => false );
			},
			immediate: true,
		},
	},
	methods: {
		onItemEditing( index: number, val: boolean ) {
			const newValues = JSON.parse( JSON.stringify( this.editing ) );
			newValues[index] = val;

			this.editing = newValues;
		},
		onItemLoading( index: number, val: boolean ) {
			const newValues = JSON.parse( JSON.stringify( this.loading ) );
			newValues[index] = val;

			this.loading = newValues;
		},
		onItemInput( index: number, val: number | null ) {
			const newValues = JSON.parse( JSON.stringify( this.editedValues ) );
			newValues[index] = val;

			this.$emit( 'input', newValues );
		},
		addItem( val: number | null ) {
			const newValues = JSON.parse( JSON.stringify( this.editedValues ) );

			newValues.unshift( val );

			this.$emit( 'input', newValues );
		},
		deleteItem( index: number ) {
			const newValues = JSON.parse( JSON.stringify( this.editedValues ) );

			if ( newValues.length > this.minItems ) {
				newValues.splice( index, 1 );
			}

			this.$emit( 'input', newValues );
		},
	},
} );
