<template>
	<v-card>
		<v-toolbar dark flat color="primary" height="44">
			<v-toolbar-title>
				<slot name="title"></slot>
			</v-toolbar-title>

			<v-spacer></v-spacer>

			<v-btn icon @click="close">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-toolbar>

		<v-card-text>
			<v-container>
				<slot name="inputs"></slot>
			</v-container>
		</v-card-text>

		<v-card-actions>
			<slot name="actions">
				<v-spacer></v-spacer>
				<v-btn color="primary" text @click="clear">Clear</v-btn>
				<v-btn color="primary" text @click="search">Search</v-btn>
			</slot>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: 'ac-search-card',
	methods: {
		close() {
			this.$emit( 'close' );
		},
		search() {
			this.$emit( 'search' );
		},
		clear() {
			this.$emit( 'clear' );
		},
	},
};
</script>

<style scoped>
</style>
