














import Vue from 'vue';
import { isEqual } from 'lodash';
import api from '@/services/api';
import Collection from '@/models/collection';
import ReaderCategory from '@/models/reader-category';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';

const endpoint = '/reader-categories';
const getDefaultItem = (): Partial<ReaderCategory> => {
	return {
		reader_category_name: '',
		reader_category_label: '',
		icon: 'personBookOutlined',
		order: 1,
	};
};

export default Vue.extend( {
	mixins: [validationMixin],
	name: 'reader-category-form',
	props: {
		nameError: String,
		handleSave: {
			type: Boolean,
			default: true,
		},
		hideCollection: {
			type: Boolean,
			default: false,
		},
		value: Object,
	},
	data: () => {
		return {
			editedItem: getDefaultItem(),
			loading: false,
			loadingCollections: false,
			collections: [] as Array<Collection>,
		};
	},
	created() {
		this.loadResources();
	},
	validations: {
		editedItem: {
			reader_category_name: { required, maxLength: maxLength( 45 ) },
			reader_category_label: { required, maxLength: maxLength( 45 ) },
			collection_id: { required },
		},
	},
	computed: {
		readerCategory(): ReaderCategory {
			return ( this.value as ReaderCategory ) || getDefaultItem();
		},
		readerCategoryNameErrors(): string[] {
			const errors: string[] = [];
			if ( ! this.$v.editedItem.reader_category_name?.$dirty ) return errors;
			! this.$v.editedItem.reader_category_name.required && errors.push( 'This field is required.' );
			! this.$v.editedItem.reader_category_name.maxLength && errors.push( 'Must be at most 45 characters long' );
			this.nameError && errors.push( this.nameError );
			return errors;
		},
		readerCategoryLabelErrors(): string[] {
			const errors: string[] = [];
			if ( ! this.$v.editedItem.reader_category_label?.$dirty ) return errors;
			! this.$v.editedItem.reader_category_label.required && errors.push( 'This field is required.' );
			! this.$v.editedItem.reader_category_label.maxLength && errors.push( 'Must be at most 45 characters long' );
			return errors;
		},
		collectionIdErrors(): string[] {
			const errors: string[] = [];
			if ( ! this.$v.editedItem.collection_id?.$dirty ) return errors;
			! this.$v.editedItem.collection_id.required && errors.push( 'This field is required.' );
			return errors;
		},
	},
	watch: {
		hideCollection( val: boolean ) {
			if ( ! val ) {
				this.loadResources();
			}
		},
		readerCategory: {
			deep: true,
			immediate: true,
			handler( val ) {
				this.editedItem = JSON.parse( JSON.stringify( val ) );
			},
		},
		editedItem: {
			deep: true,
			handler( val ) {
				this.$emit( 'changed', JSON.stringify( val ) !== JSON.stringify( this.readerCategory ) );
			},
		},
		loading( val ) {
			this.$emit( 'loading', val );
		},
	},
	methods: {
		reset() {
			this.editedItem = JSON.parse( JSON.stringify( this.readerCategory ) );
			this.$v.$reset();
		},
		sendError( res: any, err: any, defaultText: any ) {
			this.$emit( 'error', { response: res, error: err, message: defaultText } );
		},
		sendInput( val: any ) {
			this.$emit( 'input', val );
		},
		async save() {
			this.$v.$touch();
			if ( this.$v.$invalid ) {
				return;
			}

			if ( this.handleSave ) {
				if ( this.readerCategory.reader_category_id ) {
					return this.update();
				} else {
					return this.create();
				}
			} else {
				if ( this.readerCategory.reader_category_id ) {
					this.$emit( 'update', this.editedItem );
				} else {
					this.$emit( 'save', this.editedItem );
				}
			}
		},
		async create() {
			this.loading = true;

			const accel_api = api( this );
			let res;
			try {
				res = await accel_api.post( endpoint, this.editedItem );

				if ( 200 !== res.status || 'success' !== res.data.result ) {
					this.loading = false;
					this.sendError( res, null, 'Failed to save the Reader Category' );
					return;
				}
			} catch ( err ) {
				this.sendError( null, err, 'Failed to save the Reader Category' );
				return;
			} finally {
				this.loading = false;
			}

			this.sendInput( res.data.data?.length ? res.data.data[0] : [] );
		},
		async update() {
			this.loading = true;

			const accel_api = api( this );
			const updatedFields: any = {};
			let needsUpdate = false;
			let field: keyof ReaderCategory;
			for ( field in this.editedItem ) {
				if ( ! isEqual( this.readerCategory[field], this.editedItem[field] ) ) {
					updatedFields[field] = this.editedItem[field];
					needsUpdate = true;
				}
			}
			if ( ! needsUpdate ) {
				// No update to process, just reset the original values
				this.loading = false;
				this.reset();
				this.sendInput( { ...this.editedItem } );
				return;
			}
			let res;
			try {
				res = await accel_api.put( `${endpoint}/${this.editedItem.reader_category_id}`, updatedFields );

				if ( 200 !== res.status || 'success' !== res.data.result ) {
					this.loading = false;
					this.sendError( res, null, 'Failed to save the Reader Category' );
					return;
				}
			} catch ( err ) {
				this.sendError( null, err, 'Failed to save the Reader Category' );
				return;
			} finally {
				this.loading = false;
			}

			this.sendInput( { ...this.editedItem } );
		},
		async getResource( url: string, errorMsg: string, data?: any ) {
			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : undefined );
			} catch ( err ) {
				this.sendError( null, err, errorMsg );

				return;
			}

			if ( 401 == res.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res.status || 'success' !== res.data.result ) {
				this.sendError( res, null, errorMsg );

				return;
			}

			return res.data.data;
		},
		loadResources() {
			if ( ! this.hideCollection ) {
				this.loadCollections();
			}
		},
		async loadCollections() {
			this.loadingCollections = true;
			this.collections = await this.getResource( '/collections', 'Failed to load the Collections' );
			this.loadingCollections = false;
		},
	},
} );
