
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import IMenu from '@/models/menu';

import IUser from '@/models/user';
import IUserRolePermission from '@/models/user-role-permission';

Vue.use( Vuex );

type ItemsPerListNumber = 10 | 25 | 50 | - 1;

interface InfoMessage {
	dialog?: boolean;
	message: string;
	persistent?: boolean;
}

interface ItemsPerListPayload {
	listName: string;
	number: ItemsPerListNumber;
}

interface AppState {
	user?: IUser;
	menu?: IMenu;
	userRolePermission?: IUserRolePermission;
	infoMessage: InfoMessage | null;
	itemsPerListMap: {[key: string]: ItemsPerListNumber}
}

const state: AppState = {
	user: undefined,
	menu: undefined,
	userRolePermission: undefined,
	infoMessage: null,
	itemsPerListMap: {},
};

const getters = {
	USER: ( state: AppState ) => {
		return state.user;
	},
	MENU : ( state: AppState ) => {
		return state.menu;
	},
	UserRolePermission : ( state: AppState ) => {
		return state.userRolePermission;
	},
	userInitials: ( state: AppState ) => {
		return state.user ? state.user.fullname.split( ' ' ).slice( 0, 2 ).map( ( n ) => {
			return n[0] ? String( n[0] ).toUpperCase() : '';
		} ).join( '' ) : '';
	},
	InfoMessage: ( state: AppState ) => {
		return state.infoMessage;
	},
	itemsPerList: ( state: AppState ) => ( listName: string ): ItemsPerListNumber => {
		const userSettingName = `${state.user?.user_id || 'global'}_${listName}`;

		if ( state.itemsPerListMap[userSettingName] != null ) {
			return state.itemsPerListMap[userSettingName];
		}

		return - 1;
	}
};

const mutations = {
	SET_USER: ( state: AppState, payload: any ) => {
		const storage = localStorage || sessionStorage;
		if ( null === payload ) {
			storage.removeItem( 'user' );
		} else {
			storage.setItem( 'user', payload );
		}
		state.user = payload;
	},
	GET_USER: ( state: AppState ) => {
		if ( null !== state.user ) {
			return state.user;
		}
		const storage = localStorage || sessionStorage;
		const storedUser = storage.getItem( 'user' ) || ''
		const user: IUser = JSON.parse( storedUser );

		if ( user ) {
			state.user = user;
			return user;
		}
		return null;
	},
	SET_MENU : ( state: AppState, payload: any ) => {
		const storage = localStorage || sessionStorage;
		if ( null === payload ) {
			storage.removeItem( 'menu' );
		} else {
			storage.setItem( 'menu', payload );
		}
		state.menu = payload;
	},
	GET_MENU : ( state: AppState ) => {
		if ( null !== state.menu ) {
			return state.menu;
		}
		const storage = localStorage || sessionStorage;
		const storedMenu = storage.getItem( 'menu' ) || ''
		const menu: IMenu = JSON.parse( storedMenu );

		if ( menu ) {
			state.menu = menu;
			return menu;
		}
		return null;
	},
	SET_USER_ROLE_PERMISSION : ( state: AppState, payload: any ) => {
		const storage = localStorage || sessionStorage;
		if ( null === payload ) {
			storage.removeItem( 'userRolePermission' );
		} else {
			storage.setItem( 'userRolePermission', payload );
		}
		state.userRolePermission = payload;
	},
	GET_USER_ROLE_PERMISSION : ( state: AppState ) => {
		if ( null !== state.userRolePermission ) {
			return state.userRolePermission;
		}
		const storage = localStorage || sessionStorage;
		const storedUserRolePermission = storage.getItem( 'userRolePermission' ) || ''
		const userRolePermission: IUserRolePermission = JSON.parse( storedUserRolePermission );

		if ( userRolePermission ) {
			state.userRolePermission = userRolePermission;
			return userRolePermission;
		}
		return null;
	},
	SHOW_INFO_MESSAGE : ( state: AppState, payload: InfoMessage ) => {
		state.infoMessage = payload;
	},
	DISMISS_INFO_MESSAGE : ( state: AppState ) => {
		state.infoMessage = null;
	},
	SET_ITEMS_PER_LIST: ( state: AppState, payload: ItemsPerListPayload ) => {
		const { listName, number } = payload;
		const userSettingName = `${state.user?.user_id || 'global'}_${listName}`;

		state.itemsPerListMap = {
			...state.itemsPerListMap,
			[userSettingName]: number,
		};
	}
};

const actions = {
	SET_USER: ( context: any, payload: any ) => {
		context.commit( 'SET_USER', payload );
	},
	GET_USER: ( context: any, payload: any ) => {
		context.commit( 'GET_USER', payload );
	},
	SET_MENU : ( context: any, payload: any ) => {
		context.commit( 'SET_MENU', payload );
	},
	GET_MENU : ( context: any, payload: any ) => {
		context.commit( 'GET_MENU', payload );
	},
	SET_USER_ROLE_PERMISSION : ( context: any, payload: any ) => {
		context.commit( 'SET_USER_ROLE_PERMISSION', payload );
	},
	GET_USER_ROLE_PERMISSION : ( context: any, payload: any ) => {
		context.commit( 'GET_USER_ROLE_PERMISSION', payload );
	},
	SHOW_INFO_MESSAGE: ( context: any, payload: InfoMessage ) => {
		context.commit( 'SHOW_INFO_MESSAGE', payload );
	},
	DISMISS_INFO_MESSAGE: ( context: any ) => {
		context.commit( 'DISMISS_INFO_MESSAGE' );
	},
	SET_ITEMS_PER_LIST: ( context: any, payload: ItemsPerListPayload ) => {
		context.commit( 'SET_ITEMS_PER_LIST', payload );
	},
};

export default new Vuex.Store( {
	state,
	getters,
	mutations,
	actions,
	plugins: [createPersistedState()]
} );
