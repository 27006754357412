








import { Component, Vue } from 'vue-property-decorator';
import ManuscriptFile from '@/components/manuscript-file.vue';

@Component( {
	components: {
		ManuscriptFile,
	},
} )

export default class ManuscriptFileView extends Vue {}

