
































import Vue from 'vue';
import api from '../../services/api';
import Collection from '@/models/collection';
const endPoint = '/collections';

export default Vue.extend( {
	name: 'collections',
	components: {},
	data: () => {
		return {
			headers: [
				{ text: 'Collection Name', value: 'collection_name' },
				{ text: 'Collection Reader Name', value: 'collection_reader_name' },
			],
			dataset: [],
			dialogDelete: false,
			dialogError: false,
			deleteID: 0,
			deleteText: '',
			errorText: '',
			editItemCollection: {},
		};
	},
	computed: {
		itemsPerPage: {
			get() {
				return this.$store.getters.itemsPerList( 'collections' );
			},
			set( val ) {
				this.$store.dispatch( 'SET_ITEMS_PER_LIST', {
					listName: 'collections',
					number: val,
				} );
			},
		},
	},
	watch: {
		dialogDelete( val ) {
			if ( ! val ) {
				this.deleteID = 0;
			}
		},
	},
	created() {
		this.load();
	},
	methods: {
		editItem( item: Collection ) {
			this.$router.push( `/collections/${item.collection_id}` );
		},
		createItem() {
			this.$router.push( '/collections/new' );
		},
		deleteItem( item: Collection ) {
			this.deleteID = item.collection_id!;
			this.deleteText = item.collection_name;
			this.dialogDelete = true;
		},
		showError( res: any, err: any, defaultText: string ) {
			this.errorText = defaultText;
			if ( err && err.response && err.response.data && err.response.data.error ) {
				this.errorText += ': ' + err.response.data.error;
			} else if ( res && res.data.error ) {
				this.errorText += ': ' + res.data.error;
			} else if ( err && 'Error: Network Error' == err.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.dialogError = true;
		},
		deactivate( id: number ) {
			const accel_api = api( this );
			accel_api
				.delete( endPoint + '/' + id )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.load();
					} else {
						this.showError( res, null, 'Failed to deactivate the Collection' );
					}
				} )
				.catch( ( err ) => {
					this.showError( null, err, 'Failed to deactivate the Collection' );
				} );
			this.deleteID = 0;
		},
		async load() {
			const accel_api = api( this );
			accel_api
				.get( endPoint )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.dataset = res.data.data;
					} else if ( 401 == res.status ) {
						window.console.log( '401: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load the Collection' );
					}
				} )
				.catch( ( err ) => {
					this.showError( null, err, 'Failed to load the Collection' );
				} );
		},
	},
} );
