






import { Component, Vue } from 'vue-property-decorator';
import QuestionsPages from '@/components/questions-pages/list.vue';
@Component( {
	components: {
		QuestionsPages,
	},
} )
export default class QuestionsPagesView extends Vue {}
