







































import Vue from 'vue';
import api from '@/services/api';
import DataItem from './data-item.vue';
import GlobalSearchBar from './global-search-bar.vue';

interface DashboardDataItem {
	title: string;
	text: string;
	route?: string;
}

export default Vue.extend( {
	name: 'dashboard',
	data: function () {
		return {
			assetsStats: null as any,
			errorDialog: false,
			errorText: '',
			loadingStats: true,
		};
	},
	components: {
		DataItem,
		GlobalSearchBar,
	},
	computed: {
		dataItems(): DashboardDataItem[] {
			const { assets_total, assets_published, assets_in_flight, assets_pre_publishing } = this.assetsStats || {};

			return [
				{
					title: 'Total Titles',
					text: null != assets_total ? String( assets_total ) : '-',
					route: null != assets_total ? '/assets' : undefined,
				},
				{
					title: 'Published Titles',
					text: null != assets_published ? String( assets_published ) : '-',
					route: null != assets_published ? '/assets?manuscripts_done=%5B%7B"manuscript_status_id"%3A18,"manuscript_done"%3Atrue%7D%5D' : undefined,
				},
				{
					title: 'In-Flight Titles',
					text: null != assets_in_flight ? String( assets_in_flight ) : '-',
					route: null != assets_in_flight ? '/assets?manuscripts_done=%5B%7B"manuscript_status_id"%3A18,"manuscript_done"%3Afalse%7D%5D&in_flight_approved=1' : undefined,
				},
				{
					title: 'Pre-Publishing Titles',
					text: null != assets_pre_publishing ? String( assets_pre_publishing ) : '-',
					route: null != assets_pre_publishing ? '/assets?manuscripts_done=%5B%7B"manuscript_status_id"%3A18,"manuscript_done"%3Afalse%7D%5D&in_flight_approved=0' : undefined,
				},
			];
		},
	},
	created() {
		this.loadStats();
	},
	methods: {
		showError( res: any, err: any, defaultText: string ) {
			this.errorText = defaultText;
			if ( err && err.response && err.response.data && err.response.data.error ) {
				this.errorText += ': ' + err.response.data.error;
			} else if ( res && res.data.error ) {
				this.errorText += ': ' + res.data.error;
			} else if ( err && 'Error: Network Error' == err.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.errorDialog = true;
		},
		async getResource( url: string, errorMsg: string, data?: any ) {
			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : undefined );
			} catch ( err ) {
				this.showError( null, err, errorMsg );
			}

			if ( 401 == res?.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res?.status || 'success' !== res.data.result ) {
				this.showError( res, null, errorMsg );

				return;
			}

			return res.data.data;
		},
		async loadStats() {
			this.loadingStats = true;
			this.assetsStats = await this.getResource( '/assets/stats', 'Failed to load the Reading Levels' );
			this.loadingStats = false;
		},
	},
} );
