<template>
	<v-data-table :headers="headers" :items="dataset" sort-by="editor_name" class="elevation-3" dense>
		<template v-slot:top>
			<v-toolbar flat color="white">
				<v-toolbar-title>Editors</v-toolbar-title>
				<v-spacer></v-spacer>
				<create-editor :editItem="editItemEditor" @editorLoad="load" v-if="rolePermission.add == 1"></create-editor>
				<ac-dialog-deactivate v-model="dialogDelete" :itemId="deleteID" :itemType="'Editor'" :itemName="deleteText" @deactivate="deactivate" />
				<ac-dialog-error v-model="dialogError" :text="errorText" />
			</v-toolbar>
		</template>
		<template v-slot:item="{ item }">
			<tr @dblclick="editItem(item)">
				<td>{{ item.fullname }}</td>
				<td>{{ item.editor_first_name }}</td>
				<td>{{ item.editor_name }}</td>
				<td>{{ item.editor_name_english }}</td>
				<td>{{ item.gender_type }}</td>
				<td>{{ item.email }}</td>
				<td>{{ item.phone }}</td>
				<td>{{ item.address1 }}</td>
				<td>{{ item.address2 }}</td>
				<td>{{ item.state }}</td>
				<td>{{ item.post_code }}</td>
				<td>{{ item.country_name }}</td>
				<td width="80">
					<v-icon style="padding: 20 !important;" @click="editItem(item)">
						mdi-pencil
					</v-icon>
					<v-icon style="padding: 20 !important;" @click="deleteItem(item)">
						mdi-delete-circle
					</v-icon>
				</td>
			</tr>
		</template>
	</v-data-table>
</template>

<script lang="js">
import CreateEditor from '@/components/editors/createOrUpdate.vue';
import api from '../../services/api';
const endPoint = '/editors';
export default {
	name: 'editors',
	components: {
		'create-editor': CreateEditor
	},
	data: () => {
		return {
			headers: [
				{ text: 'User Name', value: 'fullname' },
				{ text: 'First Name', value: 'editor_first_name' },
				{ text: 'Name', value: 'editor_name' },
				{ text: 'Name (English)', value: 'editor_name_english' },
				{ text: 'Gender', value: 'gender_type' },
				{ text: 'Email', value: 'email' },
				{ text: 'Phone', value: 'phone' },
				{ text: 'Address Line 1', value: 'address1' },
				{ text: 'Address Line 2', value: 'address2' },
				{ text: 'State', value: 'state' },
				{ text: 'Postcode', value: 'post_code' },
				{ text: 'Country Name', value: 'country_name' },
				{ text: '', value: 'action', sortable: false },
			],
			dataset: [],
			dialog: false,
			dialogDelete: false,
			dialogError: false,
			deleteID: 0,
			deleteText: '',
			errorText: '',
			editItemEditor: {}
		}
	},
	watch: {
		dialog( val ) {
			if ( this.editedItem.editor_id === 0 || ! val ) {
				this.editedItem = Object.assign( {}, this.defaultItem );
			}
		},
		dialogDelete( val ) {
			if ( ! val ) {
				this.deleteID = 0;
			}
		}
	},
	created() {
		this.load();
	},
	methods: {
		editItem( item ) {
			this.editItemEditor = item;
		},
		deleteItem( item ) {
			this.deleteID = item.editor_id;
			this.deleteText = item.editor_name;
			this.dialogDelete = true;
		},
		showError( res, err, defaultText ) {
			this.errorText = defaultText;
			if ( err && err.response && err.response.data && err.response.data.error ) {
				this.errorText += ': ' + err.response.data.error;
			} else if ( res && res.data.error ) {
				this.errorText += ': ' + res.data.error;
			} else if ( err && 'Error: Network Error' == err.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.dialogError = true;
		},
		deactivate( id ) {
			const accel_api = api( this );
			accel_api.delete( endPoint + '/' + id )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.load();
					} else {
						this.showError( res, null, 'Failed to deactivate the Editor' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to deactivate the Editor' );
				} );
			this.deleteID = 0;
		},
		async load() {
			const accel_api = api( this );
			accel_api.get( endPoint )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.dataset = res.data.data;
					} else if ( 401 == res.status ) {
						window.console.log( '401: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load the Editor' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to load the Editor' );
				} )
		}
	}
}
</script>

<style scoped></style>
