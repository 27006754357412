







import { Component, Vue } from 'vue-property-decorator';
import Author from '@/components/authors/single.vue';

@Component( {
	components: {
		Author,
	},
} )
export default class AuthorView extends Vue {}
