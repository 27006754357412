











import Vue from 'vue';
import api from '@/services/api';
import FileInfo from '@/models/file-info';

export default Vue.extend( {
	name: 'ac-file-upload',
	props: {
		accept: String,
		dense: Boolean,
		flat: Boolean,
		fileAttributeName: {
			type: String,
			default: 'file',
		},
		ownerId: Number,
		resourceName: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			default: 'Select a file',
		},
		value: Number,
	},
	data: () => {
		return {
			fileInfo: null as FileInfo | null,
			loadingFileInfo: false,
			uploadedFileInfo: null as FileInfo | null,
			uploadingFile: false,
			stagedFileInfo: null as FileInfo | null,
		};
	},
	computed: {
		infoUrl(): string | undefined {
			if ( ! this.ownerId ) {
				return undefined;
			}

			return `${this.resourceName}/${this.ownerId}/${this.fileAttributeName}`;
		},
		downloadUrl(): string | undefined {
			if ( ! this.ownerId || ! this.value ) {
				return undefined;
			}

			return `${this.resourceName}/${this.ownerId}/${this.fileAttributeName}/download`;
		},
		uploadUrl(): string | undefined {
			return this.getUploadUrl( this.ownerId );
		},
		showPreview(): boolean {
			return this.loadingFileInfo;
		},
		enableDownload(): boolean {
			return null != this.fileInfo && ! this.uploadingFile && ! this.uploadedFileInfo;
		},
		enableDelete(): boolean {
			return null != this.currentFileInfo && ! this.uploadingFile;
		},
		currentFileInfo(): any {
			return this.stagedFileInfo || this.uploadedFileInfo || this.fileInfo;
		},
	},
	watch: {
		infoUrl: {
			handler: function () {
				this.loadFileInfo();
			},
			immediate: true,
		},
	},
	methods: {
		getUploadUrl( ownerId?: string | number ): string | undefined {
			if ( ! ownerId ) {
				return undefined;
			}

			return `${this.resourceName}/${ownerId}/${this.fileAttributeName}/upload`;
		},
		async getResource( url: any, errorMsg: any, data?: any ) {
			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : undefined );
			} catch ( err ) {
				this.sendError( null, err, errorMsg );
				return;
			}

			if ( 401 == res.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res.status || 'success' !== res.data.result ) {
				this.sendError( res, null, errorMsg );

				return;
			}

			return res.data.data;
		},
		sendError( res: any, err: any, defaultText: any ) {
			this.$emit( 'error', { response: res, error: err, message: defaultText } );
		},
		async loadFileInfo() {
			this.fileInfo = null;

			if ( ! this.infoUrl ) {
				return;
			}

			this.loadingFileInfo = true;

			const res = await this.getResource( this.infoUrl, 'Failed to load the File' );
			if ( res ) {
				this.fileInfo = res;
			}

			this.loadingFileInfo = false;
		},
		async download() {
			if ( ! this.downloadUrl ) {
				return;
			}

			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( this.downloadUrl );

				if ( 200 !== res.status ) {
					this.sendError( res, null, 'Failed to download the file' );
				}

				const url = res.data.presigned_url
				const link = document.createElement( 'a' );
				link.href = url;
				link.setAttribute( 'download', res.data.file_name );
				document.body.appendChild( link );
				link.click();
				link.remove();
			} catch ( err ) {
				this.sendError( null, err, 'Failed to download the file' );
			}
		},
		_upload( event: FileInfo | null ) {
			this.stagedFileInfo = event ? { ...event } : null;
			this.upload();
		},
		async upload( ownerId?: number ): Promise<number | null> {
			if ( null == this.stagedFileInfo?.file ) {
				return null;
			}

			const uploadUrl = ownerId ? this.getUploadUrl( ownerId ) : this.uploadUrl;

			if ( ! uploadUrl ) {
				return null;
			}

			const accel_api = api( this );

			this.uploadingFile = true;

			let res;
			try {
				res = await accel_api.post( uploadUrl, { file_name: this.stagedFileInfo.file.name, size: this.stagedFileInfo.file.size } );

				if ( 200 !== res.status || 'success' !== res.data.result ) {
					this.sendError( res, null, 'Failed to upload the file' );
					return null;
				}

				this.uploadedFileInfo = { ...res.data };
				await fetch( res.data.presigned_url, { method: 'PUT', body: this.stagedFileInfo.file } );
			} catch ( err ) {
				this.sendError( null, err, 'Failed to upload the file' );
				return null;
			} finally {
				this.stagedFileInfo = null;
				this.uploadingFile = false;
			}

			if ( null != res.data.insertId ) {
				this.$emit( 'input', res.data.insertId );
				return res.data.insertId;
			} else {
				return null;
			}
		},
		remove() {
			this.stagedFileInfo = null;
			this.uploadedFileInfo = null;
			this.fileInfo = null;
			this.$emit( 'input', null );
		},
		async finalize() {
			this.uploadedFileInfo = null;
			await this.loadFileInfo();
		},
	},
} );
