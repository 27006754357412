







import { Component, Vue } from 'vue-property-decorator';
import Illustrator from '@/components/illustrators/single.vue';

@Component( {
	components: {
		Illustrator,
	},
} )
export default class IllustratorView extends Vue {}
