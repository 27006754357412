<template>
  <v-dialog v-model="show" max-width="450px" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Error</span>
      </v-card-title>
      <v-card-text>
        <v-alert border="bottom" color="warning" elevation="1">
          {{ text }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" width="100" @click="close">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="js">
export default {
	name: 'ac-dialog-deactivate',
	props: {
		value: Boolean,
		text: String,
	},
	computed: {
		show: {
			get() {
				return this.value
			},
			set( value ) {
				this.$emit( 'input', value );
			}
		},
	},
	methods: {
		close() {
			this.show = false;
		},
	}
};
</script>

<style scoped>
</style>
