






import { Component, Vue } from 'vue-property-decorator';
import Programs from '@/components/programs.vue';

@Component( {
	components: {
		Programs,
	},
} )
export default class ProgramsView extends Vue {}
