






import { Component, Vue } from 'vue-property-decorator';
import Gender from '@/components/genders.vue';

@Component( {
	components: {
		Gender,
	},
} )
export default class GenderView extends Vue {}
