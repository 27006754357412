


























import GlobalMixin from '@/mixins/globalMixin';
import contentDisposition from 'content-disposition';

interface ExportAttribute {
	title: string;
	key: string;
	selected: boolean;
}

const getDefaultAttributes = (): Array<ExportAttribute> => [
	{ title: 'LFA ID', key: 'asset_lfa_id', selected: true },
	{ title: 'Parent', key: 'parent_asset_lfa_id', selected: true },
	{ title: 'ISBN Number', key: 'isbn_number', selected: true },
	{ title: 'Title (English)', key: 'title_english', selected: true },
	{ title: 'Title (original)', key: 'title', selected: true },
	{ title: 'Description (English)', key: 'description_english', selected: true },
	{ title: 'Description (original)', key: 'description', selected: true },
	{ title: 'Authors (English name)', key: 'authors', selected: false },
	{ title: 'Editors (English name)', key: 'editors', selected: false },
	{ title: 'Illustrators (English name)', key: 'illustrators', selected: false },
	{ title: 'Notes', key: 'note', selected: false },
	{ title: 'Target Publish Date', key: 'target_publish_date', selected: false },
	{ title: 'Language', key: 'language_name', selected: false },
	{ title: 'Acquisition Type', key: 'acquisition_type_name', selected: false },
	{ title: 'Content Pillar', key: 'content_pillar_name', selected: false },
	{ title: 'Issues Focus', key: 'issues_focus_description', selected: false },
	{ title: 'Reading Focus', key: 'reading_focus_description', selected: false },
	{ title: 'Reading Level', key: 'reading_level_name', selected: false },
	{ title: 'Funder', key: 'funder_name', selected: false },
	{ title: 'Program', key: 'program_name', selected: false },
	{ title: 'Gender Representation', key: 'gender_representation_description', selected: false },
	{ title: 'Book Production Model', key: 'book_production_model_name', selected: false },
	{ title: 'Fiction / Non Fiction', key: 'is_fiction', selected: false },
	{ title: 'BTT Potential', key: 'is_books_that_travel', selected: false },
	{ title: 'Listed on LFA ecommerce site', key: 'is_commerce_site', selected: false },
	{ title: 'In-Flight Approved', key: 'in_flight_approved', selected: false },
	{ title: 'Collections', key: 'collections', selected: false },
	{ title: 'Formats', key: 'formats', selected: false },
	{ title: 'Keywords', key: 'keywords', selected: false },
	// { title: '', key: 'reader_categories', selected: false },
	// { title: '', key: 'subjects', selected: false },
	{ title: 'Derivatives', key: 'derivatives', selected: false },
];

export default GlobalMixin.extend( {
	name: 'asset-list-export-dialog',
	props: {
		value: {
			type: Array,
			default: [],
		},
	},
	data() {
		return {
			allSelected: false,
			attributes: getDefaultAttributes(),
			attributesErrors: [] as Array<string>,
			dialog: false,
			loading: false,
			type: 'csv',
		};
	},
	computed: {
		selectedAttributes(): Array<ExportAttribute> {
			return this.attributes.filter( ( attr ) => attr.selected );
		},
	},
	watch: {
		selectedAttributes() {
			this.attributesErrors = [];
		},
	},
	methods: {
		onSelectAll( val: boolean ) {
			for ( const attr of this.attributes ) {
				attr.selected = val;
			}
		},
		async exportAssets() {
			if ( this.selectedAttributes.length === 0 ) {
				this.attributesErrors = ['You need to select at least one attribute'];
				return;
			}

			this.loading = true;

			const params: any = {
				fields: this.selectedAttributes.map( ( attr ) => attr.key ),
				type: this.type,
			};

			if ( this.value.length > 0 ) {
				params.asset_id = this.value;
			}

			const res = await this.getApiDownload( '/assets/export', 'Error while exporting the selection', params );

			if ( ! res ) {
				// GlobalMixin.getApiDownload already handles sending error events.
				this.loading = false;
				return;
			}

			let fileName = 'downloaded-file';
			try {
				fileName = contentDisposition.parse( res.headers['content-disposition'] ).parameters.filename || 'downloaded-file';
			} catch ( e ) {
				// Ignore malformed content-disposition headers
			}

			const url = window.URL.createObjectURL( new Blob( [res.data] ) );
			const link = document.createElement( 'a' );
			link.href = url;
			link.setAttribute( 'download', fileName );
			document.body.appendChild( link );
			link.click();
			link.remove();

			this.loading = false;
			this.dialog = false;
		},
	},
} );
