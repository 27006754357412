








import { Component, Vue } from 'vue-property-decorator';
import GenderRepresentation from '@/components/gender-representations.vue';

@Component( {
	components: {
		GenderRepresentation,
	},
} )

export default class GenderRepresentationView extends Vue {}

