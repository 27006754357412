








import { Component, Vue } from 'vue-property-decorator';
import Countries from '@/components/countries.vue';

@Component( {
	components: {
		Countries,
	},
} )

export default class CountryView extends Vue {}

