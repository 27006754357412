














import Vue from 'vue';

export default Vue.extend( {
	name: 'ac-dialog-alert',
	props: {
		closeTitle: {
			type: String,
			default: 'Ok',
		},
		message: {
			type: String,
			default: '',
		},
		value: Boolean,
	},
	computed: {
		show: {
			get(): boolean {
				return this.value;
			},
			set( value: boolean ) {
				this.$emit( 'input', value );
			},
		},
	},
	methods: {
		onClose() {
			this.$emit( 'input', false );
		},
	},
} );
