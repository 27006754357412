



















import Vue from 'vue';
import api from '@/services/api';
import Collection from '@/models/collection';
import CountryRestriction from '@/models/country-restriction';
import CreateCountryRestriction from './createOrUpdate.vue';
import { getName } from '@/helpers/country-codes';

const endpoint = '/country-restrictions';

export default Vue.extend( {
	name: 'country-restrictions',
	components: {
		'create-country-restriction': CreateCountryRestriction,
	},
	data: () => {
		return {
			headers: [
				{ text: 'Country', value: 'countryName', align: 'center', divider: true },
				{ text: 'Restriction', value: 'restriction_name', align: 'center', divider: true },
				{ text: 'Collections', value: 'collections', align: 'center', divider: true },
			],
			loading: false,
			dataset: [] as Array<CountryRestriction>,
			dialogDelete: false,
			dialogError: false,
			deleteID: 0,
			deleteText: '',
			errorText: '',
			editItemCountryRestriction: {},
		};
	},
	computed: {
		enrichedDataset(): Array<CountryRestriction | { countryName: string }> {
			return this.dataset.map( ( item ) => {
				return {
					...item,
					countryName: `${getName( item.country_code ) || 'Unknown'} - (${item.country_code})`,
				};
			} );
		},
		itemsPerPage: {
			get() {
				return this.$store.getters.itemsPerList( 'countryRestrictions' );
			},
			set( val ) {
				this.$store.dispatch( 'SET_ITEMS_PER_LIST', {
					listName: 'countryRestrictions',
					number: val,
				} );
			},
		},
	},
	watch: {
		dialogDelete( val ) {
			if ( ! val ) {
				this.deleteID = 0;
			}
		},
	},
	created() {
		this.load();
		this.loadResources();
	},
	methods: {
		editItem( item: any ) {
			this.editItemCountryRestriction = item;
		},
		deleteItem( item: any ) {
			this.deleteID = item.country_restriction_id;
			this.deleteText = item.country_code;
			this.dialogDelete = true;
		},
		showError( event: { response?: any; error?: any; message: string } ) {
			const { response, error, message } = event;

			this.errorText = message;
			if ( error && error.response && error.response.data && error.response.data.error ) {
				this.errorText += ': ' + error.response.data.error;
			} else if ( response && response.data.error ) {
				this.errorText += ': ' + response.data.error;
			} else if ( error && 'Error: Network Error' == error.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.dialogError = true;
		},
		sortCollections( a: Collection, b: Collection ): number {
			if ( a.collection_name < b.collection_name ) {
				return - 1;
			}
			if ( a.collection_name > b.collection_name ) {
				return 1;
			}
			return 0;
		},
		deactivate() {
			const accel_api = api( this );
			accel_api
				.delete( endpoint + '/' + this.deleteID )
				.then( ( res ) => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.load();
					} else {
						this.showError( { response: res, error: null, message: 'Failed to deactivate the Country Restriction' } );
					}
				} )
				.catch( ( err ) => {
					this.showError( { response: null, error: err, message: 'Failed to deactivate the Country Restriction' } );
				} );
			this.deleteID = 0;
		},
		async getResource( url: string, errorMsg: string, data?: any ) {
			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : undefined );
			} catch ( err ) {
				this.showError( { error: err, message: errorMsg } );

				return;
			}

			if ( 401 == res.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res.status || 'success' !== res.data.result ) {
				this.showError( { response: res, message: errorMsg } );

				return;
			}

			return res.data.data;
		},
		async load() {
			this.loading = true;
			this.dataset = await this.getResource( endpoint, 'Failed to load the Country Restrictions' );
			this.loading = false;
		},
		async loadResources() {
			// noop
		},
	},
} );
