<template>
	<component :is="autocomplete ? 'v-autocomplete' : 'v-select'" v-bind="{ itemText, itemValue, multiple, returnObject, value, ...$attrs }" v-on="listeners" @input="onInput">
		<template v-slot:prepend-item v-if="internalShowSelectAll">
			<v-list-item ripple @click="toggle">
				<v-list-item-action>
					<v-icon>
						{{ icon }}
					</v-icon>
				</v-list-item-action>
				<v-list-item-content>
					<v-list-item-title>
						Select All
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-divider class="mt-2"></v-divider>
		</template>
		<template v-slot:selection="{ item, index }" v-if="internalDisplayLimit">
			<span v-if="index < internalDisplayLimit" style="white-space: pre;">{{ hasItemText ? getText(item, index) : item }}</span>
			<span v-if="index === internalDisplayLimit" class="mx-1 grey--text text-caption">
				(+{{ value.length - 1 }} others)
			</span>
		</template>
	</component>
</template>

<script>
import { getPropertyFromItem } from 'vuetify/es5/util/helpers';

export default {
	name: 'ac-select',
	props: {
		autocomplete: Boolean,
		displayLimit: {
			type: Number,
			default: 1,
		},
		itemText: {
			type: [String, Array, Function],
			default: 'text',
		},
		itemValue: {
			type: [String, Array, Function],
			default: 'value',
		},
		multiple: Boolean,
		returnObject: Boolean,
		showSelectAll: Boolean,
		value: {
			validator: () => true,
		},
	},
	data: () => {
		return {};
	},
	computed: {
		hasItemText() {
			return this.itemText !== 'text';
		},
		hasItemValue() {
			return this.itemValue !== 'value';
		},
		internalDisplayLimit() {
			return this.multiple ? this.displayLimit : 0;
		},
		internalShowSelectAll() {
			return this.multiple && this.showSelectAll;
		},
		listeners() {
			// Filter out the listener for the `input` event. We need to hijack it to make the "select all" feature to work.
			// eslint-disable-next-line no-unused-vars
			const { input, ...rest } = this.$listeners;

			return rest;
		},
		allSelected() {
			if ( ! this.$attrs.items || ! this.value ) {
				return false;
			}

			return this.value.length === this.$attrs.items.length;
		},
		someSelected() {
			if ( ! this.$attrs.items || ! this.value ) {
				return false;
			}

			return this.value.length > 0 && ! this.allSelected;
		},
		icon() {
			if ( this.allSelected ) return 'mdi-checkbox-marked';
			if ( this.someSelected ) return 'mdi-minus-box';
			return 'mdi-checkbox-blank-outline';
		},
	},
	watch: {},
	created() {},
	methods: {
		toggle() {
			// Deselect all if all the items are currently selected
			if ( this.allSelected || ! this.$attrs.items ) {
				this.$emit( 'input', [] );
				return;
			}

			// Handle `return-object` and `item-value` properties that are normally used with `<v-select />`
			if ( this.returnObject || ! this.hasItemValue ) {
				// Items are added to the array of selected items in their entirety
				this.$emit( 'input', this.$attrs.items.slice() );
			} else {
				// Get only the attribute defined by `item-value` from each item
				this.$emit(
					'input',
					this.$attrs.items.map( ( i ) => i[this.itemValue] ),
				);
			}
		},
		onInput( event ) {
			// Just re-emit the event
			this.$emit( 'input', event );
		},
		getText( item, index ) {
			let text = getPropertyFromItem( item, this.itemText, item );

			const displayLimit = this.internalDisplayLimit ? Math.min( this.value.length, this.internalDisplayLimit ) : this.value.length;
			text += index + 1 < displayLimit ? ', ' : '';
			return text;
		},
	},
};
</script>

<style scoped>
</style>
