











import Vue from 'vue';

export default Vue.extend( {
	props: {
		persistent: {
			type: Boolean,
			default: false,
		},
		closeColor: {
			type: String,
			default: 'white',
		},
		color: {
			type: String,
			default: 'primary',
		},
		message: String,
		timeout: {
			type: Number,
			default: 5000,
		},
		value: Boolean,
	},
	data: () => {
		return {};
	},
	computed: {
		_timeout(): number {
			return this.persistent ? 0 : this.timeout;
		},
	},
	methods: {
		onInput( val: boolean ) {
			this.$emit( 'input', val );
		},
		onClose() {
			this.$emit( 'input', false );
		},
	},
} );
