<template>
	<div>
		<div v-for="comment in this.comments" :key="comment.comment_id">
			<v-container v-if="comment.user_id === user_id">
				<v-row>
					<v-col cols="2">
						<span style="color: black; font-weight: bold;" v-text="comment.fullname"></span>
					</v-col>
					<v-col cols="2">
						<span v-text="comment.created"></span>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="11">
						<v-textarea auto-grow outlined background-color="grey lighten-2" color="cyan" v-if="!comment.edit" :disabled="true" v-model="comment.comment_text" ></v-textarea>
						<v-textarea auto-grow outlined v-if="comment.edit" v-model="comment.comment_text" ></v-textarea>
					</v-col>

					<v-col cols="1">
						<v-icon v-if="!comment.edit" color="red darken-1" style="float: right;"
							@click="removeComment( comment.comment_id )"
						>
							mdi-close
						</v-icon>
						<v-icon v-if="!comment.edit" color="blue darken-1" style="float: right;"
							@click="edit( comment )"
						>
							mdi-pencil
						</v-icon>
						<v-icon v-if="comment.edit" color="blue darken-1" style="float: right;"
							@click="editComment( comment )"
						>
							mdi-send
						</v-icon>
						<v-icon v-if="comment.edit" color="blue darken-1" style="float: right;"
							@click="cancelEdit( comment )"
						>
							mdi-close
						</v-icon>
					</v-col>
				</v-row>
			</v-container>
			<v-container v-if="comment.user_id !== user_id">
				<v-row>
					<v-col cols="8"></v-col>
					<v-col cols="2">
						<span style="color: orange; font-weight: bold; float: right;" v-text="comment.fullname"></span>
					</v-col>
					<v-col cols="2">
						<span style="float: right;" v-text="comment.created"></span>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="1"></v-col>
					<v-col cols="11">
						<v-textarea auto-grow outlined background-color="amber lighten-4" color="orange orange-darken-4" :disabled="true" v-model="comment.comment_text" ></v-textarea>
					</v-col>
				</v-row>
			</v-container>
		</div>
		<v-divider class="mt-6 mb-8"></v-divider>
		<v-container>
			<v-row>
				<v-textarea ref="commentText" outlined label="Type to add new note" v-model="editedItem.comment_text" :counter="255"></v-textarea>
			</v-row>
		</v-container>
		<v-spacer></v-spacer>
		<v-icon color="blue darken-1" style="padding: 20 !important; float: right;"
			@click="newComment()"
		>
			mdi-send
		</v-icon>
		<v-dialog v-model="dialogError" max-width="450px" persistent>
		<v-card>
			<v-card-title>
			<span class="headline">Error</span>
			</v-card-title>
			<v-card-text>
				<v-alert
					border="bottom"
					color="orange darken-1"
					elevation="1"
				>
				{{ errorText }}
				</v-alert>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="success darken-2" width="100" @click="closeError">OK</v-btn>
			</v-card-actions>
		</v-card>
		</v-dialog>
	</div>
</template>

<script lang="js">
import api from '../services/api';

const assetCommentEndpoint = '/asset-comment';
const commentEndpoint = '/comment';

const getDefaultComment = () => {
	return {
		asset_comment_id: 0,
		asset_id: 0,
		user_id: 0,
		fullname: '',
		comment_id: 0,
		comment_text: '',
		created: 0,
		edit: false,
	};
};

export default {
	name: 'asset-comments',
	data: () => {
		return {
			comments: [],
			errorText: '',
			dialogError: false,
			asset_id: 0,
			user_id: 0,
			editedItem: getDefaultComment(),
		};
	},
	created() {
		this.asset_id = this.$route.params.asset_id;
		this.user_id = this.$store.getters.USER.user_id;
		this.editedItem.user_id = this.user_id;
		this.editedItem.asset_id = this.asset_id;
		if ( this.asset_id !== 0 ) {
			this.loadComments();
		}
	},
	watch: {
		$route( to, from ) {
			this.asset_id = to.params.asset_id;
			if ( ! this.asset_id ) {
				return;
			}

			if ( from.params && this.asset_id === from.params.asset_id ) {
				// Same asset, just reset the values to their original values
				return;
			}

			this.loadComments();
		},
	},
	methods: {
		showError( res, err, defaultText ) {
			this.errorText = defaultText;
			if ( err && err.response && err.response.data && err.response.data.error ) {
				this.errorText += ': ' + err.response.data.error;
			} else if ( res && res.data.error ) {
				this.errorText += ': ' + res.data.error;
			} else if ( err && 'Error: Network Error' == err.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.dialogError = true;
		},
		closeError() {
			this.errorText = '';
			this.dialogError = false;
		},
		edit( comment ) {
			comment.edit = true;
		},
		cancelEdit( comment ) {
			comment.edit = false;
		},
		editComment( comment ) {
			const accel_api = api( this );
			const updateFields = { comment_id: comment.comment_id , comment_text: comment.comment_text };
			comment.edit = 0;
			accel_api.put( commentEndpoint + '/' + updateFields.comment_id , updateFields )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.loadComments();
					} else {
						this.showError( res, null, 'Failed to update the Asset Comments' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to update the Asset Comments' );
				} );
		},
		newComment() {
			const accel_api = api( this );
			const saveFields = Object.assign( {}, this.editedItem );

			accel_api.post( assetCommentEndpoint, saveFields )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.$refs.commentText.reset();
						this.loadComments();
					} else {
						this.showError( res, null, 'Failed to add a new Asset Comments' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to add a new Asset Comments' );
				} );
		},
		removeComment( id ) {
			const accel_api = api( this );
			accel_api.delete( assetCommentEndpoint + '/' + id )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.loadComments();
					} else {
						this.showError( res, null, 'Failed to remove Asset Comments' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to remove Asset Comments' );
				} );
			this.deleteID = 0;
		},
		async loadComments() {
			const accel_api = api( this );
			accel_api.get( assetCommentEndpoint + '/' + this.asset_id )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.comments = res.data.data.map( ( c ) => Object.assign( getDefaultComment(), c ) );
					} else if ( 401 == res.status ) {
						window.console.log( '401: Unauthorized' );
						this.$store.dispatch( 'SET_USER', null );
						this.$router.push( '/login' );
					} else {
						this.showError( res, null, 'Failed to load the Asset Comments' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to load the Asset Comments' );
				} )
		}
	},
};
</script>

<style scoped>
</style>