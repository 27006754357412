






import { Component, Vue } from 'vue-property-decorator';
import Illustrators from '@/components/illustrators/list.vue';
@Component( {
	components: {
		Illustrators,
	},
} )
export default class IllustratorsView extends Vue {}
