









































import GlobalMixin from '@/mixins/globalMixin';

export default GlobalMixin.extend( {
	name: 'ac-list-page',
	props: {
		enableSearch: {
			type: Boolean,
			default: true,
		},
		headers: {
			type: Array,
			default: () => [],
		},
		items: {
			type: Array,
			required: true,
		},
		itemsPerPage: {
			type: Number,
			default: 10,
		},
		title: {
			type: String,
			default: '',
		},
		showExpand: {
			type: Boolean,
			default: false,
		},
		showSearch: {
			type: Boolean,
			default: false,
		},
		singleExpand: {
			type: Boolean,
			default: false,
		},
		textSearchFilter: Function,
		loading: {
			type: Boolean,
			default: false,
		},
		sortBy: String,
	},
	data: () => {
		return {
			search: '',
			resultLength: 0,
		};
	},
	computed: {
		fullHeaders(): Array<any> {
			if ( ! this.rolePermission.edit && ! this.rolePermission.delete ) {
				return this.headers;
			}

			return this.headers.concat( [{ text: '', align: 'center', value: 'actions', sortable: false, width: 80 }] );
		},
		searchSlots(): Array<string> {
			return Object.keys( this.$scopedSlots )
				.filter( ( key ) => key.startsWith( 'search.' ) )
				.map( this.cleanSearchSlotName );
		},
		tableSlots(): Array<string> {
			return Object.keys( this.$scopedSlots ).filter( ( key ) => ! key.startsWith( 'search.' ) && ! key.startsWith( 'buttons' ) );
		},
		resultCount(): string {
			const query = this.$route.query;
			if ( query && Object.keys( query ).length > 0 ) {
				return `Found ${this.resultLength} result(s)`;
			} else {
				if ( this.search.length > 0 ) return `Found ${this.resultLength} result(s)`;
			}
			return '';
		},
		showSearchCard: {
			get(): boolean {
				return this.showSearch;
			},
			set( value: boolean ) {
				this.$emit( 'update:show-search', value );
			},
		},
	},
	watch: {},
	methods: {
		emitSearch() {
			this.$emit( 'search' );
		},
		emitClear() {
			this.$emit( 'clear' );
		},
		editItem( item: any ) {
			this.$emit( 'update', item );
		},
		deleteItem( item: any ) {
			this.$emit( 'delete', item );
		},
		cleanSearchSlotName( val: string ) {
			return val.replace( /^search\./, '' );
		},
		getSearchSlotName( val: string ) {
			return `search.${val}`;
		},
		setResultLength( pagination: any ) {
			this.resultLength = pagination.itemsLength;
		},
	},
} );
