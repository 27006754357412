<template>
	<v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
		<template v-slot:activator="{ on, attrs }">
			<v-text-field :value="formatDate(internalValue)" :background-color="backgroundColor" :error-messages="errorMessages" :label="label" :hint="hint" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" :dense="dense" :hide-details="hideDetails" :flat="flat" :outlined="outlined" :solo="solo"></v-text-field>
		</template>
		<v-date-picker v-model="internalValue" no-title scrollable>
			<v-spacer></v-spacer>
			<v-btn text color="primary" @click="clear">
				Clear
			</v-btn>
		</v-date-picker>
	</v-menu>
</template>

<script>
export default {
	name: 'ac-tri-state-checkbox',
	props: {
		backgroundColor: String,
		closeOnSelect: {
			type: Boolean,
			default: true,
		},
		dense: Boolean,
		errorMessages: {
			type: Array,
			default: () => [],
		},
		hideDetails: Boolean,
		hint: {
			type: String,
			default: 'DD/MM/YYYY format',
		},
		flat: Boolean,
		label: String,
		outlined: Boolean,
		solo: Boolean,
		value: {
			validator: () => true,
		},
	},
	data: () => {
		return {
			menu: false,
		};
	},
	computed: {
		internalValue: {
			get() {
				return this.value;
			},
			set( val ) {
				this.$emit( 'input', val );
				if ( this.closeOnSelect ) {
					this.menu = false;
				}
			},
		},
	},
	watch: {},
	created() {},
	methods: {
		clear() {
			this.internalValue = null;
		},
		formatDate( date ) {
			if ( ! date ) return null;

			// Dates from the date picker are always ISO strings
			const [year, month, day] = date.split( '-' );
			return `${day}/${month}/${year}`;
		},
	},
};
</script>

<style scoped>
</style>
