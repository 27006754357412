<template>
	<ac-dialog-edit v-model="dialog" :form-title="formTitle" @save="save" @update="update" :is-edit="editedItem.book_production_model_id !== 0">
		<template v-slot:activator="{ on }">
			<v-btn small dark class="createBtn" v-on="on">
				{{ buttonName ? buttonName : 'Create New Record' }}
			</v-btn>
		</template>
		<template v-slot:inputs>
			<v-row>
				<v-col cols="12" sm="12">
					<v-text-field v-model="editedItem.book_production_model_name" label="Book Production Model Title" required @blur="!$v.editedItem.book_production_model_name.$touch()" @input="$v.editedItem.book_production_model_name.$touch()" :error-messages="titleNameErrors"></v-text-field>
				</v-col>
			</v-row>
		</template>
	</ac-dialog-edit>
</template>
<script lang="js">
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import api from '../../services/api';
const endPoint = '/book-production-models';
const DefaultItem = {
	book_production_model_id: 0,
	book_production_model_name: '',
	active: 1,
};
export default {
	mixins: [validationMixin],
	name: 'create-book-production-model',
	props: {
		buttonName: String,
		editItem: Object,
	},
	data: () => {
		return {
			dialog: false,
			dialogDelete: false,
			dialogError: false,
			deleteID: 0,
			deleteText: '',
			errorText: '',
			editedItem: DefaultItem,
			defaultItem: DefaultItem,
			currentItem: DefaultItem,
		}
	},
	validations: {
		editedItem :{
			book_production_model_name: { required },
		}
	},
	computed: {
		formTitle() {
			return this.editedItem.book_production_model_id === 0 ? 'New Book Production Model' : 'Edit Book Production Model';
		},
		titleNameErrors() {
			const errors = []
			if ( ! this.$v.editedItem.book_production_model_name.$dirty ) return errors
			! this.$v.editedItem.book_production_model_name.required && errors.push( 'Book production model name is required.' )
			return errors;
		},
	},
	watch: {
		editItem( item ) {
			this.editedItem = Object.assign( {}, item );
			this.currentItem = Object.assign( {}, this.editedItem );
			this.dialog = true;
		},
		dialog( val ) {
			if ( this.editedItem.book_production_model_id === 0 || ! val ) {
				this.editedItem = Object.assign( {}, this.defaultItem );
			}
		},
		dialogDelete( val ) {
			if ( ! val ) {
				this.deleteID = 0;
			}
		}
	},
	created() {
	},
	methods: {
		deleteItem( item ) {
			this.deleteID = item.book_production_model_id;
			this.deleteText = item.book_production_model_name;
			this.dialogDelete = true;
		},
		showError( res, err, defaultText ) {
			this.errorText = defaultText;
			if ( err && err.response && err.response.data && err.response.data.error ) {
				this.errorText += ': ' + err.response.data.error;
			} else if ( res && res.data.error ) {
				this.errorText += ': ' + res.data.error;
			} else if ( err && 'Error: Network Error' == err.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.dialogError = true;
		},
		update() {
			const accel_api = api( this );
			const updateFields = { book_production_model_id: this.editedItem.book_production_model_id };
			let updated = false;
			for ( const field in this.editedItem ) {
				if ( this.currentItem[field] != this.editedItem[field] ) {
					updateFields[field] = this.editedItem[field];
					updated = true;
				}
			}
			if ( ! updated ) {
				return;
			}
			accel_api.put( endPoint + '/' + updateFields.book_production_model_id, this.editedItem )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.$emit( 'bookProductionModelLoad', res.data.result  );
					} else {
						this.showError( res, null, 'Failed to update the Book Production Model' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to update the Book Production Model' );
				} );
		},
		save() {
			this.$v.$touch();
			if ( this.$v.$invalid ) {
				return;
			}
			const accel_api = api( this );
			const saveFields = Object.assign( {}, this.editedItem );
			accel_api.post( endPoint, saveFields )
				.then( res => {
					if ( 200 === res.status && 'success' === res.data.result ) {
						this.$emit( 'bookProductionModelLoad', res.data.result );
					} else {
						this.showError( res, null, 'Failed to add the Book Production Model' );
					}
				} )
				.catch( err => {
					this.showError( null, err, 'Failed to add the Book Production Model' );
				} );
		},
	}
}
</script>

<style scoped>
.createBtn {
	background-color: #14234f !important;
	margin: 8px;
}
</style>
