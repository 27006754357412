







import { Component, Vue } from 'vue-property-decorator';
import Asset from '@/components/assets/single.vue';

@Component( {
	components: {
		Asset,
	},
} )
export default class AssetView extends Vue {}
