<template>
	<v-dialog v-model="show" max-width="300px" persistent>
		<v-card>
			<v-card-title>
				<span class="headline">Deactivate {{ itemType }}</span>
			</v-card-title>
			<v-card-text>
				<v-container>
					Are you sure you want to deactivate {{ itemName }}?
				</v-container>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="error" text @click="deactivate">Deactivate</v-btn>
				<v-btn color="info" text @click="close">Cancel</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script lang="js">
export default {
	name: 'ac-dialog-deactivate',
	props: {
		value: Boolean,
		itemId: Number,
		itemType: String,
		itemName: String,
	},
	computed: {
		show: {
			get() {
				return this.value
			},
			set( value ) {
				this.$emit( 'input', value );
			}
		},
	},
	methods: {
		deactivate() {
			this.$emit( 'deactivate', this.itemId );
			this.close();
		},
		close() {
			this.show = false;
		},
	}
};
</script>

<style scoped>
</style>
