<template>
	<div class="d-flex flex-column">
		<input type="file" ref="file" style="display: none" @change="upload" :accept="accept" />
		<div class="text-subtitle-1" v-if="title">
			{{ title }}
		</div>
		<v-card class="mt-2 rounded-lg" @click="$refs.file.click()">
			<v-card-title class="justify-center">
				<v-icon size="40" disabled>
					mdi-cloud-upload
				</v-icon>
			</v-card-title>
			<v-card-text class="text-center">
				<span class="text-subtitle-2">Browse files</span>
			</v-card-text>
		</v-card>
		<v-card v-if="showPreview || null != currentFileInfo" class="mt-4" :loading="null != uploadingFileInfo">
			<v-skeleton-loader v-if="showPreview" type="list-item-avatar-two-line" />
			<v-list-item v-else>
				<v-list-item-avatar>
					<v-icon color="primary" size="32">
						mdi-file
						<!-- TODO show correct icon depending on mime type -->
					</v-icon>
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title>
						{{ currentFileInfo.file_name }}
					</v-list-item-title>
					<v-list-item-subtitle>
						{{ Number(currentFileInfo.size) | prettyBytes }}
					</v-list-item-subtitle>
				</v-list-item-content>
				<v-list-item-action v-if="enableDownload">
					<v-icon color="primary" @click="download">
						mdi-download
					</v-icon>
				</v-list-item-action>
				<v-list-item-action>
					<v-icon color="error" @click="remove" :disabled="!enableRemove">
						mdi-delete
					</v-icon>
				</v-list-item-action>
			</v-list-item>
		</v-card>
		<ac-dialog-error v-model="errorDialog" :text="errorText" />
	</div>
</template>

<script>
import api from '@/services/api';
import pBytes from 'pretty-bytes';

const endpoint = '/assets';

export default {
	name: 'asset-file-selection',
	props: {
		accept: String,
		assetId: {
			type: Number,
			required: true,
		},
		fileName: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			default: 'Select a file',
		},
		value: Number,
	},
	data: () => {
		return {
			errorDialog: false,
			errorText: '',
			fileInfo: null,
			loadingFileInfo: false,
			uploadedFileInfo: null,
			uploadingFileInfo: null,
		};
	},
	computed: {
		showPreview() {
			return this.loadingFileInfo;
		},
		enableDownload() {
			return null != this.fileInfo && ! this.uploadingFileInfo && ! this.uploadedFileInfo;
		},
		enableRemove() {
			return ( null != this.fileInfo || null != this.uploadedFileInfo ) && ! this.uploadingFileInfo;
		},
		currentFileInfo() {
			return this.uploadingFileInfo || this.uploadedFileInfo || this.fileInfo;
		},
	},
	filters: {
		prettyBytes: function ( num ) {
			return pBytes( num );
		},
	},
	watch: {
		assetId: {
			handler: function () {
				this.uploadedFileInfo = null;
				this.loadFileInfo();
			},
			immediate: true,
		},
	},
	methods: {
		async getResource( url, errorMsg, data ) {
			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( url, data ? { params: data } : null );
			} catch ( err ) {
				this.showError( null, err, errorMsg );

				return;
			}

			if ( 401 == res.status ) {
				window.console.log( '401: Unauthorized' );
				this.$store.dispatch( 'SET_USER', null );
				this.$router.push( '/login' );

				return;
			} else if ( 200 !== res.status || 'success' !== res.data.result ) {
				this.showError( res, null, errorMsg );

				return;
			}

			return res.data.data;
		},
		showError( res, err, defaultText ) {
			this.errorText = defaultText;
			if ( err && err.response && err.response.data && err.response.data.error ) {
				this.errorText += ': ' + err.response.data.error;
			} else if ( res && res.data.error ) {
				this.errorText += ': ' + res.data.error;
			} else if ( err && 'Error: Network Error' == err.toString() ) {
				this.errorText += ':\nUnable to connect to the backend API';
			} else {
				this.errorText += ':\nUnexpected error';
			}
			this.errorDialog = true;
		},
		async loadFileInfo() {
			this.loadingFileInfo = true;
			this.fileInfo = null;

			const res = await this.getResource( `${endpoint}/${this.assetId}/${this.fileName}`, 'Failed to load the File' );
			if ( res ) {
				this.fileInfo = res;
			}

			this.loadingFileInfo = false;
		},
		async download() {
			if ( ! this.value ) {
				return;
			}

			const accel_api = api( this );

			let res;
			try {
				res = await accel_api.get( `${endpoint}/${this.assetId}/${this.fileName}/download` );

				if ( 200 !== res.status ) {
					this.showError( res, null, 'Failed to download the file' );
				}

				const url = res.data.presigned_url
				const link = document.createElement( 'a' );
				link.href = url;
				link.setAttribute( 'download', res.data.file_name );
				document.body.appendChild( link );
				link.click();
				link.remove();
			} catch ( err ) {
				this.showError( null, err, 'Failed to download the file' );
			}
		},
		async upload() {
			const file = this.$refs.file.files[0];
			if ( null == file ) {
				return;
			}

			const accel_api = api( this );

			this.uploadingFileInfo = { file_name: file.name, size: file.size };

			let res;
			try {
				res = await accel_api.post( `${endpoint}/${this.assetId}/${this.fileName}/upload`, this.uploadingFileInfo );

				if ( 200 !== res.status || 'success' !== res.data.result ) {
					this.showError( res, null, 'Failed to upload the file' );
				}

				this.uploadedFileInfo = { ...res.data }
				await fetch( res.data.presigned_url, { method: 'PUT', body: file } );
			} catch ( err ) {
				this.showError( null, err, 'Failed to upload the file' );
			} finally {
				this.uploadingFileInfo = null;
			}

			if ( null != res.data.insertId ) {
				this.$emit( 'input', res.data.insertId );
			}
		},
		remove() {
			this.uploadedFileInfo = null;
			this.fileInfo = null;
			this.$emit( 'input', null );
		},
		async finalize() {
			this.uploadedFileInfo = null;
			return this.loadFileInfo();
		},
	},
};
</script>

<style scoped lang="scss">
</style>
